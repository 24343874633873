import { useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { PATH_AUTOMATIONS } from 'src/routes/paths';
import { useAutomationPlatform, AutomationPlatform } from './useAutomationPlatform';

// Poshmark actions
import {
  setActiveCloset as poshmarkSetActiveCloset,
} from 'src/store/automations/slices/myClosetSlice';
import {
  resetAutomationsState as poshmarkResetAutomationsState,
  setActiveClosetAutomationPlatform as poshmarkSetActiveClosetAutomationPlatform,
} from 'src/store/automations/slices/automationsSlice';

// eBay actions
import {
  setActiveCloset as ebaySetActiveCloset,
} from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import {
  resetAutomationsState as ebayResetAutomationsState,
  setActiveClosetAutomationPlatform as ebaySetActiveClosetAutomationPlatform,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';

export const useShopParamEffect = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { platform, states } = useAutomationPlatform();
  const { pathname } = location;

  const { closetList, activeCloset } = states.myShops;

  useEffect(() => {
    const shopQueryParam = searchParams.get('shop');
    const referrerQueryParam = searchParams.get('referrer');

    const actions = {
      setActiveCloset: platform === AutomationPlatform.EBAY ? ebaySetActiveCloset : poshmarkSetActiveCloset,
      resetAutomationsState: platform === AutomationPlatform.EBAY ? ebayResetAutomationsState : poshmarkResetAutomationsState,
      setActiveClosetAutomationPlatform: platform === AutomationPlatform.EBAY ? ebaySetActiveClosetAutomationPlatform : poshmarkSetActiveClosetAutomationPlatform,
    };

    if (!referrerQueryParam && shopQueryParam) {
      const initialCloset = closetList.find(
        (closet) => shopQueryParam === closet?.marketplaceUserName
      );
      if (initialCloset) {
        dispatch(actions.resetAutomationsState());
        dispatch(actions.setActiveCloset(initialCloset));
        dispatch(actions.setActiveClosetAutomationPlatform(initialCloset?.country));
      }
    } else {
      if (referrerQueryParam || (!shopQueryParam && activeCloset?.marketplaceUserName)) {
        if (
          activeCloset?.marketplaceUserName &&
          !pathname.includes(PATH_AUTOMATIONS.account) &&
          !pathname.includes('subscription')
        ) {
          setTimeout(() => {
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.set('shop', activeCloset.marketplaceUserName);
            window.history.replaceState(
              null,
              '',
              `${window.location.pathname}?${currentParams.toString()}${window.location.hash}`
            );
          }, 500);
        }
      }
    }
  }, [window.location.search, closetList, window.location.href]);
}; 