import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { poshmarkAutomationEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';

import {
  getActivityListFail,
  getActivityListRequest,
  getActivityListSuccess,
  getOngoingListFail,
  getOngoingListRequest,
  getOngoingListSuccess,
  removeAllActiveJobsFail,
  removeAllActiveJobsRequest,
  removeAllActiveJobsSuccess,
  removeSingleActiveJobFail,
  removeSingleActiveJobRequest,
  removeSingleActiveJobSuccess,
  setHasMoreActivity,
  setInitialLoading,
  setIsLoading,
  setIsRemoveTaskDialogOpen,
  setRequestCursor,
  setSelectedOngoingTask,
} from '../slices/activitySlice.ebay';
import {
  IGetActivityRequest,
  IGetOngoingRequest,
} from 'src/pages/automations/ActivityPage/utils/types';
import { toast } from 'react-toastify';
import { UserAction } from '@beta.limited/primelister';
import { ongoingTabItemsList } from 'src/pages/automations/ActivityPage/utils/constants';

function* getActivityListSaga(action: PayloadAction<IGetActivityRequest>) {
  try {
    const { automation, cursor } = action.payload;

    const paramsForRequest = {
      pageSize: 10,
      credentialsId: action.payload.activeClosetCredentialsId,
      ...(cursor !== 0 && { cursor }),
      ...(automation !== 'all' && { automation }),
    };

    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        poshmarkAutomationEndpoints.activity.GET_ACTIVITY(
          action.payload.activeClosetAutomationPlatform
        ) + '/activity',
        {
          params: paramsForRequest,
        }
      )
    );

    yield put(
      getActivityListSuccess({
        activityList: response.data.data.records,
        requestType: action.payload.requestType,
      })
    );
    yield put(
      setHasMoreActivity(response.data.data.records.length ? response.data.data.hasMore : false)
    );
    yield put(setRequestCursor(response.data.data.cursor));
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getActivityListFail(error.message));
    yield put(setInitialLoading(false));
  }
}

export function* getOngoingListSaga(action: PayloadAction<IGetOngoingRequest>) {
  try {
    const { shop, product } = action.payload;

    // const paramsForRequest = {
    //   pageSize: 10,
    //   credentialsId: action.payload.activeClosetCredentialsId,
    //   ...(action.payload.cursor !== 0 && { cursor: action.payload.cursor }),
    // };

    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        poshmarkAutomationEndpoints.activity.GET_ACTIVE_JOBS({
          shop,
          product,
          action: action.payload.action,
        })
      )
    );

    yield put(
      getOngoingListSuccess({
        ongoingList: response.data.data,
        // requestType: action.payload.requestType,
      })
    );
    // yield put(
    //   setHasMoreOngoingList(response.data.data.records.length ? response.data.data.hasMore : false)
    // );
    // yield put(setRequestCursor(response.data.data.cursor));
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getOngoingListFail(error.message));
    yield put(setInitialLoading(false));
  }
}

export function* removeAllActiveJobsSaga(
  action: PayloadAction<{
    shop: string;
    getOngoingListRequestParams: IGetOngoingRequest;
    isMobile: boolean;
  }>
) {
  try {
    const response: AxiosResponse = yield toast.promise(
      axiosInstance.delete(
        poshmarkAutomationEndpoints.activity.REMOVE_ALL_ACTIVE_JOBS(action.payload.shop)
      ),
      {
        pending: 'Processing your request, please wait...',
        success: 'Your task(s) successfully removed',
        error: 'Something went wrong',
      },
      {
        position: !action.payload.isMobile ? 'top-right' : 'bottom-center',
      }
    );

    yield put(setInitialLoading(true));
    yield put(
      getOngoingListRequest({
        ...action.payload.getOngoingListRequestParams,
      })
    );
    yield put(setIsRemoveTaskDialogOpen(false));
    yield put(removeAllActiveJobsSuccess());
    yield put(setInitialLoading(false));
    yield put(setSelectedOngoingTask(ongoingTabItemsList[0]));
  } catch (error) {
    yield put(removeAllActiveJobsFail(error.message));
    yield put(setInitialLoading(false));
  }
}

export function* removeSingleActiveJobSaga(
  action: PayloadAction<{
    jobId: string;
    getOngoingListRequestParams: IGetOngoingRequest;
    isMobile: boolean;
  }>
) {
  try {
    const response: AxiosResponse = yield toast.promise(
      axiosInstance.delete(
        poshmarkAutomationEndpoints.activity.REMOVE_SINGLE_ACTIVE_JOB({
          jobId: action.payload.jobId,
        })
      ),
      {
        pending: 'Processing your request, please wait...',
        success: 'Your task(s) successfully removed',
        error: 'Something went wrong',
      },
      {
        position: !action.payload.isMobile ? 'top-right' : 'bottom-center',
      }
    );

    yield put(setInitialLoading(true));
    yield put(
      getOngoingListRequest({
        ...action.payload.getOngoingListRequestParams,
      })
    );
    yield put(setIsRemoveTaskDialogOpen(false));
    yield put(removeSingleActiveJobSuccess());
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(removeSingleActiveJobFail(error.message));
    yield put(setInitialLoading(false));
  }
}

export function* activityModuleSaga() {
  yield takeLatest(getActivityListRequest.type, getActivityListSaga);
  yield takeLatest(getOngoingListRequest.type, getOngoingListSaga);
  yield takeLatest(removeAllActiveJobsRequest.type, removeAllActiveJobsSaga);
  yield takeLatest(removeSingleActiveJobRequest.type, removeSingleActiveJobSaga);
}
