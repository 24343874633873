// @mui
import { CircularProgress, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
// hooks
import { useAutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

import ActivityCard from './ActivityCard';
import Image from 'src/components/image';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ACTIVITY_REQUEST_TYPE, completedTabItemsList } from '../utils/constants';
import { checkValueForRelist } from '../utils/helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export default function ActivityCardContainer() {
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const {
    initialLoading = true,
    activityList,
    hasMoreActivity,
    requestCursor,
    selectedActivity,
    loading,
  } = states.activity;

  const { activeClosetAutomationPlatform } = states.automations;

  const matches = useMediaQuery('(min-width:700px)');
  const { activeClosetCredentialsId } = states.myShops;
  const dispatch = useDispatch();

  const fetchDataWithScroll = () => {
    dispatch(
      platformActions.getActivityListRequest({
        automation: checkValueForRelist(selectedActivity.value),
        activeClosetAutomationPlatform,
        cursor: requestCursor,
        requestType: ACTIVITY_REQUEST_TYPE.moreActivityRequest,
        activeClosetCredentialsId,
      })
    );
  };

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const shop = searchParams.get('shop');

  useEffect(() => {
    if (!shop) {
      const hashValue = location.hash.substring(1);
      const matchedItem = completedTabItemsList.find((item) => item.urlParam === hashValue);

      if (hashValue && matchedItem) {
        dispatch(platformActions.setSelectedActivity(matchedItem));
      } else {
        dispatch(platformActions.setSelectedActivity(selectedActivity));
      }

      if (activeClosetCredentialsId) {
        dispatch(platformActions.activitySetInitialLoading(true));
        dispatch(
          platformActions.getActivityListRequest({
            automation: checkValueForRelist(matchedItem?.value || selectedActivity.value),
            activeClosetAutomationPlatform,
            cursor: 0,
            requestType: ACTIVITY_REQUEST_TYPE.newRequest,
            activeClosetCredentialsId,
          })
        );
      } else {
        dispatch(platformActions.activitySetInitialLoading(false));
        dispatch(platformActions.setActivityList([]));
      }
    }
  }, []);

  useEffect(() => {
    const hashValue = location.hash.substring(1);
    const matchedItem = completedTabItemsList.find((item) => item.urlParam === hashValue);

    if (hashValue && matchedItem) {
      dispatch(platformActions.setSelectedActivity(matchedItem));
    } else {
      dispatch(platformActions.setSelectedActivity(selectedActivity));
    }

    if (activeClosetCredentialsId) {
      dispatch(platformActions.activitySetInitialLoading(true));
      dispatch(
        platformActions.getActivityListRequest({
          automation: checkValueForRelist(matchedItem?.value || selectedActivity.value),
          activeClosetAutomationPlatform,
          cursor: 0,
          requestType: ACTIVITY_REQUEST_TYPE.newRequest,
          activeClosetCredentialsId,
        })
      );
    } else {
      dispatch(platformActions.activitySetInitialLoading(false));
      dispatch(platformActions.setActivityList([]));
    }
  }, [activeClosetCredentialsId, shop]);

  const addSkeletonToComponents = (component: any) => {
    if (!initialLoading) {
      return component;
    } else {
      const skeletonItems = Array.from({ length: 7 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          sx={{ width: '100%', height: '6rem', my: '16px !important' }}
        />
      ));
      return <>{skeletonItems}</>;
    }
  };

  const renderEmptyContent = () => (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        mt: -6,
        height: 1,
        textAlign: 'center',
        p: (theme) => theme.spacing(8, 2),
      }}
    >
      <Image
        disabledEffect
        alt="empty content"
        src={'/assets/illustrations/illustration_empty_content.svg'}
        sx={{ width: '214.8px', height: '161.1px', mb: 3 }}
      />

      <Typography variant="body2" gutterBottom>
        Nothing to see yet. Activity will appear here.
      </Typography>
    </Stack>
  );

  return activityList && activityList.length > 0 ? (
    <Stack spacing={matches ? '40px' : '24px'}>
      <InfiniteScroll
        dataLength={activityList.length + 2}
        next={fetchDataWithScroll}
        hasMore={hasMoreActivity}
        loader={null}
        style={{
          border: 'none !important',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          msOverflowStyle: 'none',
        }}
      >
        {activityList.map((activity) =>
          addSkeletonToComponents(<ActivityCard key={activity._id} activity={activity} />)
        )}
      </InfiniteScroll>
      {hasMoreActivity && loading && (
        <CircularProgress color="inherit" sx={{ alignSelf: 'center' }} size={40} thickness={4} />
      )}
      {!hasMoreActivity && (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgba(99, 115, 129, 1)',
            maxWidth: '270px',
            alignSelf: 'center',
          }}
        >
          You've reached the end of the activities.
        </Typography>
      )}
    </Stack>
  ) : (
    addSkeletonToComponents(renderEmptyContent())
  );
}
