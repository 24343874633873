import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PATH_AUTOMATIONS } from 'src/routes/paths';

import { IRootState } from 'src/store';
import {
  IRemoveData,
  removeClosetRequest as poshmarkRemoveClosetRequest,
  setIsRemoveClosetDialogOpen as poshmarkSetIsRemoveClosetDialogOpen,
} from 'src/store/automations/slices/myClosetSlice';
import { 
  removeClosetRequest as ebayRemoveClosetRequest,
  setIsRemoveClosetDialogOpen as ebaySetIsRemoveClosetDialogOpen 
} from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import { LoadingButton } from '@mui/lab';

const RemoveClosetDialog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isEbayAutomation = location.pathname.startsWith(PATH_AUTOMATIONS.ebayRoot);

  const { isRemoveClosetDialogOpen, removeClosetData, loading } = useSelector(
    (state: IRootState) => isEbayAutomation ? state.ebayMyShops : state.myCloset
  );

  const getTextByPlatform = (type: 'title' | 'button' | 'entity') => {
    switch (type) {
      case 'title':
        return isEbayAutomation ? 'Remove Shop' : 'Remove Closet';
      case 'button':
        return isEbayAutomation ? 'Remove Shop' : 'Remove Closet';
      case 'entity':
        return isEbayAutomation ? 'shop' : 'closet';
      default:
        return '';
    }
  };

  const getConfirmationMessage = () => {
    const entityType = getTextByPlatform('entity');
    return [
      `Your ${entityType} '${removeClosetData.name}' will be removed from your account. Are you sure you want to remove it?`,
      `Removing this ${entityType} will stop all enabled automations for '${removeClosetData.name}' from working.`,
      `Please note that once removed, the unit price of this ${entityType} will not be deducted from your ongoing subscription automatically. You may want to adjust the available ${entityType} limit in your subscription settings accordingly.`,
    ];
  };

  const getRemoveAction = () => {
    switch (true) {
      case isEbayAutomation:
        return ebayRemoveClosetRequest;
      default:
        return poshmarkRemoveClosetRequest;
    }
  };

  const getSetRemoveDialogAction = () => {
    switch (true) {
      case isEbayAutomation:
        return ebaySetIsRemoveClosetDialogOpen;
      default:
        return poshmarkSetIsRemoveClosetDialogOpen;
    }
  };

  const handleClose = () => {
    const setRemoveDialogAction = getSetRemoveDialogAction();
    dispatch(
      setRemoveDialogAction({ 
        openDialog: false, 
        removeClosetData: {} as IRemoveData 
      })
    );
  };

  const handleRemove = () => {
    const removeAction = getRemoveAction();
    dispatch(removeAction(removeClosetData));
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isRemoveClosetDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 3 }}>{getTextByPlatform('title')}</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>
        {getConfirmationMessage().map((message, index) => (
          <Typography
            sx={{
              ...(index < 2 && { pb: 3 }),
              fontSize: '16px',
              fontWeight: '400',
              color: '#637381',
            }}
            key={index}
          >
            {message}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleRemove}>
          {getTextByPlatform('button')}
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveClosetDialog;
