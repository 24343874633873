import { Skeleton, Stack } from '@mui/material';
import { Automation } from '@beta.limited/primelister';
import AutomationOption from 'src/pages/automations/AutomationsPage/components/AutomationOption';
import { IAutomationOption } from 'src/pages/automations/AutomationsPage/types/types';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

const ebayAutomationItemsList: IAutomationOption[] = [
  {
    name: Automation.AUTO_RELIST,
    url: '/icons/automationIcons/relist.svg',
    displayName: 'Re-create',
    index: 0,
    tooltipText: 'PrimeLister will automatically re-create listings in your eBay store.',
  },
  {
    name: Automation.AUTO_SEND_OFFER_TO_LIKERS,
    url: '/icons/automationIcons/offerToLikers.svg',
    displayName: 'Offer to Watchers',
    index: 1,
    tooltipText: 'PrimeLister will automatically send an offer to watchers for each item.',
  },
];

export default function AutomationOptionsContainer() {
  const { initialLoading } = useSelector((state: IRootState) => state.ebayAutomations);

  const addSkeletonToComponents = (component: any) =>
    initialLoading ? (
      <Skeleton variant="rounded" sx={{ width: '100', height: '5rem' }} />
    ) : (
      component
    );

  return (
    <Stack spacing={2}>
      {ebayAutomationItemsList.map((automation) =>
        addSkeletonToComponents(
          <AutomationOption
            key={Math.random() * 9999}
            automation={automation as IAutomationOption}
          />
        )
      )}
    </Stack>
  );
}
