import { PATH_AUTOMATIONS } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import { memoize } from 'lodash';
import { IFeatureFlag } from '@openreplay/tracker/lib/modules/featureFlags';
import { checkIfOpenReplayFlagIsEnabled } from 'src/utils/checkIfFlagIsEnabled';
import { IS_SANDBOX } from 'src/config';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const customMenuIcon = (name: string, isIconify = false) =>
  isIconify ? (
    <Iconify icon={name} sx={{ width: 22, height: 22 }} />
  ) : (
    <SvgColor src={`/icons/${name}MenuIcon.svg`} sx={{ width: 1, height: 1 }} />
  );

const ICONS = {
  automations: customMenuIcon('automations'),
  activity: customMenuIcon('statistics'),
  shops: customMenuIcon('myCloset'),
  account: icon('ic_user'),
};

const createNavConfig = memoize((isWebMobile: boolean, isPrimeListerMobileApp: boolean) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Navigation',
    items: [
      {
        title: 'Shops',
        path: `${PATH_AUTOMATIONS.ebayMyShops}`,
        icon: ICONS.shops,
        tooltip: 'Connect your eBay store',
      },
      {
        title: 'Automations',
        path: `${PATH_AUTOMATIONS.ebayAutomations}`,
        icon: ICONS.automations,
        tooltip: 'Automate eBay tasks',
      },
      {
        title: 'Activity',
        path: `${PATH_AUTOMATIONS.ebayActivity}`,
        icon: ICONS.activity,
        tooltip: 'Activity',
      },
      {
        title: 'Account',
        path: PATH_AUTOMATIONS.ebayAccount,
        icon: ICONS.account,
        tooltip: 'Account & Subscription',
      },
    ],
  },
]);

export default createNavConfig;
