import { Alert, Button, Stack, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';

import { getAlertContent } from './alertConstants';

export enum AlertTypes {
  EXTENSION = 'EXTENSION',
  POSHMARK_CONNECTION = 'POSHMARK_CONNECTION',
  POSHMARK_RECONNECTION = 'POSHMARK_RECONNECTION',
  SUBSCRIPTION_TO_POSHMARK = 'SUBSCRIPTION_TO_POSHMARK',
  EBAY_CONNECTION = 'EBAY_CONNECTION',
  EBAY_RECONNECTION = 'EBAY_RECONNECTION',
  SUBSCRIPTION_TO_EBAY = 'SUBSCRIPTION_TO_EBAY',
  DUPLICATE = 'DUPLICATE',
  MOBILE_DEVICE = 'MOBILE_DEVICE',
  BROWSER_COMPATIBILITY = 'BROWSER_COMPATIBILITY',
}

interface ILayoutAlertProps {
  type: AlertTypes;
  alertFunction?: () => void;
}

const LayoutAlert = ({ alertFunction, type }: ILayoutAlertProps) => {
  const { startExtensionAlertAnimation } = useSelector((state: IRootState) => state.home);
  const matches = useMediaQuery('(min-width:950px)');
  const alertContent = getAlertContent(type);

  const hasButton =
    type === AlertTypes.EXTENSION ||
    type === AlertTypes.POSHMARK_CONNECTION ||
    type === AlertTypes.EBAY_CONNECTION ||
    type === AlertTypes.POSHMARK_RECONNECTION ||
    type === AlertTypes.EBAY_RECONNECTION ||
    type === AlertTypes.SUBSCRIPTION_TO_POSHMARK ||
    type === AlertTypes.SUBSCRIPTION_TO_EBAY ||
    type === AlertTypes.BROWSER_COMPATIBILITY;

  const hasLink = type === AlertTypes.DUPLICATE;
  const isAnimated = type === AlertTypes.EXTENSION;

  const alertButton = () => (
    <Button
      size="small"
      color="inherit"
      variant="contained"
      sx={{
        alignSelf: 'center',
        bgcolor: 'common.white',
        fontSize: matches ? '13px' : '11px',
        mb: 0.5,
      }}
      onClick={alertFunction}
    >
      {alertContent?.buttonText}
    </Button>
  );

  const alertLink = () => (
    <b style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={alertFunction}>
      link.
    </b>
  );

  const renderButton = () => (hasButton ? alertButton() : null);
  const renderLink = () => (hasLink ? alertLink() : null);

  const shakeAnimation = {
    '@keyframes shake': {
      '0%': {
        marginLeft: '0rem',
      },
      '25%': {
        marginLeft: '0.5rem',
      },
      '50%': {
        marginLeft: '0rem',
      },
      '75%': {
        marginLeft: '-0.5rem',
      },
      '100%': {
        marginLeft: '0rem',
      },
    },
    animation: startExtensionAlertAnimation ? 'shake 0.1s ease-in-out 0s 3.5' : '',
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        ...(isAnimated && shakeAnimation),
        zIndex: 100,
      }}
    >
      <Alert
        severity={'error'}
        variant="filled"
        sx={{
          display: 'flex',
          justifyContent: 'center !important',
          alignItems: 'center !important',
          flexDirection: matches ? 'row' : 'column',
        }}
        action={renderButton()}
      >
        {alertContent?.message} {''}
        {renderLink()}
      </Alert>
    </Stack>
  );
};

export default LayoutAlert;
