import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Stack, Typography, IconButton, Button, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import { Automation, EbayAutoSendOfferToLikersRule } from '@beta.limited/primelister';
import DeleteRuleDialog from '../DeleteRuleDialog';

export interface RuleProps {
  rule: EbayAutoSendOfferToLikersRule;
  index: number;
  errors: any;
  touched: any;
  isExpanded: boolean;
  toggleExpand: () => void;
}

interface RulesProps {
  initialRules: EbayAutoSendOfferToLikersRule[];
  initialRule: EbayAutoSendOfferToLikersRule;
  validationSchema: Yup.ObjectSchema<any>;
  renderRuleFields: (props: RuleProps) => React.ReactNode;
  defaultExpanded: boolean;
}

const Rules: React.FC<RulesProps> = ({
  initialRules,
  initialRule,
  validationSchema,
  renderRuleFields,
  defaultExpanded,
}) => {
  const [expandedRules, setExpandedRules] = useState<boolean[]>(
    initialRules.map(() => defaultExpanded)
  );
  const dispatch = useDispatch();
  const { selectedAutomationOption, activeClosetAutomationPlatform, loading } = useSelector(
    (state: IRootState) => state.ebayAutomations
  );
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.ebayMyShops
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState<{ open: boolean; index: number | null }>(
    {
      open: false,
      index: null,
    }
  );
  const ruleRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleRule = (index: number) => {
    setExpandedRules((prev) => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });

    // Scroll the rule into view when expanded
    if (!expandedRules[index]) {
      setTimeout(() => {
        const drawerContent = document.getElementById('automationDrawerScrollableContent');
        const ruleElement = ruleRefs.current[index];
        if (drawerContent && ruleElement) {
          const drawerRect = drawerContent.getBoundingClientRect();
          const ruleRect = ruleElement.getBoundingClientRect();

          const targetScrollTop = ruleElement.offsetTop - (drawerRect.height - ruleRect.height) / 2;
          const minScroll = 0;
          const maxScroll = drawerContent.scrollHeight - drawerRect.height;
          const scrollTop = Math.max(minScroll, Math.min(targetScrollTop, maxScroll));

          drawerContent.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  };

  const updateRules = (updatedRules: EbayAutoSendOfferToLikersRule[]) => {
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

    const dataForUpdating = {
      settings: {
        [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
          config: {
            rules: updatedRules,
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };

    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  const handleRemoveRule = (arrayHelpers: any, index: number, values: any) => {
    arrayHelpers.remove(index);
    setExpandedRules((prev) => prev.filter((_, i) => i !== index));

    const updatedRules = values.rules.filter((_: any, i: number) => i !== index);
    updateRules(updatedRules);
  };

  return (
    <Formik
      initialValues={{ rules: initialRules }}
      validationSchema={validationSchema}
      onSubmit={(values) => {}}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <FieldArray name="rules">
            {(arrayHelpers) => (
              <Stack spacing={2}>
                {values.rules.map((rule, index) => (
                  <React.Fragment key={index}>
                    <Stack
                      ref={(el) => (ruleRefs.current[index] = el)}
                      spacing={2}
                      sx={{ bgcolor: 'white', p: '12px', borderRadius: 1, width: '360px' }}
                    >
                      <Stack
                        sx={{
                          mb: '4px !important',
                        }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="subtitle2">Rule {index + 1}</Typography>
                        {values.rules.length > 1 && (
                          <IconButton
                            onClick={() => setOpenDeleteDialog({ open: true, index })}
                            size="small"
                            sx={{
                              '&:hover': {
                                backgroundColor: '#FFF1EE',
                                svg: {
                                  color: '#FF5630',
                                },
                              },
                            }}
                          >
                            <Iconify
                              icon="material-symbols:delete-outline"
                              sx={{
                                width: '18px',
                                height: '18px',
                                color: '#637381',
                              }}
                            />
                          </IconButton>
                        )}
                      </Stack>

                      {renderRuleFields({
                        rule,
                        index,
                        errors,
                        touched,
                        isExpanded: expandedRules[index],
                        toggleExpand: () => toggleRule(index),
                      })}

                      <Button
                        onClick={() => toggleRule(index)}
                        variant="text"
                        color="inherit"
                        endIcon={expandedRules[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        sx={{
                          alignSelf: 'center',
                          textTransform: 'none',
                          fontWeight: 300,
                          mt: '12px !important',
                        }}
                        size="small"
                        fullWidth
                      >
                        {expandedRules[index] ? 'Hide Details' : 'Show Details'}
                      </Button>
                    </Stack>
                  </React.Fragment>
                ))}
                <Button
                  variant="outlined"
                  color="inherit"
                  fullWidth
                  onClick={() => {
                    if (hasActiveClosetConnection) {
                      const newRule = { ...initialRule };
                      arrayHelpers.push(newRule);
                      setExpandedRules((prev) => prev.map(() => false).concat(true));
                      updateRules([...values.rules, newRule]);
                      setTimeout(() => {
                        const drawerContent = document.getElementById(
                          'automationDrawerScrollableContent'
                        );
                        if (drawerContent) {
                          drawerContent.scrollTo({
                            top: drawerContent.scrollHeight,
                            behavior: 'smooth',
                          });
                        }
                      }, 100);
                    } else {
                      dispatch(setIsConnectionErrorDialogOpen(true));
                    }
                  }}
                  sx={{
                    alignSelf: 'flex-start',
                    textTransform: 'none',
                    fontWeight: 600,
                    color: '#212B36',
                    bgcolor: 'white',
                    width: '360px',
                  }}
                  startIcon={<Iconify icon="mingcute:add-line" width={20} height={20} />}
                >
                  Add a New Rule
                </Button>
                <DeleteRuleDialog
                  open={openDeleteDialog.open}
                  onClose={() => setOpenDeleteDialog({ open: false, index: null })}
                  isLoading={loading}
                  onDelete={() => {
                    if (openDeleteDialog.index !== null) {
                      handleRemoveRule(arrayHelpers, openDeleteDialog.index, values);
                      setOpenDeleteDialog({ open: false, index: null });
                    }
                  }}
                />
              </Stack>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

export default Rules;
