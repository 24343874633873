import {
  Alert,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  Checkbox,
  FormGroup,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from '../../../../types/types';
import RelistLimit from './RelistLimit';
import { Automation, EbaySettings, PoshmarkSettings } from '@beta.limited/primelister';
import RelistingHours from './RelistHours';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

const RelistSettings = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
    loading,
  } = states.automations;
  const { activeClosetCredentialsId, hasActiveClosetConnection } = states.myShops;
  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isNumberOfPeopleToggled, setIsNumberOfPeopleToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const initialAgesEnabled =
    (automationsSettings as PoshmarkSettings)[Automation.AUTO_RELIST]?.config.filters.age
      ?.enabled || true;

  const initialAgeValue =
    (automationsSettings as PoshmarkSettings)[Automation.AUTO_RELIST]?.config.filters.age?.value ||
    3;

  const initialLikesEnabled =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.likes?.enabled || false;

  const initialLikesNumber =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.likes?.value || 0;

  const initialPriceRangeEnabled =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.priceRange?.enabled || false;

  const initialPriceRangeMin =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.priceRange?.min ?? 1;

  const initialPriceRangeMax =
    automationsSettings[Automation.AUTO_RELIST]?.config.filters.priceRange?.max ?? 2;

  const initialConditionEnabled =
    (automationsSettings as EbaySettings)[Automation.AUTO_RELIST]?.config.filters.condition
      ?.enabled || false;
  const initialConditionNew =
    (automationsSettings as EbaySettings)[Automation.AUTO_RELIST]?.config.filters.condition?.new ||
    false;
  const initialConditionUsed =
    (automationsSettings as EbaySettings)[Automation.AUTO_RELIST]?.config.filters.condition?.used ||
    false;

  const initialAgeMin = (automationsSettings as EbaySettings)[Automation.AUTO_RELIST]?.config.filters.ageRange?.min || 0;
  const initialAgeMax = (automationsSettings as EbaySettings)[Automation.AUTO_RELIST]?.config.filters.ageRange?.max || 99999;

  const validationSchema = Yup.object().shape({
    initialLikesNumber: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialAgeValue: Yup.number()
      .required('Please enter a number that is between 3 and 365.')
      .min(3, 'Please enter a number that is between 3 and 365.')
      .max(365, 'Please enter a number that is between 3 and 365.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialPriceRangeMin: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialPriceRangeMax: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialAgeMin: Yup.number()
      .when('initialAgesEnabled', {
        is: true,
        then: Yup.number()
          .required('Please enter a number that is between 0 and 99999.')
          .min(0, 'Please enter a number that is between 0 and 99999.')
          .max(99999, 'Please enter a number that is between 0 and 99999.')
          .test(
            'is-integer',
            'Invalid input. Please enter a whole number without decimals.',
            (value) => Number.isInteger(value)
          ),
      }),
    initialAgeMax: Yup.number()
      .when('initialAgesEnabled', {
        is: true,
        then: Yup.number()
          .required('Please enter a number that is between 0 and 99999.')
          .min(0, 'Please enter a number that is between 0 and 99999.')
          .max(99999, 'Please enter a number that is between 0 and 99999.')
          .test(
            'is-integer',
            'Invalid input. Please enter a whole number without decimals.',
            (value) => Number.isInteger(value)
          ),
      }),
  });
  const { currency, currencySymbol } = getCurrencyInfo(activeClosetAutomationPlatform);

  const handleRelistFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      initialLikesNumber,
      initialLikesEnabled,
      initialAgesEnabled,
      initialAgeValue,
      initialPriceRangeEnabled,
      initialPriceRangeMin,
      initialPriceRangeMax,
      initialConditionEnabled,
      initialConditionNew,
      initialConditionUsed,
      initialAgeMin,
      initialAgeMax,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handleAgesInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleRelistFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 3 && inputValue <= 365;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                filters: {
                  age: {
                    value: inputValue,
                  },
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !== handleRelistFormik.initialValues.initialLikesNumber;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  const handleLikesEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handleRelistFormik.setFieldValue('initialLikesEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_RELIST]: {
            config: {
              filters: {
                likes: {
                  enabled: newValue,
                },
              },
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleLikesInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleRelistFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 0 && inputValue < 100000;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                filters: {
                  likes: {
                    value: inputValue,
                  },
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !== handleRelistFormik.initialValues.initialLikesNumber;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  const handlePriceRangeEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handleRelistFormik.setFieldValue('initialPriceRangeEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_RELIST]: {
            config: {
              filters: {
                priceRange: {
                  enabled: newValue,
                },
              },
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
  };

  const renderPriceRangeAlert = () => {
    const minValue = handleRelistFormik.values.initialPriceRangeMin;
    const maxValue = handleRelistFormik.values.initialPriceRangeMax;
    let errorMessage = '';

    if (minValue === null || maxValue === null) {
      errorMessage = 'Both minimum and maximum price values are required.';
    } else if (minValue > maxValue) {
      errorMessage = 'Minimum price should be less than or equal to maximum price.';
    } else if (minValue < 0 || maxValue < 0 || minValue > 99999 || maxValue > 99999) {
      errorMessage = 'Please enter whole numbers between 0 and 99999 for both fields.';
    }

    if (errorMessage) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorMessage}
        </Alert>
      );
    }

    return null;
  };

  const pendingPriceUpdates = React.useRef<{ min: number | null; max: number | null }>({
    min: null,
    max: null,
  });

  const handlePriceValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'min' | 'max'
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      const formikField = field === 'min' ? 'initialPriceRangeMin' : 'initialPriceRangeMax';
      const inputValue = event.target.value === '' ? null : Number(event.target.value);

      const otherField = field === 'min' ? 'max' : 'min';
      const otherValue =
        field === 'min'
          ? handleRelistFormik.values.initialPriceRangeMax
          : handleRelistFormik.values.initialPriceRangeMin;

      handleRelistFormik.setFieldValue(formikField, inputValue);

      if (inputValue !== null) {
        const isValidInput = inputValue >= 0 && inputValue < 100000 && Number.isInteger(inputValue);

        if (isValidInput) {
          pendingPriceUpdates.current = {
            min: field === 'min' ? inputValue : otherValue ?? null,
            max: field === 'max' ? inputValue : otherValue ?? null,
          };

          onChangeTimer.current = setTimeout(() => {
            const { min, max } = pendingPriceUpdates.current;

            if (min !== null && max !== null && min <= max) {
              const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

              const dataForUpdating: IDataForUpdateAutomationSettings = {
                settings: {
                  [Automation.AUTO_RELIST]: {
                    config: {
                      filters: {
                        priceRange: pendingPriceUpdates.current,
                      },
                    },
                  },
                },
                toastMessage,
                displayName: selectedAutomationOption.displayName,
                activeClosetAutomationPlatform,
                activeClosetCredentialsId,
              };

              dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
            }
          }, 700);
        }
      }
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handleRelistFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const renderAlert = (errorText: string) => {
    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorText}
        </Alert>
      );
    }
  };

  const getActionText = () => {
    switch (platform) {
      case AutomationPlatform.EBAY:
        return {
          optionsTitle: 'Options',
          optionsTooltip:
            'Customize Re-create automation by turning on filters. Only listings that match your criteria will be re-created.',
          ageFilterLabel: 'Filter by age (in days) of listing',
          ageFilterTooltip:
            'Set the automation to re-create only the listings older than the age inputted or more',
          listingsOlderThan: 'Listings older than',
          likesFilterLabel: 'Filter by number of watchers of listing',
          likesFilterTooltip:
            'Set the automation to re-create only the listings that have more watchers than the number inputted',
          priceRangeLabel: 'Filter by price range',
          priceRangeTooltip:
            'Set the automation to re-create only the listings that fall within your selected price range',
          engagementTerm: 'watchers',
        };
      default:
        return {
          optionsTitle: 'Options',
          optionsTooltip:
            'Customize Re-list automation by turning on filters. Only listings that match your criteria will be re-listed.',
          ageFilterLabel: 'Filter by age (in days) of listing',
          ageFilterTooltip:
            'Set the automation to re-list only the listings older than the age inputted or more',
          listingsOlderThan: 'Listings older than',
          likesFilterLabel: 'Filter by number of likes',
          likesFilterTooltip:
            'Set the automation to re-list only the listings that have more likes than the number inputted',
          priceRangeLabel: 'Filter by price range',
          priceRangeTooltip:
            'Set the automation to re-list only the listings that fall within your selected price range',
          engagementTerm: 'likes',
        };
    }
  };

  const renderEnableAgesSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          checked={handleRelistFormik.values.initialAgesEnabled}
          key={'initialAgesEnabled'}
          control={
            <Switch
              name={'initialAgesEnabled'}
              value={handleRelistFormik.values.initialAgesEnabled}
              disabled
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              {' '}
              <Typography fontSize="14px" color="#212B36">
                {getActionText().ageFilterLabel}
              </Typography>
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title={`ℹ️ ${getActionText().ageFilterTooltip}`}
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );
  const isThereAnErrorOnAge = handleRelistFormik.errors.initialAgeValue && renderError;

  const renderAgeOptionInput = () =>
    isNumberOfPeopleToggled && platform !== AutomationPlatform.EBAY && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '13px !important',
          }}
        >
          {getActionText().listingsOlderThan}
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 3-365."
            placement={'top'}
          >
            <TextField
              type="number"
              disabled={loading}
              name="initialAgeValue"
              sx={{
                width: 0.22,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnErrorOnAge ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnErrorOnAge ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handleRelistFormik.values.initialAgeValue}
              onChange={handleAgesInputChange}
            />
          </Tooltip>
          days
        </Stack>
        {handleRelistFormik.errors.initialAgeValue &&
          renderAlert(handleRelistFormik?.errors?.initialAgeValue as string)}
      </>
    );

  const renderEbayAgeRangeInput = () =>
    isNumberOfPeopleToggled && platform === AutomationPlatform.EBAY && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '12px !important',
          }}
        >
          <Stack direction="row" alignItems="center">
            From
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                type="number"
                disabled={loading}
                sx={{
                  width: '66px !important',
                  mx: 1,
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: isThereAnErrorOnAge ? '#FFF2EF' : '#eeeff1',
                    color: isThereAnErrorOnAge ? '#B71C19' : '#000000',
                  },
                }}
                name="initialAgeMin"
                value={handleRelistFormik.values.initialAgeMin}
                onChange={(e) => handleAgeRangeChange(e, 'min')}
              />
            </Tooltip>
            days
          </Stack>
          <Stack direction="row" alignItems="center" ml="15px">
            To
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                sx={{
                  width: '66px !important',
                  mx: 1,
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: isThereAnErrorOnAge ? '#FFF2EF' : '#eeeff1',
                    color: isThereAnErrorOnAge ? '#B71C19' : '#000000',
                  },
                }}
                name="initialAgeMax"
                value={handleRelistFormik.values.initialAgeMax}
                onChange={(e) => handleAgeRangeChange(e, 'max')}
              />
            </Tooltip>
            days
          </Stack>
        </Stack>
        {renderAgeRangeAlert()}
      </>
    );

  const renderEnableLikesSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '8px !important',
        }}
      >
        <FormControlLabel
          checked={handleRelistFormik.values.initialLikesEnabled}
          key={'initialLikesEnabled'}
          control={
            <Switch
              disabled={loading}
              name={'initialLikesEnabled'}
              value={handleRelistFormik.values.initialLikesEnabled}
              onChange={handleLikesEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              {' '}
              {getActionText().likesFilterLabel}
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title={`ℹ️ ${getActionText().likesFilterTooltip}`}
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const isThereAnError = handleRelistFormik.errors.initialLikesNumber && renderError;

  const renderFollowCountInputField = () =>
    isNumberOfPeopleToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '13px !important',
          }}
        >
          Listings with
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 0-99999."
            placement={'top'}
          >
            <TextField
              type="number"
              name="initialLikesNumber"
              disabled={loading}
              sx={{
                width: 0.22,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handleRelistFormik.values.initialLikesNumber}
              onChange={handleLikesInputChange}
            />
          </Tooltip>
          or fewer {getActionText().engagementTerm}
        </Stack>
        {handleRelistFormik.errors.initialLikesNumber &&
          renderAlert(handleRelistFormik?.errors?.initialLikesNumber as string)}
      </>
    );

  const renderPriceRangeSwitch = () =>
    isNumberOfPeopleToggled && (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '8px !important',
        }}
      >
        <FormControlLabel
          checked={handleRelistFormik.values.initialPriceRangeEnabled}
          key={'initialPriceRangeEnabled'}
          control={
            <Switch
              disabled={loading}
              name={'initialPriceRangeEnabled'}
              value={handleRelistFormik.values.initialPriceRangeEnabled}
              onChange={handlePriceRangeEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              {getActionText().priceRangeLabel}
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title={`ℹ️ ${getActionText().priceRangeTooltip}`}
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const renderPriceRangeInputFields = () =>
    isNumberOfPeopleToggled &&
    handleRelistFormik.values.initialPriceRangeEnabled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '12px !important',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">From</Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                name="initialPriceRangeMin"
                sx={{
                  width: '66px !important',
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                    color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                  },
                }}
                value={handleRelistFormik.values.initialPriceRangeMin}
                onChange={(e) => handlePriceValueChange(e, 'min')}
              />
            </Tooltip>
            <Typography variant="body2">{currency}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">To</Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                name="initialPriceRangeMax"
                sx={{
                  width: '66px !important',
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                    color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                  },
                }}
                value={handleRelistFormik.values.initialPriceRangeMax}
                onChange={(e) => handlePriceValueChange(e, 'max')}
              />
            </Tooltip>
            <Typography variant="body2">{currency}</Typography>
          </Stack>
        </Stack>
        {renderPriceRangeAlert()}
      </>
    );

  const handleConditionFilterEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasActiveClosetConnection) {
      const isEnabled = event.target.checked;
      handleRelistFormik.setFieldValue('initialConditionEnabled', isEnabled);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_RELIST]: {
            config: {
              filters: {
                condition: {
                  enabled: isEnabled,
                },
              },
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleConditionChange =
    (condition: 'new' | 'used') => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (hasActiveClosetConnection) {
        const checked = event.target.checked;
        handleRelistFormik.setFieldValue(
          `initialCondition${condition === 'new' ? 'New' : 'Used'}`,
          checked
        );
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                filters: {
                  condition: {
                    [condition]: checked,
                  },
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };
        dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
      } else {
        dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
      }
    };

  const renderConditionFilter = () =>
    isNumberOfPeopleToggled &&
    platform === AutomationPlatform.EBAY && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            marginTop: '8px !important',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                disabled={loading}
                checked={handleRelistFormik.values.initialConditionEnabled}
                onChange={handleConditionFilterEnableChange}
              />
            }
            sx={{ mr: 0 }}
            label={
              <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
                Filter by condition
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={3000}
                  arrow
                  title={`ℹ️ Enable this option to re-list only items with selected conditions.`}
                  placement="top"
                >
                  <HelpOutlineIcon
                    sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }}
                  />
                </Tooltip>
              </Stack>
            }
          />
        </Stack>

        {handleRelistFormik.values.initialConditionEnabled && (
          <FormGroup
            sx={{
              marginLeft: '48px !important',
              marginTop: '12px !important',
            }}
          >
            <FormControlLabel
              disabled={loading}
              control={
                <Checkbox
                  checked={handleRelistFormik.values.initialConditionNew}
                  onChange={handleConditionChange('new')}
                />
              }
              label={<Typography variant="body2">New</Typography>}
            />
            <FormControlLabel
              disabled={loading}
              control={
                <Checkbox
                  checked={handleRelistFormik.values.initialConditionUsed}
                  onChange={handleConditionChange('used')}
                />
              }
              label={<Typography variant="body2">Used</Typography>}
            />
          </FormGroup>
        )}
      </>
    );

  const handleAgeRangeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'min' | 'max'
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      const formikField = field === 'min' ? 'initialAgeMin' : 'initialAgeMax';
      handleRelistFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const otherField = field === 'min' ? 'max' : 'min';
      const otherValue = field === 'min' 
        ? handleRelistFormik.values.initialAgeMax 
        : handleRelistFormik.values.initialAgeMin;

      const isValidInput = inputValue >= 0 && inputValue <= 99999;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;
      
      // Min-max validation kontrolü
      const isValidRange = field === 'min' 
        ? inputValue <= otherValue 
        : inputValue >= otherValue;

      if (hasValidInputValue && isValidRange) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                filters: {
                  age: {
                    [field]: inputValue,
                  },
                },
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        onChangeTimer.current = setTimeout(() => {
          dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  const renderAgeRangeAlert = () => {
    const minValue = handleRelistFormik.values.initialAgeMin;
    const maxValue = handleRelistFormik.values.initialAgeMax;
    let errorMessage = '';

    if (minValue === null || maxValue === null) {
      errorMessage = 'Both minimum and maximum age values are required.';
    } else if (minValue > maxValue) {
      errorMessage = 'Minimum age should be less than or equal to maximum age.';
    } else if (minValue < 0 || maxValue < 0 || minValue > 99999 || maxValue > 99999) {
      errorMessage = 'Please enter whole numbers between 0 and 99999 for both fields.';
    }

    if (errorMessage) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorMessage}
        </Alert>
      );
    }

    return null;
  };

  const shouldRenderAgeOptions = handleRelistFormik.values.initialAgesEnabled;
  const shouldRenderLikeInput = handleRelistFormik.values.initialLikesEnabled;

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <RelistLimit />
      <Divider sx={{ borderStyle: 'dashed' }} />
      <RelistingHours />
      <Divider sx={{ borderStyle: 'dashed', mt: '20px!important' }} />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          {getActionText().optionsTitle}
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title={`ℹ️ ${getActionText().optionsTooltip}`}
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsNumberOfPeopleToggled(!isNumberOfPeopleToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify
            icon={isNumberOfPeopleToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          />
        </IconButton>
      </Stack>
      {renderEnableAgesSwitch()}
      {shouldRenderAgeOptions && (
        platform === AutomationPlatform.EBAY ? renderEbayAgeRangeInput() : renderAgeOptionInput()
      )}
      {renderEnableLikesSwitch()}
      {shouldRenderLikeInput && renderFollowCountInputField()}
      {renderPriceRangeSwitch()}
      {renderPriceRangeInputFields()}
      {renderConditionFilter()}
    </Stack>
  );
};

export default RelistSettings;
