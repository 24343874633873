import React from 'react';
import {
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Checkbox,
  FormGroup,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import { Automation } from '@beta.limited/primelister';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';

interface FilterByConditionProps {
  ruleIndex: number;
}

const getTooltipText = (platform: AutomationPlatform) => {
  switch (platform) {
    case AutomationPlatform.EBAY:
      return 'ℹ️ Enable this option to send offers only to watched items with selected conditions.';
    default:
      return 'ℹ️ Enable this option to send offers only to liked items with selected conditions.';
  }
};

const FilterByCondition: React.FC<FilterByConditionProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const { selectedAutomationOption, automationsSettings, activeClosetAutomationPlatform, loading } =
    useSelector((state: IRootState) => state.ebayAutomations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.ebayMyShops
  );
  const { platform } = useAutomationPlatform();

  const initialValues = {
    enabled:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.rules[ruleIndex]?.filters
        .condition?.enabled || false,
    new:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.rules[ruleIndex]?.filters
        .condition?.new || false,
    used:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.rules[ruleIndex]?.filters
        .condition?.used || false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  });

  const updateAutomationSettings = (values: typeof initialValues) => {
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

    const updatedRules = automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS].config.rules.map(
      (rule, index) =>
        index === ruleIndex
          ? {
              ...rule,
              filters: {
                ...rule?.filters,
                condition: {
                  enabled: values.enabled,
                  new: values.new,
                  used: values.used,
                },
              },
            }
          : rule
    );

    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
          config: {
            rules: updatedRules,
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };

    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  const handleConditionFilterEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasActiveClosetConnection) {
      dispatch(setIsConnectionErrorDialogOpen(true));
      return;
    }

    const isEnabled = event.target.checked;
    formik.setFieldValue('enabled', isEnabled);
    updateAutomationSettings({ ...formik.values, enabled: isEnabled });
  };

  const handleConditionChange =
    (condition: 'new' | 'used') => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!hasActiveClosetConnection) {
        dispatch(setIsConnectionErrorDialogOpen(true));
        return;
      }

      const checked = event.target.checked;
      formik.setFieldValue(condition, checked);
      updateAutomationSettings({ ...formik.values, [condition]: checked });
    };

  return (
    <Stack spacing={2} px={0}>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              disabled={loading}
              checked={formik.values.enabled}
              onChange={handleConditionFilterEnableChange}
            />
          }
          sx={{
            mr: 0,
          }}
          label="Filter by condition"
        />
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          arrow
          title={getTooltipText(platform)}
          placement="top"
        >
          <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
        </Tooltip>
      </Stack>

      {formik.values.enabled && (
        <FormGroup
          sx={{
            marginLeft: '48px !important',
            marginTop: '12px !important',
          }}
        >
          <FormControlLabel
            disabled={loading}
            control={
              <Checkbox checked={formik.values.new} onChange={handleConditionChange('new')} />
            }
            label={<Typography variant="body2">New</Typography>}
          />
          <FormControlLabel
            disabled={loading}
            control={
              <Checkbox checked={formik.values.used} onChange={handleConditionChange('used')} />
            }
            label={<Typography variant="body2">Used</Typography>}
          />
        </FormGroup>
      )}
    </Stack>
  );
};

export default FilterByCondition;
