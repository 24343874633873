import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import { IRootState } from 'src/store';
import { FilterOptionsEnum, filterOptionsList } from '../utils/constants';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import NumericFilter from './NumericFilter';
import {
  getFilterCategoriesRequest,
  getMyListingsRequest,
  setHasActiveSearch,
  setIsFiltersBadgeInvisible,
  setIsListingsFilterDrawerOpen,
  setMyListings,
  setResetFiltersAndQueries,
  setSelectedFilterCategory,
  setSelectedFilterDepartment,
  setSelectedFilterOption,
  setSelectedFilterSubcategory,
  setSelectedListings,
} from 'src/store/automations/slices/myListingsSlice';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { debounce } from 'lodash';
import { isFieldValid } from '../helpers/validation';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import { flushSync } from 'react-dom';
import ListingService from '../services/ListingsService';
import CategoryListTreeView from './shared/CategoryListTreeView';

export interface FormValues {
  listingAgeMin: string;
  listingAgeMax: string;
  numberOfLikesMin: string;
  numberOfLikesMax: string;
  priceMin: string;
  priceMax: string;
  availability: FilterOptionsEnum;
  department: string;
  category: string;
  subcategory: string; // Add subcategory as optional
}

const FIELD_NAMES: { [key in keyof FormValues]: key } = {
  listingAgeMin: 'listingAgeMin',
  listingAgeMax: 'listingAgeMax',
  numberOfLikesMin: 'numberOfLikesMin',
  numberOfLikesMax: 'numberOfLikesMax',
  priceMin: 'priceMin',
  priceMax: 'priceMax',
  availability: 'availability',
  category: 'category',
  subcategory: 'subcategory',
  department: 'department',
};

export default function ListingsFilterDrawer() {
  const { isListingsFilterDrawerOpen, searchQuery, selectedSortOption, isFiltersBadgeInvisible } =
    useSelector((state: IRootState) => state.myListings);
  const [searchParams, setSearchParams] = useSearchParams();
  const { email } = isLoggedIn();
  const {
    hasActiveClosetConnection,
    closetList,
    hasPoshmarkSubscription,
    activeClosetCredentialsId,
  } = useSelector((state: IRootState) => state.myCloset);
  const { hasSubscriptionChecked } = useSelector((state: IRootState) => state.account);

  const validationSchema = Yup.object().shape({
    [FIELD_NAMES.listingAgeMin]: Yup.number()
      .typeError('Enter a whole number.')
      .min(0, 'Enter a whole number.')
      .integer('Enter a whole number.'),
    [FIELD_NAMES.listingAgeMax]: Yup.number()
      .typeError('Enter a whole number.')
      .min(0, 'Enter a whole number.')
      .integer('Enter a whole number.'),
    [FIELD_NAMES.numberOfLikesMin]: Yup.number()
      .typeError('Enter a whole number.')
      .min(0, 'Enter a whole number.')
      .integer('Enter a whole number.'),
    [FIELD_NAMES.numberOfLikesMax]: Yup.number()
      .typeError('Enter a whole number.')
      .min(0, 'Enter a whole number.')
      .integer('Enter a whole number.'),
    [FIELD_NAMES.priceMin]: Yup.number()
      .typeError('Enter a whole number.')
      .min(0, 'Enter a whole number.')
      .integer('Enter a whole number.'),
    [FIELD_NAMES.priceMax]: Yup.number()
      .typeError('Enter a whole number.')
      .min(0, 'Enter a whole number.')
      .integer('Enter a whole number.'),
    [FIELD_NAMES.availability]: Yup.mixed<FilterOptionsEnum>()
      .oneOf(Object.values(FilterOptionsEnum), 'Invalid availability option.')
      .required('Availability is required.'),
    [FIELD_NAMES.department]: Yup.string().required('Department is required.'),
    [FIELD_NAMES.category]: Yup.string().optional(),
    [FIELD_NAMES.subcategory]: Yup.string().optional(), // Add subcategory validation
  });

  const initialValues: FormValues = {
    listingAgeMin: searchParams.get(FIELD_NAMES.listingAgeMin) || '',
    listingAgeMax: searchParams.get(FIELD_NAMES.listingAgeMax) || '',
    numberOfLikesMin: searchParams.get(FIELD_NAMES.numberOfLikesMin) || '',
    numberOfLikesMax: searchParams.get(FIELD_NAMES.numberOfLikesMax) || '',
    priceMin: searchParams.get(FIELD_NAMES.priceMin) || '',
    priceMax: searchParams.get(FIELD_NAMES.priceMax) || '',
    availability:
      (searchParams.get(FIELD_NAMES.availability) as FilterOptionsEnum) ||
      filterOptionsList[1].value,
    department: searchParams.get(FIELD_NAMES.department) || 'all-departments',
    category: searchParams.get(FIELD_NAMES.category) || '',
    subcategory: searchParams.get(FIELD_NAMES.subcategory) || '', // Initialize subcategory
  };

  const handleListingFilterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const [isApplyDisabled, setIsApplyDisabled] = useState(true);

  // This useEffect checks if any form values have changed from their initial values
  // and enables or disables the apply button accordingly.
  useEffect(() => {
    const isChanged = (Object.keys(initialValues) as (keyof FormValues)[]).some(
      (key) =>
        handleListingFilterFormik.values[key as keyof FormValues] !==
        initialValues[key as keyof FormValues]
    );
    setIsApplyDisabled(!isChanged);
  }, [handleListingFilterFormik.values]);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsListingsFilterDrawerOpen(false));
  };

  const resetFilters = ({ clearSearchParams = false }: { clearSearchParams?: boolean }) => {
    handleListingFilterFormik.resetForm();
    handleClearListingAgeFilter();
    handleClearNumberOfLikesFilter();
    handleClearPriceFilter();
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.availability, filterOptionsList[1].value);
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.department, 'all-departments');
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.category, '');
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.subcategory, ''); // Reset subcategory
    dispatch(setSelectedFilterDepartment('all-departments'));
    dispatch(setSelectedFilterCategory(''));
    dispatch(setSelectedFilterSubcategory(''));

    if (clearSearchParams) {
      // Update query parameters
      const newSearchParams = new URLSearchParams(searchParams);
      Object.keys(FIELD_NAMES).forEach((key) => {
        if (key !== FIELD_NAMES.availability && key !== 'shop') {
          newSearchParams.delete(key);
        }
      });
      newSearchParams.set(FIELD_NAMES.availability, filterOptionsList[1].value); // Default value
      newSearchParams.set(FIELD_NAMES.department, 'all-departments'); // Default value
      newSearchParams.delete(FIELD_NAMES.category); // Default value
      newSearchParams.delete(FIELD_NAMES.subcategory); // Remove subcategory from query params
      setSearchParams(newSearchParams);
    }
  };

  const handleClearListingAgeFilter = () => {
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.listingAgeMin, '');
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.listingAgeMax, '');
  };

  const handleClearNumberOfLikesFilter = () => {
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.numberOfLikesMin, '');
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.numberOfLikesMax, '');
  };

  const handleClearPriceFilter = () => {
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.priceMin, '');
    handleListingFilterFormik.setFieldValue(FIELD_NAMES.priceMax, '');
  };

  const isListingAgeMinValid = isFieldValid(
    handleListingFilterFormik.values[FIELD_NAMES.listingAgeMin],
    handleListingFilterFormik.errors[FIELD_NAMES.listingAgeMin]
  );

  const isListingAgeMaxValid = isFieldValid(
    handleListingFilterFormik.values[FIELD_NAMES.listingAgeMax],
    handleListingFilterFormik.errors[FIELD_NAMES.listingAgeMax]
  );

  const isNumberOfLikesMinValid = isFieldValid(
    handleListingFilterFormik.values[FIELD_NAMES.numberOfLikesMin],
    handleListingFilterFormik.errors[FIELD_NAMES.numberOfLikesMin]
  );

  const isNumberOfLikesMaxValid = isFieldValid(
    handleListingFilterFormik.values[FIELD_NAMES.numberOfLikesMax],
    handleListingFilterFormik.errors[FIELD_NAMES.numberOfLikesMax]
  );

  const isPriceMinValid = isFieldValid(
    handleListingFilterFormik.values[FIELD_NAMES.priceMin],
    handleListingFilterFormik.errors[FIELD_NAMES.priceMin]
  );

  const isPriceMaxValid = isFieldValid(
    handleListingFilterFormik.values[FIELD_NAMES.priceMax],
    handleListingFilterFormik.errors[FIELD_NAMES.priceMax]
  );

  /**
   * Checks if a filter is applied by comparing the form value with a default value.
   *
   * @param {keyof FormValues} key - The key of the form value to check.
   * @param {any} defaultValue - The default value to compare against.
   * @returns {boolean} - Returns true if the filter is applied, otherwise false.
   */
  const isFilterApplied = (key: keyof FormValues, defaultValue: any): boolean => {
    const value = handleListingFilterFormik.values[key];
    return value !== defaultValue && value !== '';
  };

  /**
   * Checks if any filter is applied by verifying each filter field against its default value.
   *
   * @returns {boolean} - Returns true if any filter is applied, otherwise false.
   */
  const checkIfAnyFilterApplied = (): boolean =>
    isFilterApplied(FIELD_NAMES.availability, filterOptionsList[1].value) ||
    isFilterApplied(FIELD_NAMES.listingAgeMin, '') ||
    isFilterApplied(FIELD_NAMES.listingAgeMax, '') ||
    isFilterApplied(FIELD_NAMES.numberOfLikesMin, '') ||
    isFilterApplied(FIELD_NAMES.numberOfLikesMax, '') ||
    isFilterApplied(FIELD_NAMES.priceMin, '') ||
    isFilterApplied(FIELD_NAMES.priceMax, '') ||
    isFilterApplied(FIELD_NAMES.department, 'all-departments') ||
    isFilterApplied(FIELD_NAMES.category, '') ||
    isFilterApplied(FIELD_NAMES.subcategory, ''); // Check if subcategory is applied

  const currentShop = searchParams.get('shop');

  useEffect(() => {
    const fetchFilterCategories = () => {
      const shopParam = searchParams.get('shop');
      const departmentParam = searchParams.get(FIELD_NAMES.department);
      const categoryParam = searchParams.get(FIELD_NAMES.category);
      const credentialsIdByShopParam = ListingService.getCredentialsIdByShopParam(
        shopParam,
        closetList
      );

      dispatch(
        getFilterCategoriesRequest({
          credentialsId: credentialsIdByShopParam || activeClosetCredentialsId,
          department: departmentParam || undefined,
          category: categoryParam || undefined,
        })
      );
    };

    if (
      hasSubscriptionChecked &&
      hasActiveClosetConnection &&
      hasPoshmarkSubscription &&
      activeClosetCredentialsId
    ) {
      if (checkIfAnyFilterApplied()) {
        handleApplyFilters();
        updateBadgeVisibility();
      }
      fetchFilterCategories();
    }
  }, [
    hasActiveClosetConnection,
    hasSubscriptionChecked,
    hasPoshmarkSubscription,
    activeClosetCredentialsId,
  ]);

  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }

    dispatch(setResetFiltersAndQueries());
    flushSync(() => {
      resetFilters({
        clearSearchParams: true,
      });
      dispatch(setIsFiltersBadgeInvisible(true));
    });
  }, [currentShop]);

  useEffect(() => {
    if (!isFiltersBadgeInvisible) {
      dispatch(setHasActiveSearch(false));
    }
  }, [isFiltersBadgeInvisible]);

  /**
   * Updates the visibility of the badge based on whether any filter is applied.
   */
  const updateBadgeVisibility = (): void => {
    const isAnyFilterApplied = checkIfAnyFilterApplied();
    dispatch(setIsFiltersBadgeInvisible(!isAnyFilterApplied));
  };

  const handleApplyFilters = useCallback(
    debounce((event?: React.FormEvent) => {
      dispatch(setHasActiveSearch(true));
      event?.preventDefault();
      event?.stopPropagation();
      const newSearchParams = new URLSearchParams(searchParams);

      Object.keys(handleListingFilterFormik.values).forEach((key) => {
        const value = handleListingFilterFormik.values[key as keyof FormValues];
        if (value !== null && value !== undefined && value !== '') {
          newSearchParams.set(key, value.toString());
        } else {
          newSearchParams.delete(key); // Remove the param if the value is empty
        }
      });

      setSearchParams(newSearchParams);
      setIsApplyDisabled(true); // Disable the button after applying filters
      dispatch(setMyListings([]));
      dispatch(setSelectedListings(new Set()));

      const sort = searchParams.get('sort') || selectedSortOption.value;
      const shop = searchParams.get('shop');

      const credentialsIdByShopParam = ListingService.getCredentialsIdByShopParam(shop, closetList);

      const filters = ListingService.buildFilters({
        nextPageId: undefined,
        searchQuery,
        availability: handleListingFilterFormik.values.availability,
        selectedFilterOption: { value: handleListingFilterFormik.values.availability },
        selectedSortOption: { value: sort },
        listingAgeMin: handleListingFilterFormik.values.listingAgeMin,
        listingAgeMax: handleListingFilterFormik.values.listingAgeMax,
        numberOfLikesMin: handleListingFilterFormik.values.numberOfLikesMin,
        numberOfLikesMax: handleListingFilterFormik.values.numberOfLikesMax,
        priceMin: handleListingFilterFormik.values.priceMin,
        priceMax: handleListingFilterFormik.values.priceMax,
        department: handleListingFilterFormik.values.department,
        category: handleListingFilterFormik.values.category,
        subcategory: handleListingFilterFormik.values.subcategory,
      });

      dispatch(
        getMyListingsRequest({
          credentialsId: credentialsIdByShopParam || activeClosetCredentialsId,
          filters,
        })
      );

      updateBadgeVisibility(); // Update badge visibility after applying filters
    }, 200), // 200ms debounce delay
    [
      searchParams,
      handleListingFilterFormik.values,
      activeClosetCredentialsId,
      searchQuery,
      selectedSortOption,
    ]
  );

  const handleCategorySelect = (department: string, category: string, subcategory: string) => {
    handleListingFilterFormik.setFieldValue('department', department);
    handleListingFilterFormik.setFieldValue('category', category);
    handleListingFilterFormik.setFieldValue('subcategory', subcategory);
  };

  return (
    <Drawer
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      anchor="right"
      open={isListingsFilterDrawerOpen}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        zIndex: 9999999,
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '280px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: '24px',
        }}
      >
        <form onSubmit={handleApplyFilters}>
          <Stack>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '16px 8px 16px 20px',
                borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                position: 'sticky',
                zIndex: 99,
                backgroundColor: 'white',
                top: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  lineHeight: '28px',
                  fontWeight: 700,
                  color: '#212B36',
                }}
              >
                Filters
              </Typography>

              <Box
                sx={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={() => resetFilters({ clearSearchParams: false })}
                  size="small"
                  sx={{ cursor: 'pointer', color: '#637381', p: '8px' }}
                >
                  <Badge variant="dot" invisible={isFiltersBadgeInvisible} color="error">
                    <Iconify width={20} height={20} icon="solar:restart-bold" />
                  </Badge>
                </IconButton>
                <IconButton
                  onClick={handleClose}
                  size="small"
                  sx={{ cursor: 'pointer', color: '#637381', p: '8px' }}
                >
                  <Iconify width={20} height={20} icon="mingcute:close-line" />
                </IconButton>
              </Box>
            </Box>

            <Stack
              gap={3}
              sx={{
                padding: '0 20px',
              }}
            >
              <Box
                sx={{
                  mt: '24px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    display: 'flex',
                    alignItems: 'center',
                    mb: '8px',
                  }}
                >
                  Category
                </Typography>

                <CategoryListTreeView onSelect={handleCategorySelect} />
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '22px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Availability
                </Typography>

                <Box
                  sx={{
                    mt: '8px',
                  }}
                >
                  <RadioGroup
                    row
                    sx={{
                      flexWrap: 'nowrap',
                      flexDirection: 'column',
                    }}
                    value={handleListingFilterFormik.values.availability}
                    onChange={(e) => {
                      handleListingFilterFormik.setFieldValue('availability', e.target.value);
                      const selectedFilterOption = filterOptionsList.find(
                        (option) => option.value === e.target.value
                      );
                      dispatch(setSelectedFilterOption(selectedFilterOption!));
                    }}
                    name="availability"
                  >
                    {filterOptionsList.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        sx={{
                          height: '36px',
                        }}
                        control={<Radio />}
                        label={option.displayName}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Box>

              <NumericFilter
                formik={handleListingFilterFormik}
                isMinValid={isListingAgeMinValid}
                isMaxValid={isListingAgeMaxValid}
                onClear={handleClearListingAgeFilter}
                label="Listing Age"
                minName="listingAgeMin"
                maxName="listingAgeMax"
              />

              <NumericFilter
                formik={handleListingFilterFormik}
                isMinValid={isNumberOfLikesMinValid}
                isMaxValid={isNumberOfLikesMaxValid}
                onClear={handleClearNumberOfLikesFilter}
                label="Number of Likes"
                minName="numberOfLikesMin"
                maxName="numberOfLikesMax"
              />

              <NumericFilter
                formik={handleListingFilterFormik}
                isMinValid={isPriceMinValid}
                isMaxValid={isPriceMaxValid}
                onClear={handleClearPriceFilter}
                label="Price"
                minName="priceMin"
                maxName="priceMax"
              />
            </Stack>
          </Stack>
        </form>
        <Box
          sx={{
            padding: '0 20px 24px 20px',
            position: 'sticky',
            zIndex: 1,
            bottom: 0,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <LoadingButton
            onClick={handleApplyFilters}
            variant="contained"
            disabled={!handleListingFilterFormik.isValid || isApplyDisabled}
          >
            Apply Filters
          </LoadingButton>

          <Button
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            variant="outlined"
            sx={{
              width: '100%',
              justifyItems: 'flex-end',
              justifySelf: 'flex-end',
              textTransform: 'none',
              borderColor: 'rgba(145, 158, 171, 0.32)',
              whiteSpace: 'nowrap',
              textWrap: 'nowrap',
              height: '38px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
                cursor: 'unset',
                borderColor: 'rgba(145, 158, 171, 0.32)',
              },
            }}
          >
            <Typography
              sx={{
                width: '100%',
                fontSize: '13px',
                fontWeight: 400,
                color: '#212B36',
                lineHeight: '22px',
                textWrap: 'nowrap',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              Need other filters? Request it&nbsp;
              <Typography
                onClick={() => {
                  window.open(`https://tally.so/r/3Xe7Jj?email=${email}`, '_blank');
                }}
                sx={{
                  fontSize: '13px',
                  fontWeight: 400,
                  textDecoration: 'underline',
                  '&:hover': {
                    color: '#2065D1',
                    cursor: 'pointer',
                  },
                }}
              >
                here.
              </Typography>
            </Typography>
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
