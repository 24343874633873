import { LoadingButton } from '@mui/lab';
import { Stack, MenuItem, Avatar, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { IRootState } from 'src/store';
import {
  getActivityListRequest,
  getOngoingListRequest,
  setInitialLoading,
  setSelectedOngoingTask,
} from 'src/store/automations/slices/activitySlice';
import {
  ACTIVITY_REQUEST_TYPE,
  automationItemsList,
  completedTabItemsList,
  ongoingTabItemsList,
} from '../utils/constants';
import { checkValueForRelist } from '../utils/helpers';
import { IActivityItem } from '../utils/types';
import { SubscriptionType, UserAction } from '@beta.limited/primelister';
import { useLocation } from 'react-router';

const OngoingTabDropdown = () => {
  const dispatch = useDispatch();
  const { activeClosetAutomationPlatform } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, activeCloset } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const { selectedOngoingTask, initialLoading } = useSelector(
    (state: IRootState) => state.activity
  );

  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);

  const handlePopoverClose = () => setOpenDropdown(null);

  const handleDropdown = (e: React.MouseEvent<HTMLElement>) =>
    setOpenDropdown(e.currentTarget.firstElementChild as HTMLElement);

  const location = useLocation();

  const hashValue = location.hash.substring(1);
  const matchedItem = ongoingTabItemsList.find((item) => item.urlParam === hashValue);
  if (matchedItem && matchedItem.urlParam !== selectedOngoingTask.urlParam) {
    dispatch(setSelectedOngoingTask(matchedItem));
  }

  const handleActivitySelection = async (item: IActivityItem) => {
    handlePopoverClose();
    dispatch(setSelectedOngoingTask(item));
    setTimeout(() => {
      if (activeClosetCredentialsId) {
        dispatch(setInitialLoading(true));
        dispatch(
          getOngoingListRequest({
            ...(item.value !== 'all' && { action: item.value as UserAction }),
            product: SubscriptionType.POSHMARK_AUTOMATION,
            shop: activeCloset.marketplaceUserName,
          })
        );
      }
      window.location.hash = item.urlParam;
    }, 500);
  };

  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        loading={initialLoading}
        color="inherit"
        onClick={handleDropdown}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
          backgroundColor: '#EDEFF2',
          color: 'black',
          p: '4px 8px',
          borderRadius: '8px',
          height: '36px',
          textTransform: 'none',
          [theme.breakpoints.down(380)]: {
            maxWidth: '200px',
          },
          [theme.breakpoints.down(320)]: {
            maxWidth: '180px',
          },
        }}
      >
        <Avatar
          src={`/icons/activityIcons/${selectedOngoingTask.name ?? 'allActivity'}.svg`}
          sx={{ fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 }}
        />
        <Typography
          sx={{
            fontSize: '14px !important',
            lineHeight: '24px',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          }}
        >
          {selectedOngoingTask.displayName}
        </Typography>
        <Iconify
          icon={openDropdown ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          sx={{ ml: '10px' }}
        />
      </LoadingButton>
      <MenuPopover
        open={openDropdown}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        arrow={'top-left'}
        sx={{ minWidth: 212, ml: '-26px !important', mt: '6px !important' }}
        disabledArrow={false}
      >
        {ongoingTabItemsList.map((item) => (
          <MenuItem
            onClick={() => handleActivitySelection(item)}
            key={item.name}
            sx={{
              fontWeight: '400 !important',
              backgroundColor: item.name === selectedOngoingTask.name ? '#EDEFF2' : 'white',
              p: '6px 8px !important',
              maxHeight: '34px !important',
              minHeight: '34px !important',
            }}
          >
            <Avatar
              src={`/icons/activityIcons/${item.name}.svg`}
              sx={{
                mr: 1,
                fontSize: '1rem',
                borderRadius: '0',
                maxWidth: '20px',
                maxHeight: '20px',
              }}
            />
            {item.displayName}
          </MenuItem>
        ))}
      </MenuPopover>
    </Stack>
  );
};

export default OngoingTabDropdown;
