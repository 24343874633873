import {
  Alert,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import { useAutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

const OfferDelay = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
  } = states.automations;
  const { activeClosetCredentialsId, hasActiveClosetConnection } = states.myShops;

  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isOfferDelayToggled, setIsOfferDelayToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const offerDelayTimeInitialValue =
    automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.offerDelayMinutes || 15;

  const validationSchema = Yup.object().shape({
    offerDelayTimeInitialValue: Yup.number()
      .required('Please enter a number that is between 1 and 120.')
      .min(1, 'Please enter a number that is between 1 and 120.')
      .max(120, 'Please enter a number that is between 1 and 120.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const handleOfferDelayFormik = useFormik({
    enableReinitialize: true,
    initialValues: { offerDelayTimeInitialValue },
    validationSchema,
    onSubmit: () => {},
  });

  const handleAutomationSettingsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleOfferDelayFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 1 && inputValue < 121;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
              config: {
                offerDelayMinutes: inputValue,
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          Number(handleOfferDelayFormik.initialValues.offerDelayTimeInitialValue);

        onChangeTimer.current = setTimeout(() => {
          if (isInputValueDifferentFromInitial) {
            dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
          }
        }, 700);
      }
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handleOfferDelayFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const renderAlert = () => {
    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {handleOfferDelayFormik.errors.offerDelayTimeInitialValue as string}
        </Alert>
      );
    }
  };

  const isThereAnError = handleOfferDelayFormik.errors.offerDelayTimeInitialValue && renderError;

  const renderFollowCountInputField = () =>
    isOfferDelayToggled && (
      <>
        <Stack direction="row" alignItems="center" sx={{ fontSize: '14px !important' }}>
          Wait
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 1-120."
            placement={'top'}
          >
            <TextField
              type="number"
              name="offerDelayTimeInitialValue"
              sx={{
                width: 0.19,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              value={handleOfferDelayFormik.values.offerDelayTimeInitialValue}
              onChange={handleAutomationSettingsChange}
            />
          </Tooltip>
          mins before sending an offer
        </Stack>
        {handleOfferDelayFormik.errors.offerDelayTimeInitialValue && renderAlert()}
      </>
    );

  return (
    <Stack spacing={2.125} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Offer Delay
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Set a minimum wait time for sending offers after receiving a like. Automation checks for new likes periodically, so a brief lag of a few minutes in sending offers may occur."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 1 }} />
          </Tooltip>
        </Typography>
        <IconButton
          size="small"
          onClick={() => setIsOfferDelayToggled(!isOfferDelayToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isOfferDelayToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>
      {renderFollowCountInputField()}
    </Stack>
  );
};

export default OfferDelay;
