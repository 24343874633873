import { Avatar, Chip, IconButton, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  setIsShareHourDialogOpen,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Iconify from 'src/components/iconify';

import { Automation, TaskHourConfig } from '@beta.limited/primelister';
import { mockShareHoursArray } from './constants';
import { sortTimeOfDay } from 'src/utils/sortTimeOfDay';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';

const SharingHours = () => {
  const dispatch = useDispatch();
  const mobileEdgeMatches = useMediaQuery('(min-width:370px)');
  const { automationsSettings, loading } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const [isSharingHoursToggled, setIsSharingHoursToggled] = useState(true);

  const shareHoursArray = automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config?.taskHours
    ? sortTimeOfDay(automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config?.taskHours)
    : mockShareHoursArray;
  const { shouldConnectionErrorDialogOpen } = useCheckSubscriptionStatus();

  const renderSharingHours = () => (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '-8px !important',
        }}
      >
        {shareHoursArray.map((item, index) => (
          <Tooltip
            key={item.timeOfDay}
            title="ℹ️ Click to switch sharing on/off and set the share limit for this hour."
          >
            <Chip
              disabled={loading}
              variant="soft"
              deleteIcon={
                <Avatar
                  sx={{ width: '18px', height: '18px' }}
                  src={`/icons/automationIcons/sharingHour${
                    item.enabled ? 'Enabled' : 'Disabled'
                  }.svg`}
                />
              }
              onClick={() => handleHourClick(item)}
              onDelete={() => handleHourClick(item)}
              label={item.timeOfDay}
              color={item.enabled ? 'success' : 'warning'}
              sx={{
                fontWeight: 400,
                fontSize: '13px !important',
                cursor: 'pointer',
                minWidth: mobileEdgeMatches ? '79px' : '90px',
                maxWidth: mobileEdgeMatches ? '79px' : '90px',
                mb: '8px',
                justifyContent: 'flex-end ',
              }}
            />
          </Tooltip>
        ))}
      </Stack>
      <Typography variant="body2" sx={{ alignSelf: 'center' }}>
        ℹ️ You've enabled {enabledSharesCount()} out of 9,000 daily shares.
      </Typography>
    </>
  );

  const handleHourClick = (hour: TaskHourConfig) => {
    if (!hasActiveClosetConnection || !activeClosetCredentialsId) {
      dispatch(setIsConnectionErrorDialogOpen(true));
    } else {
      dispatch(setIsShareHourDialogOpen({ isOpen: true, sharingHourDialogData: hour }));
    }
  };

  const enabledSharesCount = function getTotalTaskLimitForEnabled() {
    let totalTaskLimit = 0;
    if (automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config?.taskHours) {
      for (const hourObj of automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config?.taskHours) {
        if (hourObj?.enabled) {
          totalTaskLimit += hourObj?.taskLimit;
        }
      }
    }
    return totalTaskLimit.toLocaleString();
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Sharing Hours
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Determine the hours during which your listings will be automatically shared."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>
        <IconButton
          size="small"
          onClick={() => setIsSharingHoursToggled(!isSharingHoursToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isSharingHoursToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>
      {isSharingHoursToggled && renderSharingHours()}
    </Stack>
  );
};

export default SharingHours;
