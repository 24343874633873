import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { crosslistEndpoints } from 'src/endpoints';

import axiosInstance, { setHeader } from 'src/utils/axios';
import cookie from 'react-cookies';
import TagManager from 'react-gtm-module';
import packageJson from '../../../../package.json';

import {
  authLoginFail,
  authLoginSuccess,
  authLoginRequest,
  authNewPasswordFail,
  authNewPasswordRequest,
  authNewPasswordSuccess,
  authResetPasswordFail,
  authResetPasswordRequest,
  authResetPasswordSuccess,
  setEmailfromResetPage,
  setIsResetRequestDialogOpen,
  setIsResetSuccessfulDialogOpen,
  authInitialize,
  authInitializeResult,
  authLogout,
  authSignUpSuccess,
  authSignUpFail,
  authSignUpRequest,
  getAppVersionsRequest,
  getAppVersionsFail,
  getAppVersionsSuccess,
  setSignUpResponse,
  IAuthState,
  setLoginData,
  setIsUserNotVerifiedDialogOpen,
  sendVerificationRequest,
  sendVerificationSuccess,
  sendVerificationFail,
  getExtensionVersionSuccess,
  getExtensionVersionFail,
  getExtensionVersionRequest,
} from '../slices/authSlice';

import {
  ILoginRequest,
  INewPasswordRequest,
  IResetPasswordRequest,
  ISignUpRequest,
} from 'src/pages/dashboard/Auth/types/types';
import { AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router';

//--
import { setSession } from 'src/auth/utils';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import { PATH_AUTH } from 'src/routes/paths';
import * as Sentry from '@sentry/react';
import axios from 'src/utils/axios';
// import flagsmith from 'flagsmith';
import mixpanel from 'mixpanel-browser';
import { getUserTagsRequest } from 'src/store/automations/slices/automationsSlice';
import { getClosetListSuccess } from 'src/store/automations/slices/myClosetSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BackgroundActionsEnum } from 'src/utils/ExtensionMessageChannel';

function* authInitializeSaga() {
  try {
    const {
      accessToken,
      refreshToken,
      loggedIn,
      email,
      fullName,
      proStatus,
      blockState,
      userId,
      referrerCode,
    } = isLoggedIn();

    if (loggedIn) {
      setSession(
        accessToken,
        refreshToken,
        email,
        fullName,
        proStatus,
        blockState,
        userId,
        referrerCode
      );

      //--Sentry Initialize
      Sentry.setUser({ email: email, id: userId });
      //--Flagsmith Initialize
      // flagsmith.identify(email);
      TagManager.dataLayer({
        dataLayer: {
          customerUserId: userId,
          customerEmail: email,
          proStatus: proStatus,
          version: packageJson.version,
        },
      });

      //--mixpanel identify
      mixpanel.identify(userId);
      mixpanel.people.set({
        $email: email,
      });

      yield put(getUserTagsRequest({ userId }));
      yield put(authInitializeResult(true));
    } else {
      yield put(authInitializeResult(false));
    }
  } catch (error) {
    yield put(authInitializeResult(false));
  }
}

function* authLoginSaga(action: PayloadAction<ILoginRequest>) {
  try {
    yield put(setLoginData(action.payload.loginData));

    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(crosslistEndpoints.auth.GET_AUTH_LOGIN(), action.payload.loginData)
    );

    setSession(
      response.data.accessToken,
      response.data.refreshToken,
      response.data.email,
      response.data.fullName,
      response.data.proStatus,
      response.data.blockState,
      response.data.id,
      response.data.referrerCode
    );
    setHeader(response.data.accessToken);

    // flagsmith.identify(response.data.email);
    mixpanel.identify(response.data.id);
    mixpanel.people.set({
      $email: response.data.email,
    });
    yield put(getUserTagsRequest({ userId: response.data.id }));
    yield put(
      authLoginSuccess({
        blockState: response.data.blockState,
        userId: response.data.id,
        referrerCode: response.data.referrerCode,
      })
    );

    if (action.payload.reset) {
      action.payload.reset();
    }

    yield put(authLoginFail(''));

    // Close dialog after login
    if (action.payload.isFromVerifyDialog) {
      yield put(setIsUserNotVerifiedDialogOpen(false));
    }
  } catch (error) {
    if (action.payload.setError) {
      action.payload.setError('afterSubmit', {
        message: error.message,
      });
    }
    yield put(authLoginFail(error.message));
    if (error.message.toLowerCase().includes('unverified')) {
      yield put(setIsUserNotVerifiedDialogOpen(true));
    }
  }
}

function* authSignUpSaga(action: PayloadAction<ISignUpRequest>) {
  const signUpData = { ...action.payload.signUpData, userAgent: navigator.userAgent };

  signUpData.timeZone = { name: moment.tz.guess() };
  try {
    // Use fetch instead of axios to avoid CORS issues related to credentials: 'omit'
    const geoDataResponse: Response = yield call(() => fetch('https://ifconfig.primelister.com/'));
    const geoData: {} = yield geoDataResponse.json();
    signUpData.geoData = JSON.stringify(geoData);
    const response: AxiosResponse<IAuthState['signUpResponse']> = yield call(() =>
      axiosInstance.post(crosslistEndpoints.auth.GET_SIGN_UP(), signUpData)
    );
    yield put(setSignUpResponse(response.data));
    const loginData = {
      email: signUpData.email,
      password: signUpData.password,
    };
    yield put(setLoginData(loginData));
    setHeader(response.data!.accessToken);
    action.payload.reset();
    if (signUpData.appInstalled) {
      const loginData = {
        email: signUpData.email,
        password: signUpData.password,
      };
      yield put(
        authLoginRequest({
          loginData,
        })
      );
    } else {
      yield put(authSignUpSuccess());
    }
    yield put(authSignUpFail(''));
  } catch (error) {
    action.payload.setError('afterSubmit', {
      message: error.message,
    });
    yield put(authSignUpFail(error.message));
  }
}

function* authLogoutSaga(action: PayloadAction<{ navigate: NavigateFunction }>) {
  yield setSession(null, null, null, null, null, null, null, null);
  cookie.remove('accessToken');
  cookie.remove('refreshToken');
  cookie.remove('userData');
  yield put(authInitializeResult(false));
  delete axios.defaults.headers.common.Authorization;
  action.payload.navigate(PATH_AUTH.login);
  // flagsmith.logout();
  mixpanel.reset();
  yield put(getClosetListSuccess([]));
}

function* authResetPasswordSaga(action: PayloadAction<IResetPasswordRequest>) {
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.auth.GET_RESET_PASSWORD(), action.payload.payload)
    );
    yield put(authResetPasswordSuccess());
    yield put(setIsResetRequestDialogOpen(true));
    yield put(authResetPasswordFail(''));
    yield put(setEmailfromResetPage(action.payload.payload.email));
    action.payload.navigate('/reset-password?email=' + action.payload.payload.email);
  } catch (error) {
    yield put(authResetPasswordFail(error.message));
  }
}

function* authNewPasswordSaga(action: PayloadAction<INewPasswordRequest>) {
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.auth.GET_NEW_PASSWORD(), action.payload.payload)
    );
    action.payload.navigate('/login');
    yield put(authNewPasswordSuccess());
    yield put(setIsResetSuccessfulDialogOpen(true));
  } catch (error) {
    yield put(authNewPasswordFail(error.message));
  }
}

function* getAppVersionsSaga() {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(crosslistEndpoints.auth.GET_APP_VERSIONS())
    );
    yield put(getAppVersionsSuccess({ ...response.data }));
  } catch (error) {
    yield put(getAppVersionsFail(error.message));
  }
}

function* authSendVerificationSaga(
  action: PayloadAction<{
    email: string;
  }>
) {
  try {
    yield call(() =>
      axiosInstance.post(crosslistEndpoints.auth.GET_SEND_VERIFICATION(action.payload.email))
    );
    yield put(sendVerificationSuccess());
    toast.success('Verification email sent!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  } catch (error) {
    yield put(sendVerificationFail(error.message));
  }
}

function* getExtensionVersionSaga(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(() =>
      window.extension.sendCommand(BackgroundActionsEnum.GET_EXTENSION_VERSION)
    );
    yield put(getExtensionVersionSuccess(response.data.data));
  } catch (error) {
    yield put(getExtensionVersionFail(error.message));
  }
}

export function* authModuleSaga() {
  yield takeLatest(authResetPasswordRequest.type, authResetPasswordSaga);
  yield takeLatest(authNewPasswordRequest.type, authNewPasswordSaga);
  yield takeLatest(authLoginRequest.type, authLoginSaga);
  yield takeLatest(authSignUpRequest.type, authSignUpSaga);
  yield takeLatest(authInitialize.type, authInitializeSaga);
  yield takeLatest(authLogout.type, authLogoutSaga);
  yield takeLatest(sendVerificationRequest.type, authSendVerificationSaga);
  yield takeLatest(getAppVersionsRequest.type, getAppVersionsSaga);
  yield takeLatest(getExtensionVersionRequest.type, getExtensionVersionSaga);
}
