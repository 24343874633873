import { Alert, Box, Button, IconButton } from '@mui/material';
import { useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';

interface AdminLoginBannerProps {
  email: string;
  name: string;
}

const AdminLoginBanner = ({ email, name }: AdminLoginBannerProps) => {
  const isMobile = useResponsive('down', 'sm');
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;
  return (
    <Box
      sx={{
        bottom: isMobile ? 54 : 0,
        position: 'fixed',
        width: '100%',
        zIndex: 99,
        opacity: 0.8,
        color: 'white',
        pointerEvents: 'none',
      }}
    >
      <Alert
        severity={'error'}
        variant="filled"
        sx={{
          borderRadius: '0px',
          color: 'white',
          textWrap: 'wrap',
          textAlign: 'center',
          width: '100%',
          p: 0,
          px: 1,
          '& .MuiAlert-message': {
            width: '100%',
          },
          '& .MuiButton-root': {
            pointerEvents: 'auto',
          },
        }}
      >
        Please be careful, you are in the customer account.
        <br />
        <span
          style={{
            pointerEvents: 'auto',
          }}
        >
          {name} - {email}
        </span>
        <br />
        <Button variant="text" color="primary" onClick={() => setIsVisible(false)}>
          Dismiss
        </Button>
      </Alert>
    </Box>
  );
};

export default AdminLoginBanner;
