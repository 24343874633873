import { useEffect, useState } from 'react';
import useResponsive from './useResponsive';
import { useMediaQuery } from '@mui/material';

export const useScrollDisplay = () => {
  const [scrollDisplay, setScrollDisplay] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');
  const navbarMatches = useMediaQuery('(min-width:1349px)');

  useEffect(() => {
    const scrollLimit = () => {
      if (navbarMatches) {
        return 121;
      }
      if (matches) {
        return 108;
      }
      return 115;
    };

    const handleScroll = () => {
      if (window.scrollY <= scrollLimit()) {
        setScrollDisplay(false);
      } else {
        setScrollDisplay(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.scrollY]);

  return {
    scrollDisplay,
    navbarMatches,
    matches,
  };
};
