import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDateOptionsForStatistics } from 'src/pages/automations/StatisticsPage/helpers/getDateOptionsForStatistics';

import { IGetTaskCountsRequest } from 'src/pages/automations/StatisticsPage/utils/types';

export interface ITaskCountsObject {
  [key: string]: number | null;
  delete: number | null;
  delist: number | null;
  import: number | null;
  relist: number | null;
  crosspost: number | null;
  duplicate: number | null;
  'return-share': number | null;
  'return-follow': number | null;
  'auto-relist': number | null;
  'auto-party-share': number | null;
  'auto-closet-share': number | null;
  'auto-follow-likers': number | null;
  'auto-like-suggested': number | null;
  'auto-community-share': number | null;
  'auto-follow-suggested': number | null;
  'auto-refresh-listings': number | null;
  'auto-message-to-likers': number | null;
  'auto-follow-new-closets': number | null;
  'auto-send-offer-to-likers': number | null;
  'ebay-auto-send-offer-to-likers': number | null;
  'ebay-auto-relist': number | null;
}

export interface IDateObject {
  buttonText: string;
  dateOptions: {
    startDate: string;
    endDate: string;
  };
  urlParam: string;
}

export interface IEbayStatisticsState {
  initialLoading: boolean;
  loading: boolean;
  error: string;
  selectedDateOption: string;
  taskCountsObject: ITaskCountsObject;
  dateOptionsForStatistics: IDateObject;
}
export type IRequestType = 'new' | 'more';

const { today } = getDateOptionsForStatistics();

const initialState = {
  initialLoading: false,
  loading: false,
  error: '',
  selectedDateOption: '',
  dateOptionsForStatistics: {
    buttonText: 'Today',
    dateOptions: {
      startDate: today,
      endDate: today,
    },
    urlParam: 'today',
  } as IDateObject,
  taskCountsObject: {
    delete: 0,
    delist: 0,
    import: 0,
    relist: 0,
    crosspost: 0,
    duplicate: 0,
    'return-share': 0,
    'return-follow': 0,
    'auto-relist': 0,
    'auto-party-share': 0,
    'auto-closet-share': 0,
    'auto-follow-likers': 0,
    'auto-like-suggested': 0,
    'auto-community-share': 0,
    'auto-follow-suggested': 0,
    'auto-refresh-listings': 0,
    'auto-message-to-likers': 0,
    'auto-follow-new-closets': 0,
    'auto-send-offer-to-likers': 0,
    'ebay-auto-send-offer-to-likers': 0,
    'ebay-auto-relist': 0,
  } as ITaskCountsObject,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },

    setSelectedDateOption: (state, action: PayloadAction<string>) => {
      state.selectedDateOption = action.payload;
    },
    setTaskCountsOject: (state, action: PayloadAction<ITaskCountsObject>) => {
      state.taskCountsObject = action.payload;
    },
    setDateOptionsForStatistics: (state, action: PayloadAction<IDateObject>) => {
      state.dateOptionsForStatistics = action.payload;
    },

    //--
    getAutomationCountsRequest: (state, action: PayloadAction<IGetTaskCountsRequest>) => {
      state.loading = true;
      state.error = '';
    },
    getAutomationCountsSuccess: (state, action: PayloadAction<ITaskCountsObject>) => {
      state.loading = false;
      state.error = '';
      state.taskCountsObject = action.payload;
    },
    getAutomationCountsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetStatisticsState: () => initialState,
  },
});

export const {
  resetStatisticsState,
  setInitialLoading,
  setSelectedDateOption,
  setTaskCountsOject,
  setDateOptionsForStatistics,
  //--
  getAutomationCountsRequest,
  getAutomationCountsSuccess,
  getAutomationCountsFail,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
