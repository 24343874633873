import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { setStripeReferrerUrl } from 'src/store/dashboard/slices/pricingSlice';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

type PlatformConfig = {
  name: string;
  shopType: string;
  redirectPath: string;
  subscriptionPath: string;
};

const getPlatformConfig = (platform: AutomationPlatform): PlatformConfig => {
  switch (platform) {
    case AutomationPlatform.EBAY:
      return {
        name: 'eBay',
        shopType: 'Shop',
        redirectPath: PATH_AUTOMATIONS.ebayMyShops,
        subscriptionPath: PATH_DASHBOARD.subscription.ebayAutomation,
      };
    case AutomationPlatform.POSHMARK:
      return {
        name: 'Poshmark',
        shopType: 'Closet',
        redirectPath: PATH_AUTOMATIONS.myCloset,
        subscriptionPath: PATH_DASHBOARD.subscription.poshmarkAutomation,
      };
    default:
      return {
        name: 'Marketplace',
        shopType: 'Account',
        redirectPath: PATH_AUTOMATIONS.myCloset,
        subscriptionPath: PATH_DASHBOARD.subscription.poshmarkAutomation,
      };
  }
};

export default function ConnectionOrSubscriptionMissingDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const {
    automations: { isConnectionErrorDialogOpen },
  } = states;
  const {
    myShops: { hasConnection, hasCloset },
  } = states;

  const platformConfig = getPlatformConfig(platform);

  const handleClose = () => dispatch(platformActions.setIsConnectionErrorDialogOpen(false));

  const url = window.location.href;
  const handleConnection = () => {
    dispatch(setStripeReferrerUrl(url));
    if (!hasCloset || !hasConnection) {
      navigate(platformConfig.redirectPath);
    } else {
      navigate(platformConfig.subscriptionPath);
    }
    handleClose();
  };

  const connectionDialogContent = {
    title: `${hasCloset && !hasConnection ? 'Re-connect' : 'Connect'} Your ${platformConfig.name} ${
      platformConfig.shopType
    }: Action Required`,
    message: `To unlock powerful automations of our tool, please ${
      hasCloset && !hasConnection ? 're-connect' : 'connect'
    } your ${
      platformConfig.name
    } ${platformConfig.shopType.toLowerCase()}. This will enable seamless integration with our software and empower you to automate various tasks.`,
    buttonText: `${hasCloset && !hasConnection ? 'Re-connect' : 'Connect'} My ${
      platformConfig.shopType
    }`,
  };

  const subscriptionDialogContent = {
    title: `Subscribe to ${platformConfig.name} Automation Tool: Action Required`,
    message: `You don't have the subscription required to enable this automation. Subscribe to ${platformConfig.name} Automation Tool and unlock its powerful automations with full access.`,
    buttonText: 'Subscribe',
  };

  const dynamicDialogContent =
    !hasCloset || !hasConnection ? connectionDialogContent : subscriptionDialogContent;

  const { title, message, buttonText } = dynamicDialogContent;

  return (
    <Dialog fullWidth maxWidth="sm" open={isConnectionErrorDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{message}</DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConnection}
          sx={{ textTransform: 'none !important' }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
