import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { IRootState } from 'src/store';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

import { LoadingButton } from '@mui/lab';
import { Automation } from '@beta.limited/primelister';
import { useEffect, useState } from 'react';
import { IDataForUpdateAutomationSettings } from '../../types/types';
import { formatTimeZone, getHourRange } from '../../helpers/hourHelpers';

export default function RelistHourDialog() {
  const dispatch = useDispatch();
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  // Store states from useAutomationPlatform
  const {
    isRelistHourDialogOpen,
    selectedRelistHourData,
    loading,
    relistHourDialogError,
    activeClosetAutomationPlatform,
    selectedAutomationOption,
  } = states.automations;
  const { activeClosetCredentialsId } = states.myShops;
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);

  const [hourEnabled, setHourEnabled] = useState(selectedRelistHourData?.enabled);

  // Dialog Closer
  const handleClose = () => {
    dispatch(platformActions.setRelistHourDialogError(''));
    dispatch(
      platformActions.setIsRelistHourDialogOpen({
        isOpen: false,
        relistingHourDialogData: selectedRelistHourData,
      })
    );
  };

  // Request handler
  const handleSaveChanges = () => {
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_RELIST]: {
          config: {
            taskHours: [
              {
                timeOfDay: selectedRelistHourData?.timeOfDay,
                enabled: !hourEnabled,
              },
            ],
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
      isFromRelistDialog: true,
    };
    dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
  };

  useEffect(() => {
    setHourEnabled(selectedRelistHourData?.enabled);
  }, [selectedRelistHourData]);

  const hourRange = getHourRange(selectedRelistHourData?.timeOfDay || '1 AM');
  const hasError = !!relistHourDialogError;

  const textStyle = {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#212B36',
  };

  const getActionText = () => {
    switch (platform) {
      case AutomationPlatform.EBAY:
        return {
          dialogTitle: hourEnabled ? 'Disable Re-creating Hour' : 'Enable Re-creating Hour',
          contentText: (hour: string) =>
            `Are you sure you want to ${
              hourEnabled ? 'disable' : 'enable'
            } re-creating for ${hour}?`,
          buttonText: hourEnabled ? 'Disable' : 'Enable',
        };
      default:
        return {
          dialogTitle: hourEnabled ? 'Disable Re-listing Hour' : 'Enable Re-listing Hour',
          contentText: (hour: string) =>
            `Are you sure you want to ${
              hourEnabled ? 'disable' : 'enable'
            } re-listing for ${hour}?`,
          buttonText: hourEnabled ? 'Disable' : 'Enable',
        };
    }
  };

  const renderEnableHourContent = () => {
    const action = hourEnabled ? 'disable' : 'enable';
    const status = hourEnabled ? 'disabled' : 'enabled';
    const timeZoneFormatted = formatTimeZone(userAccountDetail.timeZone.gmt);

    return (
      <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
        <Typography sx={textStyle}>{getActionText().contentText(hourRange)}</Typography>
      </Stack>
    );
  };

  const renderAPIAlert = () => (
    <Alert
      severity="error"
      variant="standard"
      sx={{ mt: '24px !important', width: '100%', color: '#7A4100' }}
    >
      {relistHourDialogError}
    </Alert>
  );

  const CancelButton = () => (
    <Button variant={hourEnabled ? 'outlined' : 'text'} color="inherit" onClick={handleClose}>
      Cancel
    </Button>
  );

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '448px',
            maxHeight: !hasError ? '198.5px' : 'auto',
          },
        },
      }}
      open={isRelistHourDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle sx={{ pb: '24px' }}>{getActionText().dialogTitle}</DialogTitle>
      <DialogContent
        sx={{
          overflow: 'hidden',
        }}
      >
        {renderEnableHourContent()}
        {hasError && renderAPIAlert()}
      </DialogContent>
      <DialogActions>
        {!hourEnabled && <CancelButton />}
        <LoadingButton
          disabled={hasError}
          loading={loading}
          variant="contained"
          color={hourEnabled ? 'error' : 'primary'}
          onClick={handleSaveChanges}
          sx={{
            transition: 'none !important',
          }}
        >
          {getActionText().buttonText}
        </LoadingButton>
        {hourEnabled && <CancelButton />}
      </DialogActions>
    </Dialog>
  );
}
