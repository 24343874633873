// @mui
import { Stack, Typography, Avatar, useMediaQuery, Chip, useTheme, Box } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { IActivityData } from '../utils/types';
import { getActivityTypeProps } from '../helpers/getActivityTypeProps';
import { IRootState } from 'src/store';
import { useSelector } from 'react-redux';
import { getDateWithTimeZone } from '../../../../utils/getDateWithTimezone';
import { useAutomationPlatform } from 'src/hooks/useAutomationPlatform';

export default function ActivityCard({ activity }: { activity: IActivityData }) {
  const isDesktop = useResponsive('up', 'md');
  const matches = useMediaQuery('(min-width:835px)');
  const mobileMatches = useMediaQuery('(min-width:340px)');
  const { platform } = useAutomationPlatform();
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { activityIcon, activityDisplayName } = getActivityTypeProps(
    activity.automation,
    true,
    platform
  );

  const cardImage = activity.image ?? 'https://r2.primelister.com/image_thumbnail.svg';
  const activityTime = getDateWithTimeZone(
    activity.createdAt,
    userAccountDetail?.timeZone?.name,
    'DD MMM YYYY, hh:mm A'
  );

  const theme = useTheme();
  return (
    <Stack
      alignItems={isDesktop ? 'center' : 'flex-start'}
      sx={{
        borderRadius: '8px',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: (theme) => theme.customShadows.z20,
        },
        cursor: 'default',
        minHeight: '110px',
        width: matches ? '800px' : 'auto',
        minWidth: '260px',
        maxWidth: '800px',
        mb: '16px',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          width: 1,
          bgcolor: '#F4F6F8',
          alignItems: 'center',
          minHeight: '54px',
          pt: '24px',
          pb: '8px',
          position: 'relative',
          paddingLeft: '112px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          paddingRight: '15px',
        }}
      >
        {' '}
        <Avatar
          sx={{
            position: 'absolute',
            top: '16px',
            left: '16px',
            minWidth: '80px',
            maxWidth: '80px',
            minHeight: '80px',
            maxHeight: '80px',
            zIndex: 1,
            borderRadius: '8px !important',
          }}
          src={cardImage}
        />
        <Typography variant="subtitle2">{activity.name}</Typography>
      </Stack>
      <Stack
        direction={matches ? 'row' : 'column'}
        sx={{
          width: 1,
          alignItems: matches ? 'center' : 'flex-start',
          justifyContent: matches ? 'space-between' : 'space-evenly',
          minHeight: matches ? '56px' : '136px',
          position: 'relative',
          paddingLeft: '112px',
          paddingRight: matches ? '27px' : '0px',
        }}
      >
        <Stack direction={'row'} spacing={matches ? '12px' : '0px'} sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              minWidth: isDesktop ? '250px' : '0',
            }}
          >
            <Chip
              variant={'soft'}
              icon={
                <Avatar
                  sx={{ width: '24px', height: '24px' }}
                  src={`/icons/automationIcons/${activityIcon}.svg`}
                />
              }
              label={activityDisplayName}
              color="primary"
              sx={{
                width: 'min-content',
                height: '32px',
                fontWeight: 400,
                fontSize: '13px !important',
                color: '#2065D1',
                borderRadius: '50px',
                backgroundColor: 'rgba(32, 101, 209, 0.16)',
                [theme.breakpoints.down(381)]: {
                  minHeight: '32px',
                  height: '100%',
                  width: '100%',
                  '& .MuiChip-label': {
                    whiteSpace: 'break-spaces',
                  },
                },
              }}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={'12px'}
          sx={{
            minWidth: '200px',
            alignItems: 'center',
            color: 'rgba(99, 115, 129, 1)',
          }}
        >
          <Iconify icon="mdi:store" width={'24px'} height={'24px'} />
          <Typography
            variant="body2"
            sx={{
              [theme.breakpoints.down(381)]: {
                ml: '8px !important',
              },
            }}
          >
            {activity.shop}
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center', color: 'rgba(99, 115, 129, 1)' }}>
          <AccessTimeFilledIcon />
          <Typography
            variant="body2"
            ml={'12px'}
            sx={{
              fontSize: '14px !important',
              lineHeight: '18px',
              [theme.breakpoints.down(381)]: {
                ml: '8px !important',
              },
            }}
          >
            {activityTime}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
