import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography, useMediaQuery } from '@mui/material';
import AutomationOptionsContainer from './components/AutomationOptionsContainer';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import useResponsive from 'src/hooks/useResponsive';
import AutomationOptionDrawer from 'src/pages/automations/AutomationsPage/components/drawer/AutomationOptionDrawer';
import RelistHourDialog from 'src/pages/automations/AutomationsPage/components/dialogs/RelistHourDialog';
import ExpiredSubscriptionDialog from 'src/pages/automations/MyClosetPage/components/dialogs/ExpiredSubscriptionDialog';
import ConnectionOrSubscriptionMissingDialog from 'src/pages/automations/AutomationsPage/components/dialogs/ConnectionOrSubscriptionMissingDialog';
import DisableAutomationDialog from 'src/pages/automations/AutomationsPage/components/dialogs/DisableAutomationDialog';
import { getAutomationListRequest, setInitialLoading } from 'src/store/automations/ebay/slices/automationsSlice.ebay';

const EbayAutomationsPage = () => {
  const dispatch = useDispatch();
  const { activeClosetAutomationPlatform } = useSelector(
    (state: IRootState) => state.ebayAutomations
  );
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.ebayMyShops
  );
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const titleMatches = useMediaQuery('(max-width:380px)');
  const isMobileWeb = useResponsive('down', 'sm');
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const isDesktop = useResponsive('up', 'sm');

  useEffect(() => {
    dispatch(setInitialLoading(true));
    if (activeClosetCredentialsId) {
      dispatch(
        getAutomationListRequest({
          marketplace: activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        })
      );
    } else {
      dispatch(setInitialLoading(false));
    }
  }, [activeClosetCredentialsId, activeClosetAutomationPlatform]);

  const isShowingPageTitle = (isMobile && titleMatches) || !isMobile;
  return (
    <>
      <Helmet>
        <title> Automations | Primelister</title>
      </Helmet>

      <Container disableGutters maxWidth={'md'} sx={{ maxWidth: '800px !important' }}>
        {isShowingPageTitle && (
          <AutomationsPageTitle
            title="Automations"
            sxProps={{
              mb: !isMobile ? '2.5rem' : '24px',
              ...(isShowingPageTitle && !isDesktop ? { pt: '12px' } : {}),
            }}
          />
        )}

        <AutomationOptionsContainer />
      </Container>
      <DisableAutomationDialog />
      <ConnectionOrSubscriptionMissingDialog />
      <ExpiredSubscriptionDialog />
      <RelistHourDialog />
      <AutomationOptionDrawer />
    </>
  );
};

export default EbayAutomationsPage;
