import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';

export enum Country {
  US = 'us',
  CA = 'ca',
}

export enum ConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Removed = 'removed',
}

// Map country to its display name
export const countryDisplayNames: Record<Country, string> = {
  [Country.US]: 'Poshmark (US)',
  [Country.CA]: 'Poshmark (CA)',
};
export interface IRemoveData {
  id: string;
  name: string;
}
export interface IMyClosetState {
  loading: boolean;
  initialLoading: boolean;
  error: string;
  isConnectDialogOpen: boolean;
  connectionType: 'connect' | 'reconnect';
  isReconnection: boolean;
  closetList: IClosetDetails[];
  hasCloset: boolean;
  hasConnection: boolean;
  hasPoshmarkSubscription: boolean;
  shouldShowConnectFormAlert: boolean;
  selectedCloset: IClosetDetails; //-- This is for the closet page, connection cases
  activeCloset: IClosetDetails; //-- This is for the automation, activity and statistics pages
  activeClosetCredentialsId: string;
  isGetClosetRequestCompleted: boolean;
  connectedShopsCount: number;
  isClosetPopoverOpen: HTMLElement | null;
  isUpgradeSubscriptionDialogOpen: boolean;
  isRemoveClosetDialogOpen: boolean;
  removeClosetData: IRemoveData;
  hasActiveClosetConnection: boolean;
  shouldOpenExpiredSubscriptionDialog: boolean;
  isConnectRemovedClosetDialogOpen: boolean;
}

const initialState = {
  isGetClosetRequestCompleted: false,
  initialLoading: true,
  loading: false,
  error: '',
  isConnectDialogOpen: false,
  connectionType: 'connect',
  isReconnection: false,
  closetList: [] as IClosetDetails[],
  hasCloset: true,
  hasConnection: true,
  hasPoshmarkSubscription: false,
  shouldShowConnectFormAlert: false,
  selectedCloset: {} as IClosetDetails,
  activeCloset: {} as IClosetDetails,
  activeClosetCredentialsId: '',
  connectedShopsCount: 0,
  isClosetPopoverOpen: null,
  isUpgradeSubscriptionDialogOpen: false,
  isRemoveClosetDialogOpen: false,
  removeClosetData: {} as IRemoveData,
  hasActiveClosetConnection: false,
  shouldOpenExpiredSubscriptionDialog: false,
  isConnectRemovedClosetDialogOpen: false,
};

const myClosetSlice = createSlice({
  name: 'myCloset',
  initialState,
  reducers: {
    setInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },
    setIsReconnection: (state, action: PayloadAction<boolean>) => {
      state.isReconnection = action.payload;
    },
    setIsConnectDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isConnectDialogOpen = action.payload;
    },
    setHasPoshmarkSubscription: (state, action: PayloadAction<boolean>) => {
      state.hasPoshmarkSubscription = action.payload;
    },
    setShouldShowConnectFormAlert: (state, action: PayloadAction<boolean>) => {
      state.shouldShowConnectFormAlert = action.payload;
    },
    setSelectedCloset: (state, action: PayloadAction<IClosetDetails>) => {
      state.selectedCloset = action.payload;
    },
    setActiveCloset: (state, action: PayloadAction<IClosetDetails>) => {
      state.activeCloset = action.payload;
      state.hasActiveClosetConnection = action.payload?.status === ConnectionStatus.Connected;
      state.activeClosetCredentialsId = action.payload?._id;
    },

    setIsClosetPopoverMenuOpen: (state, action) => {
      state.isClosetPopoverOpen = action.payload;
    },
    setIsUpgradeSubscriptionDialogOpen: (state, action) => {
      state.isUpgradeSubscriptionDialogOpen = action.payload;
    },
    setIsRemoveClosetDialogOpen: (
      state,
      action: PayloadAction<{ openDialog: boolean; removeClosetData: { name: string; id: string } }>
    ) => {
      state.isRemoveClosetDialogOpen = action.payload.openDialog;
      state.removeClosetData = action.payload.removeClosetData;
    },
    setShouldOpenExpiredSubscriptionDialog: (state, action) => {
      state.shouldOpenExpiredSubscriptionDialog = action.payload;
    },
    setIsConnectRemovedClosetDialogOpen: (state, action) => {
      state.isConnectRemovedClosetDialogOpen = action.payload;
    },
    //--
    getClosetListRequest: (state) => {
      state.loading = true;
      state.error = '';
    },
    getClosetListSuccess: (state, action: PayloadAction<IClosetDetails[]>) => {
      const closetList = action.payload;

      state.loading = false;
      state.initialLoading = false;
      state.error = '';
      state.closetList = closetList;
      state.hasCloset =
        closetList.filter((closet: IClosetDetails) => closet?.status !== ConnectionStatus.Removed)
          .length > 0;
      state.isGetClosetRequestCompleted = true;
      state.connectedShopsCount = closetList.length;
      state.hasConnection = !action.payload.filter(
        (closet) =>
          closet?.status === ConnectionStatus.Disconnected ||
          closet?.status === ConnectionStatus.Removed
      ).length;

      if (!closetList.length) {
        state.activeCloset = {} as IClosetDetails;
      }
    },
    getClosetListFail: (state, action) => {
      state.loading = false;
      state.initialLoading = false;
      state.error = action.payload;
      state.isGetClosetRequestCompleted = true;
    },
    //--
    connectClosetRequest: (
      state,
      action: PayloadAction<{ country: string; userHandle: string; password: string }>
    ) => {
      state.loading = true;
      state.error = '';
    },
    connectClosetSuccess: (state) => {
      state.loading = false;
      state.error = '';
    },
    connectClosetFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    //--
    removeClosetRequest: (state, action: PayloadAction<IRemoveData>) => {
      state.loading = true;
      state.error = '';
    },
    removeClosetSuccess: (state) => {
      state.loading = false;
      state.error = '';
      state.isRemoveClosetDialogOpen = false;
      state.removeClosetData = {} as IRemoveData;
    },
    removeClosetFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetMyClosetState: (state) => initialState,
  },
});

export const {
  resetMyClosetState,
  setInitialLoading,
  setIsConnectDialogOpen,
  setIsReconnection,
  setHasPoshmarkSubscription,
  setShouldShowConnectFormAlert,
  setSelectedCloset,
  setActiveCloset,
  setIsClosetPopoverMenuOpen,
  setIsUpgradeSubscriptionDialogOpen,
  setIsRemoveClosetDialogOpen,
  setShouldOpenExpiredSubscriptionDialog,
  setIsConnectRemovedClosetDialogOpen,
  //--
  getClosetListRequest,
  getClosetListSuccess,
  getClosetListFail,
  //--
  connectClosetRequest,
  connectClosetSuccess,
  connectClosetFail,
  //--
  removeClosetRequest,
  removeClosetSuccess,
  removeClosetFail,
} = myClosetSlice.actions;

export default myClosetSlice.reducer;
