import {
  Alert,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';

interface SharingLimitsValues {
  dayTaskLimit: number;
  eveningTaskLimit: number;
}

const SharingLimits = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
    automationsSettings,
    loading,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const onChangeTimer = React.useRef<NodeJS.Timeout | null>(null);
  const onChangeAlertTimer = React.useRef<NodeJS.Timeout | null>(null);

  const [isLimitsToggled, setIsLimitsToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const validationSchema = Yup.object().shape({
    dayTaskLimit: Yup.number()
      .required('Please enter a number that is between 1 and 2000.')
      .min(1, 'Please enter a number that is between 1 and 2000.')
      .max(2000, 'Please enter a number that is between 1 and 2000.')
      .integer('Please enter a whole number.'),
    eveningTaskLimit: Yup.number()
      .required('Please enter a number that is between 1 and 2000.')
      .min(1, 'Please enter a number that is between 1 and 2000.')
      .max(2000, 'Please enter a number that is between 1 and 2000.')
      .integer('Please enter a whole number.'),
  });

  const handleSharingLimitsFormik = useFormik<SharingLimitsValues>({
    enableReinitialize: true,
    initialValues: {
      dayTaskLimit: automationsSettings[Automation.AUTO_PARTY_SHARE]?.config?.dayTaskLimit ?? 250,
      eveningTaskLimit:
        automationsSettings[Automation.AUTO_PARTY_SHARE]?.config?.eveningTaskLimit ?? 1000,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof SharingLimitsValues
  ) => {
    if (hasActiveClosetConnection) {
      if (onChangeTimer.current) clearTimeout(onChangeTimer.current);
      if (onChangeAlertTimer.current) clearTimeout(onChangeAlertTimer.current);

      handleSharingLimitsFormik.handleChange(event);

      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);
      const isValidInput = inputValue >= 1 && inputValue <= 2000 && Number.isInteger(inputValue);

      if (isValidInput) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                [field]: parseInt(event.target.value),
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !== handleSharingLimitsFormik.initialValues[field];

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  useEffect(() => {
    return () => {
      if (onChangeTimer.current) clearTimeout(onChangeTimer.current);
    };
  }, []);

  useEffect(() => {
    handleSharingLimitsFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const renderAlert = (fieldName: keyof SharingLimitsValues) => {
    if (renderError && handleSharingLimitsFormik.errors[fieldName]) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '4px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {handleSharingLimitsFormik.errors[fieldName]}
        </Alert>
      );
    }
  };

  const renderLimitInputField = (fieldName: keyof SharingLimitsValues, label: string) => (
    <Stack direction="row" alignItems="center" spacing={1} mt={2}>
      <Typography variant="body2">Share</Typography>
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        arrow
        title="ℹ️ Please enter a whole number between 1-2000."
        placement="top"
      >
        <TextField
          disabled={loading}
          type="number"
          name={fieldName}
          sx={{
            width: '70px',
            height: '40px',
            maxWidth: '70px',
            '& .MuiOutlinedInput-root': {
              maxHeight: '40px',
              height: '40px',
              maxWidth: '70px',
              width: '70px',
              bgcolor:
                renderError && handleSharingLimitsFormik.errors[fieldName] ? '#FFF2EF' : '#eeeff1',
              color:
                renderError && handleSharingLimitsFormik.errors[fieldName] ? '#B71C19' : '#000000',
            },
          }}
          value={handleSharingLimitsFormik.values[fieldName]}
          onChange={(e) => handleLimitChange(e, fieldName)}
          inputProps={{ min: 1, max: 2000 }}
        />
      </Tooltip>
      <Typography
        variant="body2"
        sx={{
          textWrap: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Stack>
  );

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Party Share Limits
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Configure how many shares will be made from your available listings in Poshmark Parties."
            placement="top"
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsLimitsToggled(!isLimitsToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isLimitsToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>
      {isLimitsToggled && (
        <>
          {renderLimitInputField('dayTaskLimit', 'items in each themed day party')}
          {renderAlert('dayTaskLimit')}
          {renderLimitInputField('eveningTaskLimit', 'items in evening party')}
          {renderAlert('eveningTaskLimit')}
        </>
      )}
    </Stack>
  );
};

export default SharingLimits;
