import { Marketplace } from '@beta.limited/primelister';
import { IClosetDetails } from 'src/pages/automations/MyClosetPage/utils/types';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import { PATH_AUTOMATIONS } from 'src/routes/paths';

export const sortClosetListAccordingToStatus = (closetList: IClosetDetails[]): IClosetDetails[] => {
  const sortedClosetList = function sortObjects() {
    return closetList.sort((a: IClosetDetails, b: IClosetDetails) => {
      if (a.status !== ConnectionStatus.Removed && b.status === ConnectionStatus.Removed) {
        return 1;
      } else if (a.status === ConnectionStatus.Removed && b.status !== ConnectionStatus.Removed) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  return sortedClosetList();
};

export const getClosetCountry = (country: string): Marketplace => {
  const isEbayPath = window.location.pathname.includes(PATH_AUTOMATIONS.ebayRoot);

  if (isEbayPath) {
    const ebayCountryMapping: Record<string, string> = {
      us: Marketplace.EBAY_US,
      ca: Marketplace.EBAY_CA,
      au: Marketplace.EBAY_AU,
    };
    return ebayCountryMapping[country.toLowerCase()] as Marketplace;
  }

  const poshmarkCountryMapping: Record<string, string> = {
    us: Marketplace.POSHMARK_US,
    ca: Marketplace.POSHMARK_CA,
  };

  return poshmarkCountryMapping[country.toLowerCase()] as Marketplace;
};
