import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
import { Automation } from '@beta.limited/primelister';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

export default function DisableAutomationDialog() {
  const dispatch = useDispatch();
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const { 
    isDisableConfirmationDialogOpen, 
    dataForUpdating, 
    loading,
    activeClosetAutomationPlatform,
    selectedAutomationOption 
  } = states.automations;

  const { 
    activeClosetCredentialsId, 
    activeCloset 
  } = states.myShops;

  const handleClose = () =>
    dispatch(platformActions.setIsDisableConfirmationDialogOpen({ isOpen: false, dataForUpdating }));

  const dialogContent = `${dataForUpdating?.displayName} automation will be disabled. Are you sure you want to disable it?`;

  const handleDisable = () => {
    const mixpanelDisableData = {
      tool: `${platform}Automation`,
      source: 'web',
      automation:
        (dataForUpdating?.workerData?.automation as Automation) ||
        (selectedAutomationOption?.name as Automation),
      enabled: false,
      credentialsId: activeClosetCredentialsId,
      marketplace: activeClosetAutomationPlatform,
      country: activeCloset?.country,
      poshmarkUserName: platform === AutomationPlatform.POSHMARK ? activeCloset?.marketplaceUserName : undefined,
      ebayUserName: platform === AutomationPlatform.EBAY ? activeCloset?.marketplaceUserName : undefined,
    };

    dispatch(
      platformActions.createAutomationWorkerRequest({
        automation: dataForUpdating?.workerData?.automation as Automation,
        marketplace: activeClosetAutomationPlatform,
        activeClosetCredentialsId,
        status: false,
        toastMessage: dataForUpdating?.toastMessage,
        automationsSettings: dataForUpdating?.workerData?.automationsSettings,
      })
    );
    
    handleClose();
    mixpanelTrack(MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED, mixpanelDisableData);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={isDisableConfirmationDialogOpen} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>Disable Automation</DialogTitle>
      <DialogContent sx={{ typography: 'body2' }}>{dialogContent}</DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} variant="contained" color="error" onClick={handleDisable}>
          Disable
        </LoadingButton>
        <Button variant="outlined" color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
