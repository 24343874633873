import { Stack, TextField, InputAdornment, FormControl, Tooltip, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import { useFormikContext } from 'formik';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation, EbayAutoSendOfferToLikersRule } from '@beta.limited/primelister';
import { useAutomationPlatform } from 'src/hooks/useAutomationPlatform';

interface ShippingOfferWithoutSelectProps {
  ruleIndex: number;
}

const ShippingOfferWithoutSelect: React.FC<ShippingOfferWithoutSelectProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const [renderError, setRenderError] = useState(false);
  const [localError, setLocalError] = useState('');

  const { states } = useAutomationPlatform();

  const { selectedAutomationOption, activeClosetAutomationPlatform, loading } = states.automations;
  const { activeClosetCredentialsId, hasActiveClosetConnection } = states.myShops;

  const { values, setFieldValue } = useFormikContext<{
    rules: EbayAutoSendOfferToLikersRule[];
  }>();

  useEffect(() => {
    if (renderError) {
      const numericValue = Number(values.rules[ruleIndex].offer.percentage);
      if (numericValue < 5 || numericValue > 100) {
        setLocalError('Please enter a number that is between 5 and 100.');
      } else {
        setLocalError('');
      }
    }
  }, [values.rules[ruleIndex].offer.percentage, renderError, ruleIndex]);

  const handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasActiveClosetConnection) {
      dispatch(setIsConnectionErrorDialogOpen(true));
      return;
    }
    const newValue = event.target.value;
    const numericValue = Number(newValue);

    setFieldValue(`rules.${ruleIndex}.offer.percentage`, newValue);
    setRenderError(true);

    if (numericValue >= 5 && numericValue <= 100) {
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

      const updatedRules = [...values.rules];
      updatedRules[ruleIndex] = {
        ...updatedRules[ruleIndex],
        offer: {
          ...updatedRules[ruleIndex].offer,
          percentage: numericValue,
        },
      };

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
      setLocalError('');
    } else {
      setFieldValue(`rules.${ruleIndex}.offer.percentage`, newValue);
      setLocalError('Please enter a number that is between 5 and 100.');
    }
  };

  return (
    <Stack>
      <Stack direction={'row'} spacing={2}>
        <FormControl
          sx={{
            maxWidth: '50%',
          }}
        >
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a number between 5-100."
            placement="top"
          >
            <TextField
              id={`offer-input-${ruleIndex}`}
              variant="outlined"
              type="number"
              disabled={loading}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={values.rules[ruleIndex].offer.percentage || ''}
              onChange={handleOfferChange}
              label="Discount"
              error={!!localError}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  bgcolor: localError ? '#FFF2EF' : 'white',
                },
                width: '100%',
              }}
            />
          </Tooltip>
        </FormControl>
      </Stack>

      {localError && (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: 'white',
            overflow: 'hidden',
            p: 0,
            mt: '2px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {localError}
        </Alert>
      )}
    </Stack>
  );
};

export default ShippingOfferWithoutSelect;
