// @mui
import {
  Divider,
  Typography,
  Stack,
  Box,
  BoxProps,
  TextField,
  InputAdornment,
  useTheme,
  Button,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import SellIcon from '@mui/icons-material/Sell';
import CloseIcon from '@mui/icons-material/Close';

import {
  checkCouponCodeValidationRequest,
  newSubscriptionRequest,
  setIsCouponValid,
  setIsThereAnyCouponError,
} from 'src/store/dashboard/slices/pricingSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getPriceCardAmounts } from '../../utils/helpers';
import {
  IBillingSubscription,
  SubscriptionStatus,
  getBillingSubscriptionsRequest,
  setCoupon,
} from 'src/store/dashboard/slices/accountSlice';
import { PATH_DASHBOARD } from 'src/routes/paths';
import {
  getCrosslistAnnualPlans,
  getCrosslistMonthlyPlans,
  getPoshmarkPlans,
} from '../../utils/constants';

// components

// ----------------------------------------------------------------------

export default function PaymentSummaryPage({ sx, ...other }: BoxProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:450px)');
  const navigate = useNavigate();
  const {
    selectedSubscription,
    loading,
    couponDetails,
    isThereAnyCouponError,
    isCouponValid,
    error,
    checkoutLoading,
    stripeReferrerUrl,
    sliderValue,
  } = useSelector((state: IRootState) => state.pricing);
  const { billingSubscriptionList } = useSelector((state: IRootState) => state.account);

  const [isAddCouponClicked, setIsAddCouponClicked] = useState(false);
  const [couponInputValue, setCouponInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const isYearly = selectedSubscription?.planId.includes('annual');
  const couponParam = searchParams.get('coupon');
  const referrerCodeParam = searchParams.get('referrerCode');
  const planIdParam = searchParams.get('planId');
  const closetParam = searchParams.get('closet');
  const controlledSliderValue = Number(searchParams.get('closet')) || sliderValue;

  const { discountRate, discountAmount, totalPrice } = getPriceCardAmounts(
    couponDetails,
    selectedSubscription,
    isCouponValid,
    isYearly
  );

  const handleInputChange = (event: any) => {
    dispatch(setIsThereAnyCouponError(false));
    setCouponInputValue(event.target.value);
  };

  const handleApply = () => {
    if (couponInputValue) {
      dispatch(
        checkCouponCodeValidationRequest({
          plan: selectedSubscription?.planId,
          code: couponInputValue,
        })
      );
    }
  };

  const handleSubscribe = () => {
    const { planId } = selectedSubscription;

    const requestData = {
      stripeRequestData: {
        returnUrl: stripeReferrerUrl,
        planId,
        ...(isCouponValid && couponInputValue && { code: couponInputValue }),
        shopCapacity: shouldRenderClosetCapacity ? controlledSliderValue : 1,
      },
      navigate,
    };

    dispatch(newSubscriptionRequest(requestData));
  };

  const handleRemoveCoupon = () => {
    setIsAddCouponClicked(false);
    dispatch(setIsCouponValid(false));
    dispatch(setIsThereAnyCouponError(false));
    setCouponInputValue('');
  };

  const getSubscriptionPlans = () => {
    const controlledSliderValue = Number(searchParams.get('closet')) || sliderValue;
    return [
      ...getCrosslistMonthlyPlans(false),
      ...getCrosslistAnnualPlans(false),
      ...getPoshmarkPlans(false, controlledSliderValue),
    ];
  };

  useEffect(() => {
    const allSubscriptionPlans = getSubscriptionPlans();

    if (planIdParam && billingSubscriptionList.length) {
      couponParam && dispatch(setCoupon(couponParam));
      handleCouponOrRedirect(allSubscriptionPlans, planIdParam);
    }
  }, [billingSubscriptionList]);

  const handleCouponOrRedirect = (
    allSubscriptionPlans: ReturnType<typeof getSubscriptionPlans>,
    planId: string
  ) => {
    const matchedPlan = findPlanById(allSubscriptionPlans, planId);
    const subscriptionWithSameCategory = findSubscriptionWithSameCategory(
      billingSubscriptionList,
      matchedPlan
    );

    if (hasActiveSubscription(subscriptionWithSameCategory)) {
      redirectToDashboard(matchedPlan);
    } else {
      handleCoupon(matchedPlan, subscriptionWithSameCategory);
    }
  };

  const findPlanById = (plans: ReturnType<typeof getSubscriptionPlans>, id: string) =>
    plans.find((plan) => plan.planId === id);

  const findSubscriptionWithSameCategory = (
    subscriptions: IBillingSubscription[],
    plan: ReturnType<typeof findPlanById>
  ) =>
    subscriptions.find((subscription) => subscription.subscriptionName === plan?.subscriptionName);

  const hasActiveSubscription = (subscription: IBillingSubscription | undefined) =>
    Boolean(subscription?.status?.toLowerCase().includes(SubscriptionStatus.ACTIVE));

  const redirectToDashboard = (plan: ReturnType<typeof findPlanById>) => {
    const isCrosslistPlan = Boolean(plan?.subscriptionName.toLowerCase().includes('crosspost'));
    const redirectUrl = isCrosslistPlan
      ? PATH_DASHBOARD.subscription.crosslist
      : PATH_DASHBOARD.subscription.poshmarkAutomation;

    navigate(redirectUrl);
  };

  const handleCoupon = (
    plan: ReturnType<typeof findPlanById>,
    subscription: IBillingSubscription | undefined
  ) => {
    if (isCouponValid) {
      handleValidCoupon(plan, subscription);
    } else {
      handleInvalidCoupon(plan, subscription);
    }
  };

  const handleValidCoupon = (
    matchedPlan: ReturnType<typeof findPlanById>,
    subscription: IBillingSubscription | undefined
  ) => {
    if (couponParam) {
      applyCoupon(couponParam);
    } else if (referrerCodeParam && !subscription?.usedCoupon) {
      applyCoupon(referrerCodeParam);
    } else {
      handleRemoveCoupon();
    }
  };

  const handleInvalidCoupon = (
    matchedPlan: ReturnType<typeof findPlanById>,
    subscription: IBillingSubscription | undefined
  ) => {
    if (matchedPlan) {
      if (couponParam) {
        applyCoupon(couponParam);
        checkValidation(matchedPlan.planId, couponParam);
      } else if (referrerCodeParam && !subscription?.usedCoupon) {
        applyCoupon(referrerCodeParam);
        checkValidation(matchedPlan.planId, referrerCodeParam);
      } else {
        handleRemoveCoupon();
      }
    }
  };

  const applyCoupon = (code: string) => {
    setCouponInputValue(code);
    setIsAddCouponClicked(true);
  };

  const checkValidation = (planId: string, code: string) => {
    dispatch(
      checkCouponCodeValidationRequest({
        plan: planId,
        code: code,
      })
    );
  };

  useEffect(() => {
    dispatch(getBillingSubscriptionsRequest());
  }, []);

  const shouldRenderAddCouponButton = !isCouponValid && !isAddCouponClicked && !isYearly;
  const shouldRenderCouponInput = !isCouponValid && isAddCouponClicked;
  const shouldRenderApprovedCoupon = isCouponValid;
  const shouldRenderClosetCapacity =
    selectedSubscription?.planId.includes('poshmark') && closetParam;

  const renderAlert = () => (
    <Typography
      variant="subtitle2"
      sx={{
        color: '#B71C19',
        pl: 0.5,
        lineHeight: '1rem',
      }}
    >
      {error}
    </Typography>
  );

  const renderCouponField = () => {
    if (shouldRenderAddCouponButton) {
      return (
        <Typography
          variant="body2"
          fontWeight={700}
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={() => setIsAddCouponClicked(true)}
        >
          Add discount code
        </Typography>
      );
    }

    if (shouldRenderCouponInput) {
      return (
        <Stack spacing={1.5} sx={{ width: '100%' }}>
          <TextField
            fullWidth
            type="text"
            name="followCountInitialValue"
            sx={{
              width: 1,
              '& .MuiOutlinedInput-root': {
                maxHeight: '2.5rem',
                bgcolor: `${isThereAnyCouponError ? '#FFF2EF' : '#eeeff1'}`,
                color: `${isThereAnyCouponError ? '#B71C19' : '#000000'}`,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadingButton
                    loading={loading}
                    sx={{ color: theme.palette.primary.main, mr: -1 }}
                    variant="text"
                    onClick={handleApply}
                  >
                    Apply
                  </LoadingButton>
                </InputAdornment>
              ),
            }}
            value={couponInputValue}
            onChange={handleInputChange}
          />
          {isThereAnyCouponError && renderAlert()}
        </Stack>
      );
    }

    if (shouldRenderApprovedCoupon) {
      return (
        <Stack sx={{ minWidth: '100%' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ minWidth: '100%' }}
          >
            <Label
              variant="filled"
              color="default"
              startIcon={<SellIcon sx={{ fontSize: '12px' }} />}
              sx={{
                fontSize: '14px',
                p: '8px 12px',
                color: 'slategray',
                textTransform: 'none',
                alignItems: 'center',
                fontWeight: '500',
                height: '36px',
              }}
            >
              {couponInputValue}
              <CloseIcon
                onClick={handleRemoveCoupon}
                sx={{
                  fontSize: '16px !important',
                  cursor: 'pointer',
                  fontWeight: 900,
                  ml: '3px',
                }}
              />
            </Label>
            <Typography variant="body2">-${discountAmount}</Typography>
          </Stack>
          <Typography variant="caption" pl={0.5} mt={0.1} color="#70809090">
            %{discountRate} off
          </Typography>
        </Stack>
      );
    }
  };

  return (
    <Box
      sx={{
        p: matches ? 5 : 3,
        borderRadius: 2,
        bgcolor: 'background.neutral',
        ...sx,
        maxWidth: '400px',
        width: '100%',
      }}
      {...other}
    >
      <Typography variant="h6" sx={{ mb: 5 }}>
        Summary
      </Typography>

      <Stack spacing={2.5}>
        <Stack
          spacing={matches ? 0 : 1}
          direction={matches ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Plan Name
          </Typography>

          <Label
            sx={{
              bgcolor: '#108674 !important',
              color: 'white',
              fontSize: matches ? '0.75rem' : '0.6rem',
              textTransform: 'none',
            }}
          >
            {selectedSubscription.planName}
          </Label>
        </Stack>
        {shouldRenderClosetCapacity && (
          <Stack
            spacing={matches ? 0 : 1}
            direction={matches ? 'row' : 'column'}
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Closet Capacity
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {controlledSliderValue} {controlledSliderValue > 1 ? 'Closets' : 'Closet'}
            </Typography>
          </Stack>
        )}

        <Stack spacing={0.5} direction="row" justifyContent="flex-end" alignItems="center">
          <Typography variant="h6">$</Typography>

          <Typography variant="h6">
            {isYearly ? selectedSubscription.annualPrice : selectedSubscription.price}
          </Typography>

          <Typography component="span" sx={{ mb: 1, alignSelf: 'center', color: 'text.secondary' }}>
            {isYearly ? '/yr' : '/mo'}
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {!isYearly && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
              {renderCouponField()}
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />
          </>
        )}

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Total due today</Typography>

          <Typography variant="h6">${totalPrice}</Typography>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      <LoadingButton
        loading={checkoutLoading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ mt: 5, mb: 3 }}
        onClick={handleSubscribe}
      >
        Start My Subscription
      </LoadingButton>

      <Stack alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Iconify icon="eva:shield-fill" sx={{ color: 'primary.main' }} />
          <Typography variant="subtitle2">Secure credit card payment</Typography>
        </Stack>

        <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          This is a secure 128-bit SSL encrypted payment
        </Typography>
      </Stack>
    </Box>
  );
}
