import {
  Alert,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from '../../../../types/types';
import { Automation } from '@beta.limited/primelister';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';
import useOnboarding from 'src/utils/onBoardingSteps/useOnboarding';

const DailyShareOptions = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
    loading,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const [isOptionsToggled, setIsOptionsToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);
  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const initialPriceRangeEnabled =
    automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config.filters.priceRange.enabled || false;

  const initialPriceRangeMin =
    automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config.filters.priceRange.min ?? 1;

  const initialPriceRangeMax =
    automationsSettings[Automation.AUTO_CLOSET_SHARE]?.config.filters.priceRange.max ?? 2;

  const validationSchema = Yup.object().shape({
    initialPriceRangeMin: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
    initialPriceRangeMax: Yup.number()
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const { currency } = getCurrencyInfo(activeClosetAutomationPlatform);

  const handleDailyShareFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      initialPriceRangeEnabled,
      initialPriceRangeMin,
      initialPriceRangeMax,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const handlePriceRangeEnableChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean
  ) => {
    if (hasActiveClosetConnection) {
      handleDailyShareFormik.setFieldValue('initialPriceRangeEnabled', newValue);
      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_CLOSET_SHARE]: {
            config: {
              filters: {
                priceRange: {
                  enabled: newValue,
                },
              },
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };
      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const renderAlert = () => {
    const minValue = handleDailyShareFormik.values.initialPriceRangeMin;
    const maxValue = handleDailyShareFormik.values.initialPriceRangeMax;
    let errorMessage = '';

    if (minValue === null || maxValue === null) {
      errorMessage = 'Both minimum and maximum price values are required.';
    } else if (minValue > maxValue) {
      errorMessage = 'Minimum price should be less than or equal to maximum price.';
    } else if (minValue < 0 || maxValue < 0 || minValue > 99999 || maxValue > 99999) {
      errorMessage = 'Please enter whole numbers between 0 and 99999 for both fields.';
    }

    if (errorMessage) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {errorMessage}
        </Alert>
      );
    }

    return null;
  };

  const pendingPriceUpdates = React.useRef<{ min: number | null; max: number | null }>({
    min: null,
    max: null,
  });

  const handlePriceValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: 'min' | 'max'
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      const formikField = field === 'min' ? 'initialPriceRangeMin' : 'initialPriceRangeMax';
      const inputValue = event.target.value === '' ? null : Number(event.target.value);

      const otherField = field === 'min' ? 'max' : 'min';
      const otherValue =
        field === 'min'
          ? handleDailyShareFormik.values.initialPriceRangeMax
          : handleDailyShareFormik.values.initialPriceRangeMin;

      handleDailyShareFormik.setFieldValue(formikField, inputValue);

      if (inputValue !== null) {
        const isValidInput = inputValue >= 0 && inputValue < 100000 && Number.isInteger(inputValue);

        if (isValidInput) {
          pendingPriceUpdates.current = {
            min: field === 'min' ? inputValue : otherValue ?? null,
            max: field === 'max' ? inputValue : otherValue ?? null,
          };

          onChangeTimer.current = setTimeout(() => {
            const { min, max } = pendingPriceUpdates.current;

            if (min !== null && max !== null && min <= max) {
              const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

              const dataForUpdating: IDataForUpdateAutomationSettings = {
                settings: {
                  [Automation.AUTO_CLOSET_SHARE]: {
                    config: {
                      filters: {
                        priceRange: pendingPriceUpdates.current,
                      },
                    },
                  },
                },
                toastMessage,
                displayName: selectedAutomationOption.displayName,
                activeClosetAutomationPlatform,
                activeClosetCredentialsId,
              };

              dispatch(updateAutomationSettingsRequest(dataForUpdating));
            }
          }, 700);
        }
      }
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  useEffect(
    () => () => {
      clearTimeout(onChangeTimer.current);
    },
    []
  );

  useEffect(() => {
    handleDailyShareFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const handleInfoIconClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const renderPriceRangeSwitch = () =>
    isOptionsToggled && (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginTop: '8px !important',
        }}
      >
        <FormControlLabel
          checked={handleDailyShareFormik.values.initialPriceRangeEnabled}
          key={'initialPriceRangeEnabled'}
          control={
            <Switch
              disabled={loading}
              name={'initialPriceRangeEnabled'}
              value={handleDailyShareFormik.values.initialPriceRangeEnabled}
              onChange={handlePriceRangeEnableChange}
            />
          }
          label={
            <Stack direction="row" alignItems="center" onClick={handleInfoIconClick}>
              Filter by current price of listing
              <Tooltip
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                arrow
                title="ℹ️ Set the automation to share only the listings within the specified price range."
                placement={'top'}
                sx={{ textAlign: 'center !important' }}
              >
                <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
              </Tooltip>
            </Stack>
          }
        />
      </Stack>
    );

  const renderPriceRangeInputFields = () =>
    isOptionsToggled &&
    handleDailyShareFormik.values.initialPriceRangeEnabled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            marginLeft: matches ? '48px !important' : '20px !important',
            fontSize: '14px !important',
            marginTop: '12px !important',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">From</Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                name="initialPriceRangeMin"
                sx={{
                  width: '66px !important',
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${renderError ? '#FFF2EF' : '#eeeff1'}`,
                    color: `${renderError ? '#B71C19' : '#000000'}`,
                  },
                }}
                value={handleDailyShareFormik.values.initialPriceRangeMin}
                onChange={(e) => handlePriceValueChange(e, 'min')}
              />
            </Tooltip>
            <Typography variant="body2">{currency}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">To</Typography>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Please enter a whole number between 0-99999."
              placement="top"
            >
              <TextField
                disabled={loading}
                type="number"
                name="initialPriceRangeMax"
                sx={{
                  width: '66px !important',
                  '& .MuiOutlinedInput-root': {
                    maxHeight: '2.5rem',
                    bgcolor: `${renderError ? '#FFF2EF' : '#eeeff1'}`,
                    color: `${renderError ? '#B71C19' : '#000000'}`,
                  },
                }}
                value={handleDailyShareFormik.values.initialPriceRangeMax}
                onChange={(e) => handlePriceValueChange(e, 'max')}
              />
            </Tooltip>
            <Typography variant="body2">{currency}</Typography>
          </Stack>
        </Stack>
        {renderAlert()}
      </>
    );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Options
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Customize Daily Share automation by turning on filters. Only listings that match your criteria will be shared."
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsOptionsToggled(!isOptionsToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isOptionsToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>
      {renderPriceRangeSwitch()}
      {renderPriceRangeInputFields()}
    </Stack>
  );
};

export default DailyShareOptions;
