import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

interface ExpiredSubscriptionDialogProps {
  title?: string;
  content?: string;
  titleSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  cancelButtonType?: 'text' | 'contained' | 'outlined';
}

export default function ExpiredSubscriptionDialog({
  title,
  content,
  titleSx,
  contentSx,
  buttonSx,
  cancelButtonType,
}: ExpiredSubscriptionDialogProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallerMobileScreen = useMediaQuery('(max-width: 321px)');
  
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);
  
  const { myShops: { shouldOpenExpiredSubscriptionDialog } } = states;

  const handleClose = () => dispatch(platformActions.setShouldOpenExpiredSubscriptionDialog(false));

  const handleConnection = () => {
    navigate(platform === AutomationPlatform.EBAY 
      ? PATH_DASHBOARD.subscription.ebayAutomation 
      : PATH_DASHBOARD.subscription.poshmarkAutomation
    );
    handleClose();
  };

  const platformName = platform === AutomationPlatform.EBAY ? 'eBay' : 'Poshmark';
  const shopType = platform === AutomationPlatform.EBAY ? 'shop' : 'closet';

  const dialogTitle = title || `Subscribe to ${platformName} Automation Tool: Action Required`;
  const dialogContent = content ||
    `You don't have the subscription required to connect your ${shopType}. Subscribe to ${platformName} Automation Tool and unlock its powerful automations with full access.`;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={shouldOpenExpiredSubscriptionDialog}
      sx={{
        '& .MuiPaper-root': {
          margin: !isSmallerMobileScreen ? '16px' : '8px',
        },
      }}
      onClose={handleClose}
    >
      <DialogTitle sx={{ pb: 2, ...(titleSx && { ...titleSx }) }}>{dialogTitle}</DialogTitle>
      <DialogContent sx={{ typography: 'body2', ...(contentSx && { ...contentSx }) }}>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button variant={cancelButtonType || 'text'} color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConnection}
          sx={{ textTransform: 'none', ...(buttonSx && { ...buttonSx }) }}
        >
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}
