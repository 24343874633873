import { useDispatch } from 'react-redux';

// @mui
import { Avatar, Button, Card, Typography } from '@mui/material';

import ClosetCardDetail from './ClosetCardDetail';
import {
  ConnectionStatus,
  setIsConnectDialogOpen,
  setSelectedCloset,
} from 'src/store/automations/slices/myClosetSlice';
import { IClosetDetails } from '../utils/types';
import { AutomationPlatform, useAutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getEbayAuthUrlRequest } from 'src/store/automations/ebay/slices/myShopsSlice.ebay';

export default function ClosetCardContainer({ closet }: { closet: IClosetDetails; key: any }) {
  const dispatch = useDispatch();
  const hasDisconnected = closet?.status === ConnectionStatus.Disconnected;
  const { platform } = useAutomationPlatform();

  const styles = {
    connectButton: {
      root: {
        display: 'flex',
        alignItems: 'center',
        p: '4px 16px',
        borderRadius: '8px',
      },
      icon: { fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 },
      text: {
        fontSize: '14px !important',
        lineHeight: '24px',
        fontWeight: 700,
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      p: !hasDisconnected ? '0px' : '16px',
      width: '100%',
      maxWidth: hasDisconnected ? '832px' : '800px',
      borderRadius: '8px',
    },

    skeleton: { width: '100%', height: '8rem', my: '16px !important' },
  };

  const { connectButton, card } = styles;

  const handleClosetConnect = () => {
    if (platform === AutomationPlatform.EBAY) {
      dispatch(
        getEbayAuthUrlRequest({
          country: closet?.country || 'us',
        })
      );
      return;
    }
    dispatch(setSelectedCloset(closet));
    dispatch(setIsConnectDialogOpen(true));
  };

  return (
    <Card sx={card}>
      <ClosetCardDetail closet={closet} />
      {hasDisconnected && (
        <Button
          color="primary"
          variant="contained"
          sx={connectButton.root}
          onClick={handleClosetConnect}
        >
          <Avatar src={`/icons/myClosetIcons/connectIcon.svg`} sx={connectButton.icon} />
          <Typography sx={connectButton.text}>Re-connect</Typography>
        </Button>
      )}
    </Card>
  );
}
