import { LoadingButton } from '@mui/lab';
import { Stack, MenuItem, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import { IRootState } from 'src/store';
import { IDateObject } from 'src/store/automations/slices/statisticsSlice';
import { getStatisticsDropDownItems } from '../utils/constants';
import { useAutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

const StatisticsDropDown = () => {
  const dispatch = useDispatch();
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);
  
  const { initialLoading, dateOptionsForStatistics } = states.statistics;
  const { activeClosetCredentialsId, activeCloset } = states.myShops;

  const [openDropdown, setOpenDropdown] = useState<HTMLElement | null>(null);

  const handlePopoverClose = () => setOpenDropdown(null);

  const handleDropdown = (e: React.MouseEvent<HTMLElement>) =>
    setOpenDropdown(e.currentTarget.firstElementChild as HTMLElement);

  const handleDateChange = (item: IDateObject) => {
    handlePopoverClose();
    setTimeout(() => {
      dispatch(platformActions.setDateOptionsForStatistics(item as IDateObject));
      if (activeClosetCredentialsId) {
        dispatch(platformActions.setStatisticsInitialLoading(true));
        dispatch(
          platformActions.getAutomationCountsRequest({
            shop: activeCloset?.marketplaceUserName,
            ...(item.dateOptions?.startDate && { ...item.dateOptions }),
          })
        );
      }
    }, 500);
  };

  const {
    buttonText,
    dateOptions: { startDate: stateStartDate, endDate: stateEndDate },
  } = dateOptionsForStatistics;

  const selectedMenuItemChecker = (item: IDateObject) =>
    item.dateOptions?.startDate === stateStartDate && item.dateOptions?.endDate === stateEndDate;

  const dateOptionsForDropDown = useMemo(
    () => getStatisticsDropDownItems(userAccountDetail.timeZone.name!),
    [userAccountDetail.timeZone.name]
  );

  return (
    <Stack direction="row" spacing={1}>
      <LoadingButton
        loading={initialLoading}
        color="inherit"
        onClick={handleDropdown}
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'left',
          backgroundColor: '#EDEFF2',
          color: 'black',
          p: '4px 8px',
          borderRadius: '8px',
        }}
      >
        <Iconify
          icon="solar:calendar-mark-bold"
          sx={{ fontSize: '1rem', maxWidth: '20px', maxHeight: '20px', mr: 1 }}
        />
        <Typography
          sx={{
            fontSize: '14px !important',
            lineHeight: '24px',
            fontWeight: 700,
          }}
        >
          {buttonText}
        </Typography>
        <Iconify
          icon={openDropdown ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'}
          sx={{ ml: '10px' }}
        />
      </LoadingButton>
      <MenuPopover
        open={openDropdown}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        arrow={'top-left'}
        sx={{ minWidth: 137, ml: '-26px !important', mt: '6px !important' }}
        disabledArrow={false}
      >
        {dateOptionsForDropDown.map((item) => (
          <MenuItem
            key={item.buttonText}
            sx={{
              fontWeight: '400 !important',
              backgroundColor: selectedMenuItemChecker(item) ? '#EDEFF2' : 'white',
              p: '6px 8px !important',
              maxHeight: '34px !important',
              minHeight: '34px !important',
            }}
            onClick={() => handleDateChange(item)}
          >
            {item.buttonText}
          </MenuItem>
        ))}
      </MenuPopover>
    </Stack>
  );
};

export default StatisticsDropDown;
