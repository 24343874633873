import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import LoadingScreen from 'src/components/loading-screen';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import NavHorizontal from '../LayoutComponents/NavHorizontal';
import createNavConfig from './nav/config';
import useResponsive from 'src/hooks/useResponsive';
import Header from '../automations/header/Header';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import useStripeRedirectedUser from 'src/hooks/useStripeRedirectedUser';
import useInitializeExtensionAndThirdParties from 'src/hooks/useInitializeExtensionAndThirdParties';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import { useEffect } from 'react';
import {
  getShopsListRequest,
  addEbayShopRequest,
  setActiveCloset,
} from 'src/store/automations/ebay/slices/myShopsSlice.ebay';
import Main from '../LayoutComponents/Main';
import LayoutAlertContainer from '../LayoutComponents/LayoutAlertContainer';
import axiosInstance from 'src/utils/axios';
import { poshmarkAutomationEndpoints } from 'src/endpoints';
import {
  resetAutomationsState,
  setActiveClosetAutomationPlatform,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import { useShopParamEffect } from 'src/hooks/useShopParamEffect';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';

export default function EbayAutomationsLayout() {
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const { loading } = useSelector((state: IRootState) => state.automations);
  const {
    isGetClosetRequestCompleted,
    hasCloset,
    hasConnection,
    hasActiveClosetConnection,
    hasPoshmarkSubscription,
    hasEbaySubscription,
    closetList,
    activeCloset,
  } = useSelector((state: IRootState) => state.ebayMyShops);
  const { hasSubscriptionChecked } = useSelector((state: IRootState) => state.account);
  const isMobileWeb = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isDesktop = useResponsive('up', 'sm');
  const navConfig = createNavConfig(isMobileWeb, isPrimeListerMobileApp);
  const { userId } = isLoggedIn();
  const shouldHideNavHorizontal = location.pathname.includes(PATH_DASHBOARD.subscription.root);
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const shouldShowHeader =
    isDesktop || (location.pathname.includes(PATH_AUTOMATIONS.account) && isMobile);
  
  useEffect(() => {
    dispatch(getShopsListRequest());
  }, []);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      dispatch(addEbayShopRequest({ code: decodeURIComponent(code) }));
      // Remove query parameters after processing
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [searchParams]);

  useShopParamEffect();

  //--Handles the flow for redirectedusers
  useStripeRedirectedUser();
  //--extension and third party apps
  useInitializeExtensionAndThirdParties(location, userId);

  const isMyShopsPage = location.pathname.includes(PATH_AUTOMATIONS.ebayMyShops);
  const shouldDisplayConnectionAlert = !hasCloset && !isMyShopsPage;
  const shouldDisplayReconnectionAlert =
    hasCloset &&
    closetList.filter((closet) => closet?.status === ConnectionStatus.Disconnected).length > 0;
  const shouldDisplaySubscriptionAlert = hasSubscriptionChecked && !hasEbaySubscription;

  const hasAnyAlerts =
    shouldDisplayConnectionAlert ||
    shouldDisplayReconnectionAlert ||
    shouldDisplaySubscriptionAlert;

  const alerts = {
    shouldDisplayConnectionAlert,
    shouldDisplayReconnectionAlert,
    shouldDisplaySubscriptionAlert,
  };

  return (
    <>
      {isGetClosetRequestCompleted ? (
        <>
          {shouldShowHeader && (
            <Header navConfig={navConfig} toolName="ebay" redirectPath={PATH_AUTOMATIONS.root} />
          )}
          {!shouldHideNavHorizontal && !navbarMatches && <NavHorizontal navConfig={navConfig} />}
          <Main hasAlert={hasAnyAlerts}>
            {hasAnyAlerts && <LayoutAlertContainer alerts={alerts} isInAutomations={true} />}
            <Outlet />
          </Main>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
