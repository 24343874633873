import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  useMediaQuery,
  Typography,
  Alert,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import { getCurrencyInfo } from 'src/pages/automations/AutomationsPage/helpers/getCurrencyInfo';

interface MinEarningFilterProps {
  ruleIndex: number;
}

const MinEarningFilter: React.FC<MinEarningFilterProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const { selectedAutomationOption, activeClosetAutomationPlatform, automationsSettings, loading } =
    useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const [renderError, setRenderError] = useState(false);

  const onChangeTimer = React.useRef<NodeJS.Timeout | null>(null);
  const onChangeAlertTimer = React.useRef<NodeJS.Timeout | null>(null);

  const { currencySymbol } = getCurrencyInfo(activeClosetAutomationPlatform);

  const initialValues = {
    enabled:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config?.rules[ruleIndex]?.filters
        ?.earnings?.enabled ?? false,
    unit:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config?.rules[ruleIndex]?.filters
        ?.earnings?.unit ?? 'percentage',
    value:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config?.rules[ruleIndex]?.filters
        ?.earnings?.value ?? 0,
  };

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean().required(),
    unit: Yup.string().oneOf(['percentage', 'amount']).required(),
    value: Yup.mixed().when('enabled', {
      is: true,
      then: Yup.number()
        .typeError('Please enter a valid number.')
        .required('This field is required.')
        .min(0, 'Please enter a number greater than or equal to 0.')
        .test('max-value', 'Please enter a number less than or equal to ${max}.', function (value) {
          const max = this.parent.unit === 'percentage' ? 100 : 9999;
          return typeof value === 'number' && value <= max;
        })
        .integer('Please enter a whole number.'),
      otherwise: Yup.mixed().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateAutomationSettings(values);
    },
  });

  const isPercentageSelected = formik.values.unit === 'percentage';

  const handleMinEarningFilterEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    if (hasActiveClosetConnection) {
      formik.setFieldValue('enabled', newValue);
      formik.submitForm();
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const handleUnitTypeSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasActiveClosetConnection) {
      const newValue = event.target.value as 'percentage' | 'amount';
      const currentValue = formik.values.value;

      let newInputValue: number;
      if (newValue === 'percentage') {
        newInputValue = currentValue > 100 ? 100 : currentValue;
      } else {
        newInputValue = currentValue > 9999 ? 9999 : currentValue;
      }

      formik.setFieldValue('unit', newValue);
      formik.setFieldValue('value', newInputValue);

      setRenderError(false);
      formik.setFieldError('value', undefined);
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          formik.submitForm();
        } else {
          setRenderError(true);
        }
      });
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };
  const handleMinEarningValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current as NodeJS.Timeout);
      clearTimeout(onChangeAlertTimer.current as NodeJS.Timeout);

      const inputValue = event.target.value;
      const numericValue = inputValue === '' ? null : Number(inputValue);

      formik.setFieldValue('value', numericValue);

      setRenderError(false);

      onChangeTimer.current = setTimeout(() => {
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length === 0 && numericValue !== null) {
            formik.submitForm();
          } else {
            setRenderError(true);
          }
        });
      }, 700);
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  const updateAutomationSettings = (values: typeof initialValues) => {
    if (values.value === null) {
      return; // Don't update if value is null
    }
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

    const updatedRules = automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS].config.rules.map(
      (rule, index) =>
        index === ruleIndex
          ? {
              ...rule,
              filters: {
                ...rule.filters,
                earnings: values,
              },
            }
          : rule
    );

    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
          config: {
            rules: updatedRules,
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };

    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  useEffect(() => {
    return () => {
      clearTimeout(onChangeTimer.current as NodeJS.Timeout);
      clearTimeout(onChangeAlertTimer.current as NodeJS.Timeout);
    };
  }, []);

  const renderAlert = () => {
    if (renderError && (formik.errors.value || formik.values.value === null)) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: 'white',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {formik.errors.value || 'This field is required.'}
        </Alert>
      );
    }
    return null;
  };

  const handlePreventDefault = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const renderEnableMinEarningFilterSwitch = () => (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        control={
          <Switch
            disabled={loading}
            checked={formik.values.enabled}
            onChange={handleMinEarningFilterEnableChange}
          />
        }
        label={
          <Stack
            direction="row"
            alignItems="center"
            onClick={handlePreventDefault}
            sx={{
              textWrap: 'nowrap',
            }}
          >
            Filter by minimum net earning of listing
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title="ℹ️ Enable this option to send offers only to liked items that guarantee a specified minimum net earning after discounts and fees are deducted."
              placement="top"
              sx={{ textAlign: 'center !important' }}
            >
              <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
            </Tooltip>
          </Stack>
        }
      />
    </Stack>
  );

  const renderUnitTypeOptionRadioSelection = () => (
    <Stack
      sx={{
        marginTop: '8px !important',
        marginBottom: '12px !important',
        fontSize: '14px !important',
        pl: 1,
      }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="unit"
        value={formik.values.unit}
        onChange={handleUnitTypeSelectionChange}
      >
        <FormControlLabel
          disabled={loading}
          value="percentage"
          control={<Radio />}
          label="Percentage (%)"
        />
        <FormControlLabel
          disabled={loading}
          value="amount"
          control={<Radio />}
          label={`Amount (${currencySymbol})`}
        />
      </RadioGroup>
    </Stack>
  );

  const renderMinEarningFilterInputField = () => (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ marginTop: '12px !important' }}>
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          arrow
          title={`ℹ️ Please enter a whole number between ${
            isPercentageSelected ? '0-100' : '0-9999'
          }.`}
          placement="top"
        >
          <TextField
            disabled={loading}
            type="number"
            sx={{
              width: '66px !important',
              '& .MuiOutlinedInput-root': {
                maxHeight: '2.5rem',
                bgcolor: renderError && formik.errors.value ? '#FFF2EF' : '#eeeff1',
                color: renderError && formik.errors.value ? '#B71C19' : '#000000',
              },
            }}
            value={formik.values.value}
            onChange={handleMinEarningValueChange}
            onBlur={formik.handleBlur}
            error={renderError && Boolean(formik.errors.value)}
            inputProps={{ min: 0, max: isPercentageSelected ? 100 : 9999 }}
          />
        </Tooltip>
        <Typography variant="body2" sx={{ ml: 1 }}>
          {isPercentageSelected ? '%' : currencySymbol}
        </Typography>
      </Stack>
      {renderAlert()}
    </Stack>
  );

  const renderEarningFilterOptions = () => (
    <Stack pl={6}>
      <Typography variant="body2">Price Filter Measure</Typography>
      {renderUnitTypeOptionRadioSelection()}
      <Typography variant="body2">{isPercentageSelected ? 'Percentage' : 'Amount'}</Typography>
      {renderMinEarningFilterInputField()}
    </Stack>
  );

  return (
    <Stack spacing={'12px'} px={0}>
      {renderEnableMinEarningFilterSwitch()}
      {formik.values.enabled && renderEarningFilterOptions()}
    </Stack>
  );
};

export default MinEarningFilter;
