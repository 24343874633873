import {
  Alert,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

interface AgeFilterProps {
  ruleIndex: number;
}

const getTooltipText = (platform: AutomationPlatform) => {
  switch (platform) {
    case AutomationPlatform.EBAY:
      return 'ℹ️ Enable this option to send offers only to watched items that fall within your selected listing age range.';
    default:
      return 'ℹ️ Enable this option to send offers only to liked items that fall within your selected listing age range.';
  }
};

const AgeFilter: React.FC<AgeFilterProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const {
    automations: { selectedAutomationOption, automationsSettings, loading },
    myShops: { activeClosetCredentialsId, hasActiveClosetConnection },
  } = states;

  const initialValues = {
    enabled:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.rules[ruleIndex]?.filters
        .ageRange?.enabled || false,
    min:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.rules[ruleIndex]?.filters
        .ageRange?.min ?? 0,
    max:
      automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config.rules[ruleIndex]?.filters
        .ageRange?.max ?? 12,
  };

  const validationSchema = Yup.object().shape({
    min: Yup.number()
      .typeError('Please enter a number that is between 0 and 99999.')
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test('is-integer', 'Invalid input. Please enter a whole number without decimals.', (value) =>
        Number.isInteger(value)
      )
      .test(
        'min-less-than-max',
        'Minimum age value must be less than or equal to maximum age value',
        function (value) {
          const { max } = this.parent;
          return !max || !value || Number(value) <= Number(max);
        }
      ),
    max: Yup.number()
      .typeError('Please enter a number that is between 0 and 99999.')
      .required('Please enter a number that is between 0 and 99999.')
      .min(0, 'Please enter a number that is between 0 and 99999.')
      .max(99999, 'Please enter a number that is between 0 and 99999.')
      .test('is-integer', 'Invalid input. Please enter a whole number without decimals.', (value) =>
        Number.isInteger(value)
      )
      .test(
        'max-greater-than-min',
        'Maximum age value must be greater than or equal to minimum age value',
        function (value) {
          const { min } = this.parent;
          return !min || !value || Number(value) >= Number(min);
        }
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const updateAutomationSettings = (values: typeof initialValues) => {
    if (values.enabled && (values.min === undefined || values.max === undefined)) {
      formik.setFieldError(
        'min',
        'Both minimum and maximum age must be set when filter is enabled'
      );
      formik.setFieldError(
        'max',
        'Both minimum and maximum age must be set when filter is enabled'
      );
      return;
    }

    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

    const updatedRules = automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS].config.rules.map(
      (rule, index) =>
        index === ruleIndex
          ? {
              ...rule,
              filters: {
                ...rule?.filters,
                ageRange: {
                  enabled: values.enabled,
                  min: values.min,
                  max: values.max,
                },
              },
            }
          : rule
    );

    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
          config: {
            rules: updatedRules,
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform: states.automations.activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };

    dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
  };

  useEffect(() => {
    if (formik.dirty && !formik.errors.min && !formik.errors.max) {
      const timer = setTimeout(() => {
        updateAutomationSettings(formik.values);
      }, 700);

      return () => clearTimeout(timer);
    }
  }, [formik.values, formik.errors]);

  const handleAgeFilterEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasActiveClosetConnection) {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
      return;
    }

    const isEnabled = event.target.checked;
    formik.setFieldValue('enabled', isEnabled);

    if (isEnabled) {
      // When enabling, use the initial values if current values are empty
      const newMin = formik.values.min || initialValues.min;
      const newMax = formik.values.max || initialValues.max;
      formik.setFieldValue('min', newMin);
      formik.setFieldValue('max', newMax);
      updateAutomationSettings({ ...formik.values, enabled: isEnabled, min: newMin, max: newMax });
    } else {
      // When disabling, keep the current min and max values
      updateAutomationSettings({ ...formik.values, enabled: isEnabled });
    }
  };

  const renderAlert = () => {
    if (formik.errors.min || formik.errors.max) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: 'white',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
            marginLeft: matches ? '48px !important' : '20px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {formik.errors.min || formik.errors.max}
        </Alert>
      );
    }
  };

  const renderEnableAgeLimitSwitch = () => (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        control={
          <Switch
            disabled={loading}
            checked={formik.values.enabled}
            onChange={handleAgeFilterEnableChange}
          />
        }
        sx={{
          mr: 0,
        }}
        label="Filter by age (in days) of listing"
      />
      <Tooltip
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        arrow
        title={getTooltipText(platform)}
        placement="top"
      >
        <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
      </Tooltip>
    </Stack>
  );

  const renderAgeFilterInputField = () => (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          marginLeft: matches ? '48px !important' : '20px !important',
          fontSize: '14px !important',
          marginTop: '12px !important',
        }}
      >
        <Stack direction="row" alignItems="center">
          From
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 0-99999."
            placement="top"
          >
            <TextField
              type="number"
              disabled={loading}
              sx={{
                width: '66px !important',
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: formik.errors.min ? '#FFF2EF' : '#eeeff1',
                  color: formik.errors.min ? '#B71C19' : '#000000',
                },
              }}
              name="min"
              value={formik.values.min}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.min && Boolean(formik.errors.min)}
              inputProps={{ min: 0, max: 99999 }}
            />
          </Tooltip>
          days
        </Stack>
        <Stack direction="row" alignItems="center" ml="15px">
          To
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Please enter a whole number between 0-99999."
            placement="top"
          >
            <TextField
              disabled={loading}
              type="number"
              sx={{
                width: '66px !important',
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: formik.errors.max ? '#FFF2EF' : '#eeeff1',
                  color: formik.errors.max ? '#B71C19' : '#000000',
                },
              }}
              name="max"
              value={formik.values.max}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.max && Boolean(formik.errors.max)}
              inputProps={{ min: 0, max: 99999 }}
            />
          </Tooltip>
          days
        </Stack>
      </Stack>
      {renderAlert()}
    </>
  );

  return (
    <Stack spacing={2} px={0}>
      {renderEnableAgeLimitSwitch()}
      {formik.values.enabled && renderAgeFilterInputField()}
    </Stack>
  );
};

export default AgeFilter;
