import { Divider, Stack } from '@mui/material';
import OfferDelay from './OfferDelay';
import PoshmarkOfferToLikersRules from './poshmark/OfferToLikersSettingsRules.poshmark';
import EbayOfferToLikersRules from './ebay/OfferToLikersSettingsRules.ebay';
import { useDispatch } from 'react-redux';
import { Automation, AutoSendOfferToLikersRule, EbayAutoSendOfferToLikersRule } from '@beta.limited/primelister';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';

// Poshmark mock rule remains the same for Poshmark platform
const poshmarkMockRule: AutoSendOfferToLikersRule = {
  filters: {
    category: { enabled: false, selectedCategories: [] },
    ageRange: { enabled: false, min: 0, max: 12 },
    dateRange: { enabled: false, min: 0, max: 12 },
    likeRange: { enabled: false, min: 0, max: 100 },
    lowestPriceRange: { enabled: false, min: 1, max: 2 },
    priceRange: { enabled: false, min: 1, max: 2 },
    condition: { enabled: false, nwt: false, boutique: false },
    earnings: { enabled: false, unit: 'percentage', value: 0 },
  },
  offer: { unit: 'percentage', value: 10 },
  shipping: { option: 'original', customValue: '' },
};

// eBay mock rule based on the provided type
const ebayMockRule: EbayAutoSendOfferToLikersRule = {
  filters: {
    ageRange: { enabled: false, min: 0, max: 12 },
    priceRange: { enabled: false, min: 1, max: 2 },
    condition: { enabled: false, new: false, used: false },
  },
  offer: {
    percentage: 5,
    message: {
      enabled: false,
      text: "Glad you're interested! Here's a special offer for you.",
    }
  },
};

const OfferToLikersSettings = () => {
  const { platform, states } = useAutomationPlatform();
  const { automationsSettings } = states.automations;
  const offerToLikersSettings = automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS];

  if (platform === AutomationPlatform.EBAY) {
    const ebayRules = (offerToLikersSettings?.config?.rules || [ebayMockRule]) as EbayAutoSendOfferToLikersRule[];
    return (
      <Stack spacing={'20px'} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
        {/* <OfferDelay /> */}
        <EbayOfferToLikersRules initialSettings={ebayRules} />
      </Stack>
    );
  }

  const poshmarkRules = (offerToLikersSettings?.config?.rules || [poshmarkMockRule]) as AutoSendOfferToLikersRule[];
  return (
    <Stack spacing={'20px'} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
      <OfferDelay />
      <PoshmarkOfferToLikersRules initialSettings={poshmarkRules} />
    </Stack>
  );
};

export default OfferToLikersSettings;
