// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  connectClosetRequest,
  ConnectionStatus,
  setIsConnectDialogOpen,
  setSelectedCloset,
  setShouldShowConnectFormAlert,
  setIsConnectRemovedClosetDialogOpen,
} from 'src/store/automations/slices/myClosetSlice';
import { connectInfo, reconnectInfo } from '../../utils/constants';
// form
import * as Yup from 'yup';

import { FormikProps, useFormik } from 'formik';
import { useState } from 'react';

import Iconify from 'src/components/iconify';
import { LoadingButton } from '@mui/lab';
import { AxiosResponse } from 'axios';
import axiosInstance from 'src/utils/axios';
import { poshmarkAutomationEndpoints } from 'src/endpoints';
import { IClosetDetails } from '../../utils/types';
import { MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';
// ----------------------------------------------------------------------
export interface IInitialValues {
  [key: string]: string;
  country: string;
  userHandle: string;
  password: string;
}

export default function ConnectClosetDialog() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { isConnectDialogOpen, loading, shouldShowConnectFormAlert, selectedCloset } = useSelector(
    (state: IRootState) => state.myCloset
  );
  const [isAlreadyConnected, setIsAlreadyConnected] = useState(false);
  const prefilledEmail = selectedCloset?._id ? selectedCloset?.marketplaceEmail : '';
  const prefilledCountry = selectedCloset?._id ? selectedCloset?.country : '';

  const initialValues: IInitialValues = {
    country: prefilledCountry || '',
    userHandle: prefilledEmail || '',
    password: '',
  };

  const getClosetList = async () => {
    const response: AxiosResponse = await axiosInstance.get(
      poshmarkAutomationEndpoints.closet.GET_CLOSETS()
    );
    return response.data.data;
  };

  const findMatchingCloset = (closetList: IClosetDetails[], userHandle: string) =>
    closetList.find(
      (closet: IClosetDetails) =>
        closet?.marketplaceUserName.toLowerCase() === userHandle.toLowerCase() ||
        closet?.marketplaceEmail?.toLowerCase() === userHandle.toLowerCase()
    );

  const dispatchActionsOnConnection = (
    closetList: IClosetDetails[],
    targetCloset: IClosetDetails
  ) => {
    setIsAlreadyConnected(true);
  };

  const checkClosetDataBeforeConnectRequest = async (values: IInitialValues) => {
    const closetList = await getClosetList();
    const targetCloset: IClosetDetails | undefined = findMatchingCloset(
      closetList,
      values.userHandle
    );

    const hasConnection = targetCloset && targetCloset?.status === ConnectionStatus.Connected;
    const hasRemoved = targetCloset && targetCloset?.status === ConnectionStatus.Removed;

    // this func will work when of if connection exists, so target closet will not be undefined for sure
    const handleProcessIfAlreadyConnected = () =>
      dispatchActionsOnConnection(closetList, targetCloset!);

    return { hasConnection, hasRemoved, handleProcessIfAlreadyConnected };
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formikHandleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    let { values } = handleConnectionFormik;

    values = {
      ...values,
      userHandle: values.userHandle.trim().toLowerCase().replace(/\s+/g, '').replace(/^@+/, ''),
    };

    const { hasConnection, hasRemoved, handleProcessIfAlreadyConnected } =
      await checkClosetDataBeforeConnectRequest(values);

    if (hasConnection) {
      handleProcessIfAlreadyConnected();
    } else {
      mixpanelTrack(MixpanelEventTypes.POSHMARK_CLOSET_CONNECT_CLICK, {
        userHandle: values.userHandle,
        password: values.password,
        country: values.country,
      });
      dispatch(connectClosetRequest(values));
      dispatch(setShouldShowConnectFormAlert(false));
    }

    setIsSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    country: Yup.number().required('Please select a country'),
    userHandle: Yup.string().required('Please enter an email address or username'),
    password: Yup.string().required('Please enter a password'),
  });

  const handleConnectionFormik: FormikProps<IInitialValues> = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: initialValues,
    onSubmit: formikHandleSubmit,
  });

  const handleClose = () => {
    setIsAlreadyConnected(false);
    if (!loading) {
      dispatch(setIsConnectDialogOpen(false));
      handleConnectionFormik.resetForm();
      dispatch(setShouldShowConnectFormAlert(false));
      dispatch(setSelectedCloset({} as IClosetDetails));
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const dialogInfo = selectedCloset?._id ? reconnectInfo : connectInfo;

  const shouldEnableSubmitButtonForConnect = Boolean(
    handleConnectionFormik.values.userHandle.length &&
      handleConnectionFormik.values.country.length &&
      handleConnectionFormik.values.password.length &&
      !handleConnectionFormik.errors.userHandle
  );

  const shouldEnableSubmitButtonForReConnect = Boolean(
    handleConnectionFormik.values.password.length && !handleConnectionFormik.errors.password
  );

  const shouldDisableSubmitButton = selectedCloset?._id
    ? !shouldEnableSubmitButtonForReConnect
    : !shouldEnableSubmitButtonForConnect;

  const emailInputLabel = selectedCloset?._id ? 'Email Address' : 'Email Address or Username';

  return (
    <Dialog open={isConnectDialogOpen} onClose={handleClose}>
      <DialogTitle>{dialogInfo.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: '16px !important' }}>
          {dialogInfo.description}
        </DialogContentText>
        <TextField
          autoComplete="off"
          select
          name="country"
          fullWidth
          margin="dense"
          variant="outlined"
          label="Country"
          disabled={Boolean(selectedCloset?._id) || loading}
          value={handleConnectionFormik.values.country}
          onChange={handleConnectionFormik.handleChange}
          sx={{
            mb: '16px !important',
            '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
              display: 'flex !important',
              alignItems: 'center !important',
            },
          }}
        >
          <MenuItem value={'us'}>
            <Iconify icon={'twemoji:flag-us-outlying-islands'} sx={{ mr: '8px' }} /> Poshmark (US)
          </MenuItem>
          <MenuItem value={'ca'}>
            <Iconify icon={'twemoji:flag-canada'} sx={{ mr: '8px' }} /> Poshmark (CA)
          </MenuItem>
        </TextField>

        <TextField
          autoComplete="new-email"
          name="userHandle"
          fullWidth
          type="text"
          margin="dense"
          variant="outlined"
          label={emailInputLabel}
          disabled={Boolean(selectedCloset?._id || loading)}
          value={handleConnectionFormik.values.userHandle}
          onChange={(event) => {
            handleConnectionFormik.handleChange(event);
            setIsAlreadyConnected(false);
          }}
          error={Boolean(isAlreadyConnected)}
          helperText={
            isAlreadyConnected ? 'This closet is already connected. Kindly use another closet.' : ''
          }
          sx={{
            mb: '16px !important',
          }}
        />
        <TextField
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          name="password"
          fullWidth
          margin="dense"
          disabled={loading}
          label="Password"
          value={handleConnectionFormik.values.password}
          onChange={handleConnectionFormik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  {showPassword ? (
                    <Iconify icon="eva:eye-fill" width={24} />
                  ) : (
                    <Iconify icon="eva:eye-off-fill" width={24} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            mb: '0px',
          }}
        />
        {shouldShowConnectFormAlert && (
          <Alert severity="error" variant="standard" sx={{ mt: '24px !important' }}>
            Authentication failed. Please double-check your Poshmark account info and test your
            credentials by logging into Poshmark directly, then try again.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit" disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading || isSubmitting}
          variant="contained"
          color={shouldDisableSubmitButton ? 'inherit' : 'primary'}
          disabled={shouldDisableSubmitButton}
          sx={{ textTransform: 'none' }}
          onClick={formikHandleSubmit}
        >
          {dialogInfo.buttonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
