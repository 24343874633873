import {
  Alert,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import { DUMMY_HOURLY_RELIST_LIMIT } from './constants';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

const RelistLimit = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
    relistLimitError,
    loading,
  } = states.automations;
  const { activeClosetCredentialsId, hasActiveClosetConnection } = states.myShops;

  const onChangeTimer = React.useRef<any>();
  const onChangeAlertTimer = React.useRef<any>();

  const [isRelistLimitToggled, setIsRelistLimitToggled] = useState(true);
  const [renderError, setRenderError] = useState(false);

  const relistLimitInitialValue = !hasActiveClosetConnection
    ? DUMMY_HOURLY_RELIST_LIMIT
    : automationsSettings[Automation.AUTO_RELIST]?.config.hourlyTaskLimit || 0;

  const validationSchema = Yup.object().shape({
    relistLimitInitialValue: Yup.number()
      .required('Please enter a whole number between 1-25.')
      .min(1, 'Please enter a number that is between 1 and 25.')
      .max(25, 'Please enter a number that is between 1 and 25.')
      .test(
        'is-multiple-of-10',
        'Invalid input. Please enter a whole number without decimals.',
        (value) => Number.isInteger(value)
      ),
  });

  const handleRelistLimitFormik = useFormik({
    enableReinitialize: true,
    initialValues: { relistLimitInitialValue },
    validationSchema,
    onSubmit: () => {},
  });

  const isThereAnError =
    handleRelistLimitFormik.errors.relistLimitInitialValue || !!relistLimitError;

  const handleAutomationSettingsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (hasActiveClosetConnection) {
      clearTimeout(onChangeTimer.current);
      clearTimeout(onChangeAlertTimer.current);

      handleRelistLimitFormik.handleChange(event);
      onChangeAlertTimer.current = setTimeout(() => setRenderError(true), 500);

      const inputValue = Number(event.target.value);

      if (relistLimitError?.length) {
        dispatch(platformActions.setRelistLimitError(''));
      }

      const isValidInput = inputValue >= 1 && inputValue <= 25;
      const hasValidInputValue = Number.isInteger(inputValue) && isValidInput;

      if (hasValidInputValue) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        const dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {
            [Automation.AUTO_RELIST]: {
              config: {
                hourlyTaskLimit: inputValue,
              },
            },
          },
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
          hourlyRelistLimitValue: inputValue,
          isFromRelistLimit: true,
        };

        const isInputValueDifferentFromInitial =
          Number(event.target.value) !==
          handleRelistLimitFormik.initialValues.relistLimitInitialValue;

        onChangeTimer.current = setTimeout(() => {
          isInputValueDifferentFromInitial &&
            dispatch(platformActions.updateAutomationSettingsRequest(dataForUpdating));
        }, 700);
      }
    } else {
      dispatch(platformActions.setIsConnectionErrorDialogOpen(true));
    }
    setRenderError(false);
  };

  useEffect(() => {
    dispatch(platformActions.setHourlyRelistLimit(handleRelistLimitFormik.values.relistLimitInitialValue));
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(onChangeTimer.current);
    };
  }, []);

  useEffect(() => {
    handleRelistLimitFormik.resetForm();
  }, [isAutomationOptionDrawerOpen]);

  const renderAPIAlert = () => (
    <Alert
      severity="error"
      variant="standard"
      sx={{
        mt: '12px !important',
        width: '100%',
        color: '#7A4100',
        padding: '6px 8px',
        mb: '4px!important',
        '& .MuiAlert-icon': {
          pl: '8px',
        },
      }}
    >
      {relistLimitError}
    </Alert>
  );

  const renderAlert = () => {
    if (renderError) {
      return (
        <Alert
          sx={{
            color: '#FF5630',
            bgcolor: '#F4F6F8',
            maxWidth: '360px !important',
            overflow: 'hidden',
            p: 0,
            mt: '-2px !important',
          }}
          severity="error"
          variant="standard"
          icon={false}
        >
          {handleRelistLimitFormik.errors.relistLimitInitialValue as string}
        </Alert>
      );
    }
  };

  const getActionText = () => {
    switch (platform) {
      case AutomationPlatform.EBAY:
        return {
          title: 'Hourly Re-create limit',
          tooltip: 'Configure the number of items for re-creating in each enabled hour.',
          inputLabel: 'Re-create',
        };
      default:
        return {
          title: 'Hourly Re-list limit',
          tooltip: 'Configure the number of items for re-listing in each enabled hour.',
          inputLabel: 'Re-list',
        };
    }
  };

  const renderRelistlimitCountInputField = () =>
    isRelistLimitToggled && (
      <>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            fontSize: '14px !important',
            mb: '4px !important',
            mt: '12px !important',
            color: '#212B36',
          }}
        >
          {getActionText().inputLabel}
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title={`ℹ️ ${getActionText().tooltip}`}
            placement={'top'}
          >
            <TextField
              disabled={loading}
              type="number"
              name="relistLimitInitialValue"
              sx={{
                width: 0.19,
                mx: 1,
                '& .MuiOutlinedInput-root': {
                  maxHeight: '2.5rem',
                  bgcolor: `${isThereAnError ? '#FFF2EF' : '#eeeff1'}`,
                  color: `${isThereAnError ? '#B71C19' : '#000000'}`,
                },
              }}
              onClick={() =>
                !hasActiveClosetConnection &&
                dispatch(platformActions.setIsConnectionErrorDialogOpen(true))
              }
              value={handleRelistLimitFormik.values.relistLimitInitialValue}
              onChange={handleAutomationSettingsChange}
            />
          </Tooltip>
          items every hour
        </Stack>
        {handleRelistLimitFormik.errors.relistLimitInitialValue && renderAlert()}
        {relistLimitError && renderAPIAlert()}
      </>
    );

  return (
    <Stack spacing={2.125} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          {getActionText().title}
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title={`ℹ️ ${getActionText().tooltip}`}
            placement={'top'}
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>
        <IconButton
          size="small"
          onClick={() => setIsRelistLimitToggled(!isRelistLimitToggled)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isRelistLimitToggled ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>
      {renderRelistlimitCountInputField()}
    </Stack>
  );
};

export default RelistLimit;
