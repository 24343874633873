import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import ClosetSelectionDropDown from 'src/pages/automations/MyClosetPage/components/ClosetSelectionDropDown';
import ClosetCardContainer from 'src/pages/automations/MyClosetPage/components/ClosetCardContainer';
import ConnectClosetDialog from 'src/pages/automations/MyClosetPage/components/dialogs/ConnectClosetDialog';
import RemoveClosetDialog from 'src/pages/automations/MyClosetPage/components/dialogs/RemoveClosetDialog';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import ExpiredSubscriptionDialog from 'src/pages/automations/MyClosetPage/components/dialogs/ExpiredSubscriptionDialog';
import MyClosetDialogs from 'src/pages/automations/MyClosetPage/components/dialogs/MyClosetDialogs';
import MainConnectButton from 'src/pages/automations/MyClosetPage/components/MainConnectButton';
import { useRef } from 'react';
import { StylesByDevice } from 'src/pages/automations/MyListingsPage/components/ListingsBulkActionsMenu';
import { bgBlur } from 'src/utils/cssStyles';
import { useScrollDisplay } from 'src/hooks/useScrollDisplay';

export default function EbayMyShopsPage() {
  const matches = useResponsive('up', 'sm');
  const isMobileWeb = useResponsive('down', 'sm');
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { hasCloset, hasConnection } = useSelector((state: IRootState) => state.ebayMyShops);
  const { scrollDisplay, navbarMatches } = useScrollDisplay();

  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const titleMatches = useMediaQuery('(max-width:380px)');
  const stackRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const stylesByDevice: StylesByDevice = {
    rootStyles: {
      mobile: {
        position: 'initial',
        boxShadow: '#ffffff',
        mb: '24px',
      },
      desktop: {
        position: 'sticky',
        top: navbarMatches ? 60 : 108,
        boxShadow: scrollDisplay ? '0px 8px 8px -5px #E5E8EB' : '#ffffff',
        mb: '32px',
        ...bgBlur({
          color: theme.palette.background.default,
        }),
      },
    },
  };

  const stackStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: matches ? '0px' : '32px',
    py: matches ? 5 : 0,
    mt: matches ? -5 : 0,
    zIndex: 11,
    px: hasConnection ? 0 : '16px',
    ...(isMobile ? stylesByDevice.rootStyles.mobile : stylesByDevice.rootStyles.desktop),
  };

  return (
    <>
      <Helmet>
        <title> My eBay Shops | Primelister</title>
      </Helmet>

      <Container
        disableGutters
        sx={{
          maxWidth: hasCloset && !hasConnection ? '832px !important' : '800px !important',
          p: 0,
        }}
      >
        <Stack
          ref={stackRef}
          direction={matches ? 'row' : 'column'}
          spacing={matches ? 0 : '24px'}
          sx={stackStyles}
        >
          {((isMobile && titleMatches) || !isMobile) && <AutomationsPageTitle title="My Shops" />}

          <MainConnectButton />
        </Stack>
        <ClosetCardContainer />
        <MyClosetDialogs />
      </Container>
    </>
  );
}
