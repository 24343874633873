import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { IRootState } from 'src/store';
import {
  getAllConnectedApiShopsRequest,
  shopifyCreateCredentialRequest,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import MyShopsPageNewVersion from './MyShopsPageNewVersion';

function MyShopsPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { isLayoutRequestSent } = useSelector((state: IRootState) => state.myShopsAPITable);

  useEffect(() => {
    const code = searchParams.get('code');
    const shop = searchParams.get('shop');

    // Handle only Shopify connections
    if (code && shop) {
      dispatch(shopifyCreateCredentialRequest({ shop, code }));
    }

    if (isLayoutRequestSent) {
      dispatch(getAllConnectedApiShopsRequest());
    }
  }, []);

  return <MyShopsPageNewVersion />;
}

export default MyShopsPage;
