export function getCurrencyInfo(activeClosetAutomationPlatform: string) {
  let currencySymbol = '$';
  let currency = 'USD';

  switch (true) {
    case activeClosetAutomationPlatform.includes('-ca'):
      currencySymbol = 'C$';
      currency = 'CAD';
      break;
    case activeClosetAutomationPlatform.includes('-au'):
      currencySymbol = 'A$';
      currency = 'AUD';
      break;
    default:
      currencySymbol = '$';
      currency = 'USD';
      break;
  }
  return {
    currencySymbol,
    currency,
  };
}
