import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { poshmarkAutomationEndpoints } from 'src/endpoints';
import axiosInstance from 'src/utils/axios';

import {
  setAutomationsSettings,
  getAutomationListFail,
  getAutomationListRequest,
  getAutomationListSuccess,
  updateAutomationSettingsFail,
  updateAutomationSettingsRequest,
  updateAutomationSettingsSuccess,
  setInitialLoading,
  getActiveClosetPlatformShippingOffersSuccess,
  getActiveClosetPlatformShippingOffersFail,
  getActiveClosetPlatformShippingOffersRequest,
  createAutomationWorkerSuccess,
  createAutomationWorkerFail,
  createAutomationWorkerRequest,
  getUserTagsRequest,
  getUserTagsFail,
  getUserTagsSuccess,
  setUserTagsRequest,
  setUserTagsFail,
  setUserTagsSuccess,
  UserTagTypesEnum,
  setSelectedShareHourData,
  setIsShareHourDialogOpen,
  setIsRelistHourDialogOpen,
  setHourlyRelistLimit,
  setRelistHourDialogError,
  setRelistLimitError,
} from '../slices/automationsSlice.ebay';
import { toast } from 'react-toastify';
import {
  IDataForUpdateAutomationSettings,
  IAutomationWorkerData,
} from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import { RenderTree } from 'src/pages/automations/MyListingsPage/components/shared/types/CategoryTreeView';

function* getAutomationListSaga(
  action: PayloadAction<{ marketplace: string; activeClosetCredentialsId: string }>
) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(
        poshmarkAutomationEndpoints.automation.GET_AUTOMATIONS({
          marketplace: action.payload.marketplace,
          activeClosetCredentialsId: action.payload.activeClosetCredentialsId,
        })
      )
    );
    yield put(setAutomationsSettings(response.data.data.settings));
    yield put(getAutomationListSuccess());
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getAutomationListFail(error.message));
  }
}

function* updateAutomationSettingsSaga(action: PayloadAction<IDataForUpdateAutomationSettings>) {
  try {
    const { settings, toastMessage, hourlyRelistLimitValue } = action.payload;

    const { data } = yield call(() =>
      axiosInstance.put(poshmarkAutomationEndpoints.automation.UPDATE_AUTOMATIONS(), {
        marketplace: action.payload.activeClosetAutomationPlatform,
        credentialsId: action.payload.activeClosetCredentialsId,
        settings: action.payload.settings,
      })
    );
    yield put(setAutomationsSettings(data.data.settings));

    const updatedShareHoursData = settings[Automation.AUTO_CLOSET_SHARE]?.config?.taskHours?.[0];
    const updatedRelistHoursData = settings[Automation.AUTO_RELIST]?.config?.taskHours?.[0];

    if (updatedRelistHoursData) {
      yield put(
        setIsRelistHourDialogOpen({
          isOpen: false,
        })
      );
    }

    if (updatedShareHoursData) {
      yield put(
        setIsShareHourDialogOpen({ isOpen: false, sharingHourDialogData: updatedShareHoursData })
      );
    }

    toast.success(toastMessage, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    if (hourlyRelistLimitValue) {
      yield put(setHourlyRelistLimit(hourlyRelistLimitValue));
    }

    yield put(updateAutomationSettingsSuccess());
  } catch (error) {
    const { isFromRelistDialog, isFromRelistLimit } = action.payload;

    if (isFromRelistDialog) {
      yield put(setRelistHourDialogError(error.error));
    } else if (isFromRelistLimit) {
      yield put(setRelistLimitError(error.error));
    }

    yield put(updateAutomationSettingsFail(error.error));
  }
}

function* getActiveClosetPlatformShippingOffersSaga(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(poshmarkAutomationEndpoints.automation.GET_SHIPPING_OFFERS(action.payload))
    );
    yield put(getActiveClosetPlatformShippingOffersSuccess(response.data.data));
    yield put(setInitialLoading(false));
  } catch (error) {
    yield put(getActiveClosetPlatformShippingOffersFail(error.message));
  }
}

function* createAutomationWorkerSaga(action: PayloadAction<IAutomationWorkerData>) {
  const {
    activeClosetCredentialsId,
    marketplace,
    automation,
    status,
    toastMessage,
    automationsSettings,
  } = action.payload;
  const activationStatus = status ? 'activate' : 'deactivate';
  const workerData = { credentialsId: activeClosetCredentialsId, marketplace, automation };
  try {
    yield call(() =>
      axiosInstance.post(
        poshmarkAutomationEndpoints.automation.CREATE_AUTOMATION_WORKER(activationStatus),
        workerData
      )
    );
    toast.success(toastMessage, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

    yield put(
      setAutomationsSettings({
        ...automationsSettings,
        [automation]: {
          ...automationsSettings[automation],
          enabled: status,
        },
      })
    );
    yield put(createAutomationWorkerSuccess());
  } catch (error) {
    toast.error(error.error, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    yield put(createAutomationWorkerFail());
  }
}

function* getUserTagsSaga(action: PayloadAction<{ userId: string }>) {
  const { userId } = action.payload;

  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.get(poshmarkAutomationEndpoints.automation.GET_USER_TAGS(userId))
    );

    const poshmarkAutomation = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION
    );
    const poshmarkAutomationFeatures = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION_FEATURES
    );
    const multipleCloset = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_MULTIPLE_CLOSETS
    );
    const inventoryFullscreen = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_INVENTORY_FULLSCREEN
    );

    const isMobileAppRateSubmitted = response.data?.data?.tags?.includes(
      UserTagTypesEnum.MOBILE_APP_RATE_SUBMITTED
    );

    yield put(
      getUserTagsSuccess({
        poshmarkAutomation,
        poshmarkAutomationFeatures,
        multipleCloset,
        isMobileAppRateSubmitted,
        inventoryFullscreen,
      })
    );
  } catch (error) {
    yield put(getUserTagsFail(error.message));
  }
}

function* setUserTagsSaga(
  action: PayloadAction<{ userId: string; userTagType: UserTagTypesEnum }>
) {
  const { userId, userTagType } = action.payload;

  try {
    const response: AxiosResponse = yield call(() =>
      axiosInstance.post(poshmarkAutomationEndpoints.automation.SET_USER_TAG(userId, userTagType))
    );
    const poshmarkAutomation = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION
    );
    const poshmarkAutomationFeatures = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_POSHMARK_AUTOMATION_FEATURES
    );
    const multipleCloset = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_MULTIPLE_CLOSETS
    );
    const inventoryFullscreen = response.data?.data?.tags?.includes(
      UserTagTypesEnum.ONBOARDING_INVENTORY_FULLSCREEN
    );
    const isMobileAppRateSubmitted = response.data?.data?.tags?.includes(
      UserTagTypesEnum.MOBILE_APP_RATE_SUBMITTED
    );
    yield put(
      setUserTagsSuccess({
        poshmarkAutomation,
        poshmarkAutomationFeatures,
        multipleCloset,
        isMobileAppRateSubmitted,
        inventoryFullscreen,
      })
    );
  } catch (error) {
    yield put(setUserTagsFail(error.message));
  }
}

export function* automationsModuleSaga() {
  yield takeLatest(getAutomationListRequest.type, getAutomationListSaga);
  yield takeLatest(updateAutomationSettingsRequest.type, updateAutomationSettingsSaga);
  yield takeLatest(
    getActiveClosetPlatformShippingOffersRequest.type,
    getActiveClosetPlatformShippingOffersSaga
  );
  yield takeLatest(createAutomationWorkerRequest.type, createAutomationWorkerSaga);
  yield takeLatest(getUserTagsRequest.type, getUserTagsSaga);
  yield takeLatest(setUserTagsRequest.type, setUserTagsSaga);
}
