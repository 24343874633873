// @mui
import { CircularProgress, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
// hooks

import ActivityCard from './ActivityCard';
import Image from 'src/components/image';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  getActivityListRequest,
  getOngoingListRequest,
  setInitialLoading,
} from 'src/store/automations/slices/activitySlice';
import { ACTIVITY_REQUEST_TYPE } from '../utils/constants';
import { checkValueForRelist } from '../utils/helpers';
import OngoingTaskCard from './OngoingTaskCard';
import { SubscriptionType, UserAction } from '@beta.limited/primelister';
import { useCallback, useEffect } from 'react';

export default function OngoingTabCardContainer() {
  const {
    initialLoading = true,
    hasMoreActivity,
    ongoingList,
  } = useSelector((state: IRootState) => state.activity);

  const matches = useMediaQuery('(min-width:700px)');

  const { activeCloset } = useSelector((state: IRootState) => state.myCloset);
  const { selectedOngoingTask, loading } = useSelector((state: IRootState) => state.activity);
  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);
  const dispatch = useDispatch();

  const fetchOngoingList = useCallback(() => {
    dispatch(setInitialLoading(true));
    dispatch(
      getOngoingListRequest({
        ...(selectedOngoingTask &&
          selectedOngoingTask.value !== 'all' && {
            action: selectedOngoingTask.value as UserAction,
          }),
        product: SubscriptionType.POSHMARK_AUTOMATION,
        shop: activeCloset.marketplaceUserName,
      })
    );
  }, [dispatch, selectedOngoingTask, activeCloset.marketplaceUserName]);

  const fetchDataWithScroll = () => {
    dispatch(
      getOngoingListRequest({
        ...(selectedOngoingTask && {
          action: selectedOngoingTask.value as UserAction,
        }),
        product: SubscriptionType.POSHMARK_AUTOMATION,
        shop: activeCloset.marketplaceUserName,
      })
    );
  };

  useEffect(() => {
    fetchOngoingList();
  }, [activeClosetCredentialsId]);

  const addSkeletonToComponents = (component: any) => {
    if (!initialLoading) {
      return component;
    } else {
      const skeletonItems = Array.from({ length: 3 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          sx={{ width: '100%', height: '6rem', my: '16px !important' }}
        />
      ));
      return <>{skeletonItems}</>;
    }
  };

  const sortedOngoingList = ongoingList
    .slice() // To avoid mutating the original array
    .sort((a, b) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime());

  const renderEmptyContent = () => (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        mt: -6,
        height: 1,
        textAlign: 'center',
        p: (theme) => theme.spacing(8, 2),
      }}
    >
      <Image
        disabledEffect
        alt="empty content"
        src={'/assets/illustrations/illustration_empty_content.svg'}
        sx={{ width: '214.8px', height: '161.1px', mb: 3 }}
      />

      <Typography variant="body2" gutterBottom>
        Nothing to see yet. Your ongoing custom tasks will appear here.
      </Typography>
    </Stack>
  );

  return ongoingList && ongoingList.length > 0 ? (
    <Stack spacing={'16px'}>
      {sortedOngoingList.map((activity) =>
        addSkeletonToComponents(<OngoingTaskCard key={activity.jobId} ongoingTask={activity} />)
      )}
      {/* 
      TODO: We will add infinite scroll later
      <InfiniteScroll
        dataLength={ongoingList.length + 2} //This is important field to render the next data
        next={fetchDataWithScroll}
        hasMore={false}
        loader={
          <CircularProgress color="inherit" sx={{ alignSelf: 'center' }} size={40} thickness={4} />
        }
        style={{
          border: 'none !important',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          msOverflowStyle: 'none',
        }}
      >
        {ongoingList.map((activity) =>
          addSkeletonToComponents(<OngoingTaskCard key={activity.jobId} ongoingTask={activity} />)
        )}
      </InfiniteScroll> */}

      {/* {hasMoreActivity && loading && (
        <CircularProgress color="inherit" sx={{ alignSelf: 'center' }} size={40} thickness={4} />
      )}
      {!hasMoreActivity && (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgba(99, 115, 129, 1)',
            maxWidth: '270px',
            alignSelf: 'center',
          }}
        >
          You've reached the end of the activities.
        </Typography>
      )} */}

      {!loading && (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgba(99, 115, 129, 1)',
            maxWidth: '270px',
            alignSelf: 'center',
          }}
        >
          You've reached the end of the activities.
        </Typography>
      )}
    </Stack>
  ) : (
    addSkeletonToComponents(renderEmptyContent())
  );
}
