import { Card, Button, Typography, Stack, Divider, Skeleton, useMediaQuery } from '@mui/material';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import { IBillingSubscription, SubscriptionStatus } from 'src/store/dashboard/slices/accountSlice';
import CancelBillingSubscriptionButton from './components/CancelBillingSubscriptionButton';
import { useNavigate } from 'react-router';
import AccountSubscriptionCard from './components/AccountSubscriptionCard';
import {
  addMillisecondsToTimestamp,
  getConditionalSubscriptionList,
  getExpiresMessage,
  getSubscriptionIcon,
  getSubscriptionQuota,
  getSubscriptionStatusLabelColor,
} from './helpers/subscriptionsHelpers';
import { setStripeReferrerUrl } from 'src/store/dashboard/slices/pricingSlice';
import { getDateWithTimeZone } from '../../../../../utils/getDateWithTimezone';
import { Marketplace } from '@beta.limited/primelister/dist/general/marketplace';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { AutomationPlatform } from 'src/hooks/useAutomationPlatform';

function AccountSubscriptions() {
  const { billingSubscriptionList, initialLoading, userAccountDetail } = useSelector(
    (state: IRootState) => state.account
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const directionChangeMatches = useMediaQuery('(min-width:1200px)');
  const fontChangeMatches = useMediaQuery('(min-width:500px)');

  const getAutomationType = (): Marketplace | null => {
    if (window.location.href.includes(PATH_AUTOMATIONS.ebayRoot)) {
      return Marketplace.EBAY_US;
    }
    if (window.location.href.includes(PATH_AUTOMATIONS.poshmarkRoot)) {
      return Marketplace.POSHMARK_US;
    }
    return null;
  };

  const automationType = getAutomationType();

  const getFilteredSubscriptionList = () => {
    if (automationType === Marketplace.EBAY_US) {
      return billingSubscriptionList.filter((subscription) =>
        subscription.subscriptionName.toLowerCase().includes('ebay')
      );
    }
    return billingSubscriptionList;
  };

  const conditionalSubscriptionList = getConditionalSubscriptionList(
    getFilteredSubscriptionList(),
    automationType
  );

  const renderSubscriptionCard = (subscription: IBillingSubscription, index: number) => {
    const subscriptionStatusLabelColor = getSubscriptionStatusLabelColor(subscription);

    const url = window.location.href;
    const getSubscriptionMarketplace = (): AutomationPlatform | null => {
      const subscriptionNameLower = subscription.subscriptionName.toLowerCase();
      if (subscriptionNameLower.includes('poshmark')) {
        return AutomationPlatform.POSHMARK;
      }
      if (subscriptionNameLower.includes('ebay')) {
        return AutomationPlatform.EBAY;
      }
      return null;
    };

    const subscriptionMarketplace = getSubscriptionMarketplace();

    const handleSubscriptionButton = () => {
      dispatch(setStripeReferrerUrl(url));

      switch (subscriptionMarketplace) {
        case AutomationPlatform.POSHMARK:
          navigate(PATH_DASHBOARD.subscription.poshmarkAutomation);
          break;
        case AutomationPlatform.EBAY:
          navigate(PATH_DASHBOARD.subscription.ebayAutomation);
          break;
        default:
          navigate(PATH_DASHBOARD.subscription.crosslist);
      }
    };

    const addMiliSecs = addMillisecondsToTimestamp(subscription);

    const createdOn = getDateWithTimeZone(
      addMiliSecs,
      userAccountDetail?.timeZone?.name,
      'MMM Do, YYYY'
    );
    const isPlanLifeTime = subscription?.planId === 'poshmark-plan-lifetime';

    const shouldUpdateAndCancelButtonDisplayed =
      !isPlanLifeTime && subscription?.status?.toLowerCase() === SubscriptionStatus.ACTIVE;

    const shouldSubscribeButtonDisplayed =
      subscription?.status?.toLowerCase() !== SubscriptionStatus.ACTIVE &&
      subscription?.status?.toLowerCase() !== SubscriptionStatus.PAST_DUE;

    const shouldRetryPaymentButtonDisplayed =
      subscription?.status?.toLowerCase() === SubscriptionStatus.PAST_DUE &&
      subscription.invoicePaymentLink;

    const shopCapacityLine = {
      title: 'Closet Capacity',
      content: subscription?.shopCapacity || 1,
    };

    const subscriptionCardInfo: { title: string; content: string | number | JSX.Element }[] = [
      {
        title: 'Plan Name',
        content: subscription?.planName,
      },
      {
        title: 'Status',
        content: (
          <Label
            variant="soft"
            color={subscriptionStatusLabelColor ?? 'error'}
            sx={{ fontWeight: 900, fontSize: '0.8rem', letterSpacing: '0.05rem', ml: -0.25 }}
          >
            {subscription.status}
          </Label>
        ),
      },
      ...(!isPlanLifeTime
        ? [
            {
              title: 'Quota Status',
              content: getSubscriptionQuota(subscription, userAccountDetail),
            },
          ]
        : []),
      ...(isPlanLifeTime ? [shopCapacityLine] : []),
      {
        title: 'Created on',
        content: createdOn,
      },
      {
        title: 'Expires',
        content: getExpiresMessage(subscription, userAccountDetail.timeZone.name!),
      },
    ];

    const shouldHidePlanName = Boolean(!subscription.planName);

    const filteredSubscriptionCardInfo = shouldHidePlanName
      ? subscriptionCardInfo.slice(1)
      : subscriptionCardInfo;

    return (
      <Stack
        direction={directionChangeMatches ? 'row' : 'column'}
        sx={{
          alignItems: `${directionChangeMatches ? 'center' : 'flex-start'}`,
          justifyContent: 'space-between',
          py: 1,
        }}
        spacing={2}
        key={index * Math.random() * 9999}
      >
        {directionChangeMatches && (
          <Iconify
            icon={getSubscriptionIcon(subscription)}
            sx={{ width: 32, height: 32, p: '8px', borderRadius: '6px', bgcolor: '#41526e20' }}
          />
        )}
        <Stack spacing={0.5} flexGrow={1}>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1,
              fontSize: '1rem !important',
              fontWeight: 600,
              gap: '0.5rem',
            }}
          >
            {!directionChangeMatches && (
              <Iconify
                icon={getSubscriptionIcon(subscription)}
                sx={{ width: 32, height: 32, p: '8px', borderRadius: '6px', bgcolor: '#41526e20' }}
              />
            )}{' '}
            {subscription.subscriptionName}
          </Typography>

          {filteredSubscriptionCardInfo.map((item, index) => (
            <AccountSubscriptionCard key={index} item={item} />
          ))}
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          {shouldUpdateAndCancelButtonDisplayed && (
            <CancelBillingSubscriptionButton subscription={subscription} />
          )}
          {shouldUpdateAndCancelButtonDisplayed && (
            <Button
              variant="outlined"
              sx={{ minWidth: '114px !important' }}
              onClick={handleSubscriptionButton}
            >
              Update plan
            </Button>
          )}
          {shouldSubscribeButtonDisplayed && (
            <Button size="medium" variant="outlined" onClick={handleSubscriptionButton}>
              Subscribe Now
            </Button>
          )}
          {shouldRetryPaymentButtonDisplayed && (
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => window.open(subscription.invoicePaymentLink, '_blank')}
            >
              Retry Payment
            </Button>
          )}
        </Stack>
      </Stack>
    );
  };

  const renderSubscriptionInfos = () => (
    <Stack divider={<Divider sx={{ borderStyle: 'dashed' }} />} spacing={2}>
      {conditionalSubscriptionList?.map((subsription, index) =>
        renderSubscriptionCard(subsription, index)
      )}
    </Stack>
  );

  const addSkeletonToComponents = (component: any) => {
    if (initialLoading) {
      const skeletonItems = Array.from({ length: 2 }).map((_, index) => (
        <Skeleton key={index} variant="rounded" sx={{ width: '100', height: '6rem', my: 1 }} />
      ));
      return <>{skeletonItems}</>;
    } else {
      return component;
    }
  };

  return (
    <Card
      sx={{
        py: 3,
        px: fontChangeMatches ? 3 : 1,
        dislay: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="overline" sx={{ display: 'block', color: 'text.secondary', mb: 3 }}>
        Your Subscriptions
      </Typography>

      {addSkeletonToComponents(renderSubscriptionInfos())}
    </Card>
  );
}

export default AccountSubscriptions;
