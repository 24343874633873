// @mui
import { Box, Divider, Skeleton, Stack, SxProps, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import StatisticWidget from './StatisticWidget';
import {
  automationItemsList,
  customTaskItemsList,
  ebayAutomationItemsList,
} from '../../ActivityPage/utils/constants';
import StatisticsDropDown from './StatisticsDropDown';
import { useLocation } from 'react-router-dom';
import { getStatisticsDropDownItems } from '../utils/constants';
import AutomationsPageTitle from 'src/layouts/automations/header/AutomationsPageTitle';
import useResponsive from 'src/hooks/useResponsive';
import { useEffect } from 'react';
import { AutomationPlatform, useAutomationPlatform } from 'src/hooks/useAutomationPlatform';
import { getPlatformActions } from 'src/utils/platformActions';

export default function StatisticsWidgetContainer() {
  const { platform, states } = useAutomationPlatform();
  const platformActions = getPlatformActions(platform);
  const dispatch = useDispatch();
  
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);

  const { initialLoading = true, dateOptionsForStatistics } = states.statistics;
  const { activeClosetCredentialsId, activeCloset } = states.myShops;

  const location = useLocation();
  const titleMatches = useMediaQuery('(max-width:380px)');
  const isMobileWeb = useResponsive('down', 'sm');
  const isMobile = isMobileWeb || isPrimeListerMobileApp;
  const matches = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    const hashValue = location.hash.substring(1);
    const macthedItem = getStatisticsDropDownItems(userAccountDetail.timeZone.name!).find(
      (item) => item.urlParam === hashValue
    );
    const dateOptions = hashValue.length
      ? macthedItem?.dateOptions
      : dateOptionsForStatistics.dateOptions;

    dispatch(platformActions.setDateOptionsForStatistics(macthedItem ?? dateOptionsForStatistics));
    dispatch(platformActions.setStatisticsInitialLoading(true));

    if (activeClosetCredentialsId) {
      dispatch(
        platformActions.getAutomationCountsRequest({
          shop: activeCloset?.marketplaceUserName,
          ...(dateOptions?.startDate && dateOptions?.endDate && { ...dateOptions }),
        })
      );
    } else {
      dispatch(platformActions.setStatisticsInitialLoading(false));
    }
  }, [activeClosetCredentialsId]);

  useEffect(() => {
    window.history.replaceState(
      null,
      '',
      `#${dateOptionsForStatistics.urlParam || 'all-activities'}`
    );
  }, [dateOptionsForStatistics]);

  const addSkeletonToComponents = (component: any) => {
    if (!initialLoading) {
      return component;
    } else {
      const skeletonItems = Array.from({ length: 1 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rounded"
          sx={{ width: '100%', height: '4.5rem', my: '4px !important' }}
          color="primary"
        />
      ));
      return <>{skeletonItems}</>;
    }
  };

  const widgetList =
    platform === AutomationPlatform.EBAY
      ? ebayAutomationItemsList
      : automationItemsList.filter((item) => item.name !== 'allActivities');

  const commonStyles: Record<string, SxProps> = {
    pageTitle: {
      fontSize: '18px !important',
      color: '#212B36 !important',
      fontWeight: '700 !important',
      lineHeight: '28px !important',
      mb: '16px',
    },
    widgetContainer: {
      display: 'grid',
      gridTemplateColumns: matches ? 'repeat(2, 2fr)' : 'repeat(1,1fr)',
      gridTemplateRows: 'repeat(1,1fr)',
      gridGap: '16px 40px',
      flexWrap: 'wrap',
      justifyItems: isMobile ? 'center' : 'unset',
    },
  };

  return (
    <Box>
      <Box sx={{ mb: !isMobile ? 3 : 2 }}>
        <StatisticsDropDown />
      </Box>

      <AutomationsPageTitle
        sxProps={{
          ...commonStyles.pageTitle,
        }}
        title="Automations"
      />
      <Stack
        sx={{
          ...commonStyles.widgetContainer,
        }}
      >
        {widgetList.map((item) =>
          addSkeletonToComponents(<StatisticWidget key={item.displayName} widget={item} />)
        )}
      </Stack>

      {platform !== AutomationPlatform.EBAY && (
        <>
          <Divider sx={{ my: '24px', borderStyle: 'dashed' }} />

          <AutomationsPageTitle
            sxProps={{
              ...commonStyles.pageTitle,
            }}
            title="Custom Tasks"
          />
          <Stack
            sx={{
              ...commonStyles.widgetContainer,
            }}
          >
            {customTaskItemsList.map((item) =>
              addSkeletonToComponents(<StatisticWidget key={item.displayName} widget={item} />)
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}
